<div class="clients-contacts">
    <ol class="breadcrumb">
        <li class="breadcrumb-item">
            <a (click)="getClients()">Clients</a>
        </li>
        <li>
            {{client.clientName}}
        </li>
    </ol>
    <div class="client-name">
        <h4>Configure Client: {{ client.clientName}}</h4>
    </div>
</div>
<!-- Application Access set up -->
<span class="main-header botm-margin"
    *ngIf="(clientApplicationAccessCap == 'read' || clientApplicationAccessCap == 'write') && !isEmptyObject(applicationAccessData) && isLoaded">{{applicationAccessData.displayName}}</span>
<mat-accordion
    *ngIf="(clientApplicationAccessCap == 'read' || clientApplicationAccessCap == 'write') && !isEmptyObject(moveProData) && isLoaded"
    multi="true" class="main-container" [formGroup]="applicationAccessForm">
    <mat-expansion-panel *ngIf="!isEmptyObject(moveProCollabBindingData)" [expanded]="true"
        class="collab-panel main-panel">
        <mat-expansion-panel-header class='panel-heading'>
            <div class="collab-display-name">{{moveProCollabBindingData.displayName}}</div>
            <div class="toggle-div mrgl4">
                <span class="mrgn5"
                    [ngClass]="{'enable': this.applicationAccessForm.controls[moveProCollabBindingData.propertyName].value === true}">Enable</span>
                <mat-slide-toggle (click)="$event.stopPropagation();"
                    [formControlName]="moveProCollabBindingData.propertyName"
                    (change)="onToggle($event,moveProCollabBindingData.propertyName)">
                    <span
                        [ngClass]="{'disable': this.applicationAccessForm.controls[moveProCollabBindingData.propertyName].value === false}">Disable</span>
                </mat-slide-toggle>
            </div>
            <!-- <div class="tooltip">
                <mat-icon class="info-icon">info</mat-icon><span
                    class="mrgn5">{{moveProCollabBindingData.tooltip}}</span>
            </div> -->
        </mat-expansion-panel-header>
        <mat-panel-description>
        </mat-panel-description>
        <mat-expansion-panel
            [disabled]="disablePanel(moveProCollabBindingData.propertyName,collabData.field0_propertyName)"
            class="inner-collab" *ngFor="let collabData of collabBindingData;let j = index">
            <mat-expansion-panel-header class='panel-heading'>
                <div class="collab-display-name-inner">{{collabData.displayName}}</div>
                <div class="toggle-div mrgl3" *ngIf="collabData.field0 && collabData.field0 === 'Boolean'">
                    <span class="mrgn5"
                        [ngClass]="{'enable': this.applicationAccessForm.controls[collabData.field0_propertyName].value === true}">Enable</span>
                    <mat-slide-toggle (click)="$event.stopPropagation();"
                        [disabled]="this.applicationAccessForm.controls[moveProCollabBindingData.propertyName].value"
                        formControlName={{collabData.field0_propertyName}}
                        (change)="onToggle($event,collabData.field0_propertyName)">
                        <span
                            [ngClass]="{'disable': this.applicationAccessForm.controls[collabData.field0_propertyName].value === false}">Disable</span>
                    </mat-slide-toggle>
                </div>
            </mat-expansion-panel-header>
            <mat-panel-description>
                <div class="field-container"                
                    *ngIf="collabData.field1_Values && collabData.field1 && collabData.field1 === 'Radio'">
                    <div>
                        <mat-radio-group aria-label="Select an option" class="field-alignment"
                            formControlName={{collabData.field1_propertyName}} (change)="onBBTypeChange($event.value)">
                            <mat-radio-button *ngFor="let value of collabData.field1_Values"
                                [disabled]="disablePanel(moveProCollabBindingData.propertyName,collabData.field0_propertyName)"
                                [value]="value" class="mrgn-align">{{value}}</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="inside-radio-btn" *ngIf="this.applicationAccessForm.value && this.applicationAccessForm.value.benefitsBuilderType === 'Standard'">
                        <div>
                            <span><b>Please select the move types you want to enable</b></span>
                            <mat-radio-group aria-label="Select a movetype" class="field-alignment"
                                formControlName={{collabData.field3_propertyName}}>
                                <mat-radio-button *ngFor="let movetype of collabData.field3_Values" 
                                    [disabled]="disablePanel(moveProCollabBindingData.propertyName,collabData.field0_propertyName)"
                                    [value]="movetype" class="mrgn-align">{{movetype}}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div class="second-row">
                            <span><b>Please select the value system you want to enable</b></span>
                            <mat-radio-group aria-label="Select a valuesystem" class="field-alignment"
                                formControlName={{collabData.field4_propertyName}}>
                                <mat-radio-button *ngFor="let valuesystem of collabData.field4_Values"
                                    [disabled]="disablePanel(moveProCollabBindingData.propertyName,collabData.field0_propertyName)"
                                    [value]="valuesystem" class="mrgn-align">{{valuesystem}}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>

                </div>
                <div class="field-container"
                    *ngIf="collabData.field2_Values && collabData.field2 && collabData.field2 === 'CheckBox'">
                    <div class="field-alignment">
                        <ng-container *ngFor="let value of collabData.field2_Values; let i= index">
                            <div formArrayName={{collabData.field2_propertyName}}>
                                <mat-checkbox (change)="checkedValue(value,$event.checked)"
                                    class="mrgn-align"
                                    [disabled]="disablePanel(moveProCollabBindingData.propertyName,collabData.field0_propertyName) || clientApplicationAccessCap === 'read'"
                                    [checked]="applicationAccessForm.controls[collabData.field2_propertyName].controls[i] ? 
                                    applicationAccessForm.controls[collabData.field2_propertyName].controls[i].value : false">
                                    {{value}}
                                </mat-checkbox>
                            </div>
                        </ng-container>
                    </div>
                    <!-- <div class="inner-tooltip" *ngIf="collabData.field2_tooltip">
                        <mat-icon class="info-icon">info</mat-icon><span>{{collabData.field2_tooltip}}</span>
                    </div> -->
                </div>
            </mat-panel-description>
        </mat-expansion-panel>
    </mat-expansion-panel>
</mat-accordion>

<!--sso set up-->
<span class="main-header botm-margin"
    *ngIf="(clientSSOConfigurationCap == 'read' || clientSSOConfigurationCap == 'write') && !isEmptyObject(ssosetUpData) && isSSOLoad">{{ssoAceessData.displayName}}</span>
<mat-accordion [formGroup]="ssoSetUpForm"
    *ngIf="(clientSSOConfigurationCap == 'read' || clientSSOConfigurationCap == 'write') && !isEmptyObject(ssosetUpData) && isSSOLoad"
    multi="true" class="main-container">
    <mat-expansion-panel class="collab-panel main-panel" *ngIf="!isEmptyObject(ssoCollabBindingData)"
        [expanded]="!this.ssoSetUpForm.controls['ssoEnabled'].value">
        <mat-expansion-panel-header class='panel-heading'>
            <div class="collab-display-name">{{ssoCollabBindingData.displayName}}</div>
            <div class="toggle-div">
                <span class="mrgl4 mrgn5"
                    [ngClass]="{'enable': this.ssoSetUpForm.controls['ssoEnabled'].value === false}">Enable</span>
                <mat-slide-toggle [disabled]="clientSSOConfigurationCap !== 'write'" (click)="$event.stopPropagation();"
                    formControlName="{{ssoCollabBindingData.propertyName}}" (change)="onSSOToggle($event,'ssoEnabled')">
                    <span
                        [ngClass]="{'disable': this.ssoSetUpForm.controls['ssoEnabled'].value === true}">Disable</span>
                </mat-slide-toggle>
            </div>
        </mat-expansion-panel-header>
        <div *ngFor="let row of ssosetUpData">
            <mat-form-field fxFlex="40%" fxLayout.xs="column" fxFlex.xs="100%">
                <mat-label class="label">{{row.displayName}}</mat-label>
                <input formControlName={{row.propertyName}} matInput type="text">
            </mat-form-field>
        </div>
    </mat-expansion-panel>
</mat-accordion>

<div class="btn-container"
    *ngIf="isLoaded && (clientSSOConfigurationCap == 'read' || clientSSOConfigurationCap == 'write') || (clientApplicationAccessCap == 'read' || clientApplicationAccessCap == 'write')">
    <button type="button" mat-raised-button class="save-button" color="primary" id="saveChanges"
        [disabled]="clientApplicationAccessCap === 'read'" (click)="openSaveChangesModal()"> Save Changes</button>
    <button class="save-button mat-button text-button" id="cancel" (click)=" getClients()"> Cancel </button>
</div>
<div class="no-cap-block"
    *ngIf="(clientSSOConfigurationCap !== 'read' && clientSSOConfigurationCap !== 'write') && (clientApplicationAccessCap !== 'read' && clientApplicationAccessCap !== 'write')">
    <p>{{noCapBloclMessage}}</p>
</div>