import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseClientService } from './base-client.service';
import { RemoteLoggingService } from './remote-logging.service';
import { Party } from '../models/party.model';
import { AppConfigService } from './app-config.service';
import { ClientView } from '../models/client-view.model';
import { UserContext } from '../models/user-context.model';
import { ClientContactsView } from '../models/client-contacts-view.model';
import { HttpParams } from '@angular/common/http';
import { OktaAuth, OktaAuthOptions, Token } from '@okta/okta-auth-js';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';

/** This service is used for managing user session as well as aiding access to legacy COL */
@Injectable({
  providedIn: 'root'
})
export class UserSessionService {
  ssoRole = new BehaviorSubject([]);
  public authClient: OktaAuth;

  /** standard constructor for services */
  constructor(
    private readonly baseClient: BaseClientService,
    private readonly logSvc: RemoteLoggingService,
    private readonly appConfig: AppConfigService,
    private readonly cookieService: CookieService,
  ) {
    const authOpts: OktaAuthOptions = {
      clientId: (this.appConfig.getConfig('oktaClientId')).toString(),
      issuer: (this.appConfig.getConfig('oktaUrl')).toString(),
      authorizeUrl: `${this.appConfig.getConfig('oktaUrl')}/v1/authorize`,
      redirectUri: (this.appConfig.getConfig('oktaRedirectUri')).toString(),
      postLogoutRedirectUri: (this.appConfig.getConfig('oktaRedirectUri')).toString(),
      tokenManager: {
        storage: 'sessionStorage'
      },
    };
    this.authClient = new OktaAuth(authOpts);
  }

  assignRealogyOkta() {
    console.log('Reinitializing auth client with ANYWHERE OKTA Credentials.')
      this.authClient = new OktaAuth({
        clientId: String(this.appConfig.getConfig('realogyOktaClientId')),
        issuer: String(this.appConfig.getConfig('realogyOktaUrl')),
        authorizeUrl: `${this.appConfig.getConfig('oktaUrl')}/v1/authorize`,
        redirectUri: (this.appConfig.getConfig('oktaRedirectUri')).toString(),
        postLogoutRedirectUri: (this.appConfig.getConfig('oktaRedirectUri')).toString(),
        tokenManager: {
          storage: 'sessionStorage'
        },
      });
  }

  /** Returns an array of party contexts */
  getContexts(): Observable<Array<Party>> {
    if (this.appConfig.getConfig('environment') === 'developer') {
      return this.baseClient.getArr<Party>('v1/auth/appContext', 'get the UserConfig data').pipe(
        map(r => r.body),
        catchError((err, source) => {
          const empty: Party[] = [];
          this.logSvc.logError(err);
          return of(empty);
        })
      );
    } else {
      const url: string = <string>this.appConfig.getConfig('authapi');
      return this.baseClient.getExt<Party[]>(url + 'user/appcontext', 'getting user contexts').pipe(
        map(r => r.body),
        catchError((err) => {
          this.logSvc.logError(err);
          const emptyArray: Party[] = [];
          return of(emptyArray);
        })
      );
    }
  }

  /** Returns a token for CartusOnline authentication */
  getColToken(): Observable<any> {
    return this.baseClient
      .getOne<any>('v1/navigateToCOL', 'get the CartusOnline token', null, true)
      .pipe(
        map(r => r),
        catchError((err, source) => {
          const empty: any = null;
          this.logSvc.logError(err);
          return of(empty);
        })
      );
  }

  /** getClients method fetches clients available for the particular partyid passed */
  getClients(partyId: string): Observable<ClientView> {
    const params: HttpParams = new HttpParams({
      fromObject: {
        'partyId': partyId
      }
    });
    return this.baseClient.getOne<ClientView>('v1/roles', 'get the clients data', params).pipe(
      map(r => r.body),
      catchError((err, source) => {
        const empty: ClientView = null;
        this.logSvc.logError(err);
        return of(empty);
      })
    );
  }

  /** getClientContacts method fetches client contacts available for the particular client from api  */
  getClientContacts(clientPartyId: string): Observable<ClientContactsView> {
    const params: HttpParams = new HttpParams({
      fromObject: {
        'clientPartyId': clientPartyId
      }
    });
    return this.baseClient.getOne<ClientContactsView>('v1/clientContacts', 'get the client contacts data', params).pipe(
      map(r => r.body),
      catchError((err, source) => {
        const empty: ClientContactsView = null;
        this.logSvc.logError(err);
        return of(empty);
      })
    );
  }

  /** get Atlas Association fetches configure fields available for the particular client from api  */
  getAtlasAssociation(clientNo: string): Observable<any> {
    const params: HttpParams = new HttpParams({
      fromObject: {
        'clientNo': clientNo
      }
    });
    return this.baseClient.getOne<any>('v1/admin/configurable-field/mappings', 'get the atlas association data', params).pipe(
      map(r => r.body),
      catchError((err, source) => {
        const empty: any = null;
        this.logSvc.logError(err);
        return of(empty);
      })
    );
  }

  addCustomData(data: any): Observable<any> {
    return this.baseClient
      .post<any>('v1/admin/configurable-field', data)
      .pipe(
        map(r => r.body),
        catchError(err => {
        this.logSvc.logError(err);
        const emptyArray = null;
          return of(emptyArray);
        })
      );
   }

   updateCustomData(data: any, id): Observable<any> {
    /*const reqObj = {
      isAuthorizationRequired : data.isAuthorizationRequired,
      expirationDate : data.expirationDate,
      clientPartyId: data.clientPartyId,
      }; */
    return this.baseClient
      .put<any>(`v1/admin/configurable-field/${id}`, data)
      .pipe(
        map(r => r.body),
        catchError(err => {
        this.logSvc.logError(err);
        const emptyArray = null;
          return of(emptyArray);
        })
      );
   }

   deleteCustomData(data: any, id): Observable<any> {
    /*const reqObj = {
      isAuthorizationRequired : data.isAuthorizationRequired,
      expirationDate : data.expirationDate,
      clientPartyId: data.clientPartyId,
      }; */
    return this.baseClient
      .put<any>(`v1/admin/configurable-field/${id}/remove`, data)
      .pipe(
        map(r => r.body),
        catchError(err => {
        this.logSvc.logError(err);
        const emptyArray = null;
          return of(emptyArray);
        })
      );
   }


  public refreshSession() {
    return this.authClient.token.getWithoutPrompt().then(tokenOrTokens => {
      console.log('getWithoutPrompt()', tokenOrTokens); // Leave this debug code in place
      return tokenOrTokens;
    }).catch(err => {
      // console.error(err); // Leave this debug code in place -- commented out due to error being displayed regardless
      return; // Not authenticated
    });
  }
}
