import { Injectable } from '@angular/core';
import { BaseClientService } from './../../../core/services/base-client.service';
import { RemoteLoggingService } from './../../../core/services/remote-logging.service';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';


export interface GetClientOptions {
  sortBy?: string;
  sortDirection?: string;
  searchText?: string;
  searchProperties?: any[];
  filterObj?: any;
  itemsPerPage?: number,
  pageNumber?: number
}

export enum ProgramValueType {
  POINTS = 'Points',
  CASH = 'Cash',
  BOTH = 'Both'
}

export interface Benefit {
  singleSelectTier?: any;
  tierValues?: any;
  reference: string;
  productSpecificationReference: any;
  category: string;
  consultantBenefitInfo?: { consultant: boolean, qualifyingInput?: number , activatedByDefault?: boolean};
  displayName: string;
  description: string;
  points: number;
  amount?: number;
  suppress?: boolean;
  multiplicity?: boolean;
  incremental?: boolean;
  maxSelection: number;
  prerequisites?: Array<any>;
  exclusions?: Array<any>;
  inclusions?: Array<any>;
  prerequisiteRule?: Array<any>;
  excludeRule?: Array<any>;
  includeRule?: Array<any>;
  scopes?: any;
  rangeMax?: number;
  rangeMin?: number;
  rangeIncrementValue?: number;
  rangeIncrementUnit?: string;
  incrementalTimespan?:boolean;
  tierConfigs?: Array<any>;
  cashOut?: boolean;
  advCashOut?: boolean;
  advCashOutV2?: boolean;
  pointsPerSelection?: number;
  icon?: string;
  perPointCurrency?: number;
  incrementalCurrency?: boolean;
  isCloned?:boolean;
  clonedCopy?:boolean;
  clonedFrom?: string;
  parentClonedCopy ?: boolean
  editTitle?: boolean; //flag for ui only not for api
  editDescription?: boolean; //flag for ui only not for api
  isNewlyAdded ?: boolean; // flag to determine new offerings
  isNewlyAddedRef? :boolean;
  hybridTempLiving?:boolean;
  preMaxSelection?: any; // temp variable
  preRangeIncrementValue?: any; //temp variable
  preRangeIncrementUnit?: any; //temp variable
  preCoreUnitsPerSelection?: any; //temp variable
  preRangeMax?: any; //temp
  preRangeMin?: any; //temp
  coreUnitsPerSelection?:number;
  productSubDetails :{
    internationalProduct?: {
      productName?: string;
      subProductName?: string;
      requiresRevision?:boolean;
    },
    usDomesticProduct?: {
      productName?:string;
      subProductName?: string;
      requiresRevision?:boolean;
 
    }
  },
  isDEIEnabled?: boolean;
  isSustainabilityEnabled?: boolean;
  tasks:Array<string>;
  currencyCode?: string;
}

export interface ProgramRequest {
  cartusClientId: string;
  standardProgramName: string;
  clientId: string;
  programName: string;
  goal?: string;
  points?: string | number;
  policyName: Array<string>;
  contractId: Array<number>;
  benefits: Array<Benefit>;
  divisions: Array<any>;
  programExpirationDate?: string;
  policyCallRequired: boolean;
  draft?: boolean;
  isCartusInsured?: boolean;
  moveType?: string;
  currency?:string;
  totalAmount?:number | string;
  valueSystem?:string;
  copyProgramDetails?: {
    programName: string;
    clientId: string;
  };
  isNewlyAdded ?: boolean;
  initialContactRequired?:boolean;
  initialContactBy?:string;
  policyCallDuration?:number;
  isDEIEnabled?: boolean;
  isSustainabilityEnabled?: boolean;
}
export interface ProgramBenefitUpdateRequest {
  benefits: Array<Benefit>;
  draft?: boolean;
  active?: boolean;
  initialContactBy?: string,
  initialContactRequired?:boolean,
  copyProgramDetails?: {
    programName: string;
    clientId: string;
  };
}
export interface ProgramList {
  clientId: string;
  divisionList: Array<{
    division_id: string;
    division_name: string;
  }>;
  programs: Array<{
    name: string;
    clientPolicy: string;
    totalPoints: string;
    divisions: Array<{
      division_id: string,
      entityName: string
    }>
  }>;
  count: number;
}

export class AtlasPolicyCkeckReq {
  clientPartyId: string;
  policies: string[]
}

export class AtlasPolicyCkeckResp {
  duplicatePolicies: string[]
}
export interface SimpleRule {
    type: string;
    _scopingCategory: string;
    scopingType: string;
    fact: string;
    uiType: string;
    id?: string;
    values: Array<OperatorValue>
};
export interface OperatorValue {
  operator: string;
  value: number;
}

@Injectable({
  providedIn: 'root'
})
export class ProgramManagementService {
  /** programList Observable */
  private programListSub = new BehaviorSubject<any[]>([]);
  programList: Observable<any[]> = this.programListSub.asObservable();

  /** benefitList Observable */
  benefitListSub = new BehaviorSubject<{ benefits: Array<Benefit> }>({benefits:[]});
  benefitList: Observable<{ benefits: Array<Benefit> }> = this.benefitListSub.asObservable();

  constructor(private baseClientService: BaseClientService, private logSvc: RemoteLoggingService) { }

  /**
  * used to get filtered programs 
  * @param clientId client party id
  * @param options options object for sorting,searching and pagination info
  * @param filterOptions object for with filtering criteria
  */
  getProgramsList(clientId: string, options: GetClientOptions = null, filterOptions?: any): Observable<ProgramList> {
    let  body  = {};
    if (filterOptions) {
      body = filterOptions;
    }
    let httpParams: HttpParams = new HttpParams();
    if (options) {
      if (options.sortBy) { httpParams = httpParams.append('sortBy', options.sortBy); }
      if (options.sortDirection) { httpParams = httpParams.append('sortDirection', options.sortDirection); }
      if (options.searchText) { httpParams = httpParams.append('searchText', options.searchText); }
      if (options.searchProperties) { httpParams = httpParams.append('searchProperties', options.searchProperties.toString()); }
      if (options.hasOwnProperty('itemsPerPage')) { httpParams = httpParams.append('itemsPerPage', options.itemsPerPage.toString()); }
      if (options.hasOwnProperty('pageNumber')) { httpParams = httpParams.append('pageNumber', (options.pageNumber + 1).toString()); }
    }
    return this.baseClientService
      .post<any>(`${options ?
        `v1/admin/program?clientId=${clientId}&${httpParams.toString()}` :
        `v1/admin/program?clientId=` + clientId}`, body)
      .pipe(
        map(r => {
          this.programListSub.next(r.body.programs);
          return r.body;
        }),
        catchError(err => {
          this.logSvc.logError(err);
          const empty = null;
          return of(empty);
        })
      );
  }

  /* fetch program templates based on program type */
  getProgramTemplates(programTypeValue): Observable<{ clientId: string, programs: Array<string>, count: number }> {
    return this.baseClientService
      .getOne<any>(`v1/admin/templates?programType=${programTypeValue}`)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          console.log('204 Error, No templates found', err)
          if (err && err?.message.includes('204')) {
            return of({ statusCode: 204, statusText: "No Templates Found" });
          } else {
            this.logSvc.logError(err);
            const empty = null;
            return of(empty);
          }
        }));
  }

  getStandardPrograms(): Observable<{ clientId: string, programs: Array<string>, count: number }> {
    return this.baseClientService
      .post<any>(`v1/admin/program?standardPrograms=true`, {})
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          console.log('204 Error, No templates found', err)
          if (err && err?.message.includes('204')) {
            return of({ statusCode: 204, statusText: "No Templates Found" });
          } else {
            this.logSvc.logError(err);
            const empty = null;
            return of(empty);
          }
        })
      );
    // const resp = {"clientId":"5dcf100073f701819e97d883",
    // "programs":[
    //   {"name":"00Test Demo"},
    //   {"name":"272022 LDJ Test"},
    //   {"name":"Advanced Cash Out Demo"},
    //   {"name":"AutomatedTemplate03824"},
    //   {"name":"Executive Homeowner"},
    //   {"name":"Executive Renter"},
    //   {"name":"Executive Renter v121"},
    //   {"name":"Expat - Long Term"},
    //   {"name":"Google International"},
    //   {"name":"Google NORAM"},
    //   {"name":"Intern 1"},
    //   {"name":"LDJ Executive Homeowner"},
    //   {"name":"LDJ Limited Benefits Move"},
    //   {"name":"Managed Budget"},
    //   {"name":"One Way"},
    //   {"name":"Repat"},
    //   {"name":"Standard Homeowner"},
    //   {"name":"Standard Renter"},
    //   {"name":"Test0002"},
    //   {"name":"US Inbound"},
    //   {"name":"US Outbound"}
    // ],
    // "count":21};
    // return of (JSON.parse(JSON.stringify(resp)));
  }

  getClientPolicies(clientId: string): Observable<{ count: number, policies: Array<string> }> {
    return this.baseClientService
      .getOne<any>(`v1/admin/client/policy?clientId=${clientId}`)
      .pipe(
        map(r => r.body),
        catchError(err => {
          this.logSvc.logError(err);
          const empty = null;
          return of(empty);
        })
      );
  }
  getClientPoliciesBB(programId: string): Observable<{ count: number, policies: Array<string> }> {
    return this.baseClientService
      .getOne<any>(`v1/admin/client/policy?context=benefits-builder&programId=${programId}`)
      .pipe(
        map(r => r.body),
        catchError(err => {
          this.logSvc.logError(err);
          const empty = null;
          return of(empty);
        })
      );
    }
  
  getBaseContract(clientId: string): Observable<any> {
    return this.baseClientService
      .getOne<any>(`v1/admin/contract-list?clientId=${clientId}&context=program`)
      .pipe(
        map(r => r.body),
        catchError(err => {
          this.logSvc.logError(err);
          const empty = null;
          return of(empty);
        })
      );
  }

  getApplicationType(): Observable<any> {
    return this.baseClientService
      .getOne<any>(`v1/admin/value-list?key=PointsValueAllocationType`)
      .pipe(
        map(r => r.body),
        catchError(err => {
          this.logSvc.logError(err);
          const empty = null;
          return of(empty);
        })
      );
  }

  checkDuplicatePrograms(clientId: string, templateName: string): Observable<{ clientId: string, programs: Array<string>, count: number }> {
    return this.baseClientService
      .post<any>(`v1/admin/program?clientId=${clientId}&programName=${templateName}&checkDuplicateProgram=true`, {})
      .pipe(
        map(r => r.body),
        catchError(err => {
          this.logSvc.logError(err);
          const empty = null;
          return of(empty);
        })
      );
  }

  getProgramDetail(clientId: string, templateName: string): Observable<{ clientId: string, programs: Array<string>, count: number }> {
    return this.baseClientService
      .post<any>(`v1/admin/program?clientId=${clientId}&programName=${templateName}`, {})
      .pipe(
        map(r => r.body),
        catchError(err => {
          this.logSvc.logError(err);
          const empty = null;
          return of(empty);
        })
      );
  }

  getProgramBenefits(clientId: string, programName: string, clientProgram?: boolean, isManageMove?: boolean, programType?: string): Observable<{ benefits: Array<Benefit> }> {
    const clientProgramParam = clientProgram ? '&clientProgram=true' : '';
    let url = `v1/admin/program/benefit?clientId=${clientId}&programName=${programName}`;
    if(programType)
      url = url + `&programType=${programType}`
    else 
        url = url  + `${clientProgramParam}`;
    if (isManageMove) {
      url = url  + `&managedMove=true`;
    }
    return this.baseClientService
      .getOne<any>(url)
      .pipe(
        map(r =>  r.body ),
        catchError(err => {
          this.logSvc.logError(err);
          const empty = null;
          return of(empty);
        })
      );
  }

  getTransfereeViewProgramBenefits(clientId: string, programName: string, orderRequestId: string, clientProgram?: boolean, isManageMove? : boolean): Observable<{ benefits: Array<Benefit> }> {
    const clientProgramParam = clientProgram ? '&clientProgram=true&transfereeView=true&orderRequestId=' : '';
    let url = `v1/admin/program/benefit?clientId=${clientId}&programName=${programName}${clientProgramParam}${orderRequestId}`;
    if (isManageMove) {
      url = `v1/admin/program/benefit?clientId=${clientId}&programName=${programName}${clientProgramParam}${orderRequestId}&managedMove=true`;
    }
    return this.baseClientService
      .getOne<any>(url)
      .pipe(
        map(r => {
          this.benefitListSub.next(r.body);
          return r.body
        }),
        catchError(err => {
          this.logSvc.logError(err);
          const empty = null;
          return of(empty);
        })
      );
  }

  getClientDivisions(clientId: string): Observable<{ count: number, "divisions": Array<string> }> {
    return this.baseClientService
      .getOne<any>(`v1/admin/client/division?clientId=${clientId}`)
      .pipe(
        map(r => r.body),
        catchError(err => {
          this.logSvc.logError(err);
          const empty = null;
          return of(empty);
        })
      );
  }

  publishProgram(data: ProgramRequest, copyProgram = false) {
    data = this.removeIconFromReq(data);
    const copyUrlParam = copyProgram ? `?copyProgram=true` : ``;
    const url = `v1/admin/program${copyUrlParam}`;
    return this.baseClientService
      .post<ProgramRequest>(url, data)
      .pipe(
        map(r => r.body),
        catchError(err => {
          this.logSvc.logError(err);
          const empty = null;
          return of(empty);
        })
      );
  }

  updateProgram(data: any) {
    data = this.removeIconFromReq(data);
    return this.baseClientService
      .put<ProgramRequest>(`v1/admin/program`, data)
      .pipe(
        map(r => r.body),
        catchError(err => {
          this.logSvc.logError(err);
          const empty = null;
          return of(empty);
        })
      );
  }
  updateProduct(data: any) {
    data = this.removeIconFromReq(data);
    return this.baseClientService
      .put<ProgramRequest>(`v1/admin/program`, data)
      .pipe(
        map(r => r.body),
        catchError(err => {
          this.logSvc.logError(err);
          const empty = null;
          return of(empty);
        })
      );
  }


  updateBenefitForProgram(data: any, programId: string, copyProgram = false, onlyCopy = false) {
    data = this.removeIconFromReq(data);
    const copyUrlParam = copyProgram && onlyCopy ? `?onlyCopyProgram=true` :
      (copyProgram && !onlyCopy ? `?copyProgram=true` : ``);
    const url = `v1/admin/program/${programId}/benefits${copyUrlParam}`;
    return this.baseClientService
      .put<ProgramRequest>(url, data)
      .pipe(
        map(r => r.body),
        catchError(err => {
          this.logSvc.logError(err);
          const empty = null;
          return of(empty);
        })
      );
  }

  associateDivisions(data: any) {
    data = this.removeIconFromReq(data);
    return this.baseClientService
      .put<any>(`v1/admin/program/division`, data)
      .pipe(
        map(r => r.body),
        catchError(err => {
          this.logSvc.logError(err);
          const empty = null;
          return of(empty);
        })
      );
  }

  /**
  * used to deactivate program/programs
  * @param request array of program id's to be deactivated
  */
  deactivateProgram(request): Observable<any> {
    return this.baseClientService
      .put<any>(`v1/admin/program/deactivate-programs`, request)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          this.logSvc.logError('Failed to deactivate program' + err);
          return null;
        })
      );
  }

  removeIconFromReq(data: any) {
    if (data.hasOwnProperty('benefits')) {
      data.benefits.forEach(ele => {
        ele.hasOwnProperty('icon') ? delete ele.icon : null;
      });
    }
    return data;
  }

  getNewLegacy(): Observable<any> {
    return this.baseClientService
    .getOne<any>(`v1/admin/value-list?key=LegacyProductSubProduct`)
    .pipe(
      map(r => r.body),
      catchError(err => {
        this.logSvc.logError(err);
        const empty = null;
        return of(empty);
      })
    )
  }

  deleteProgram(request): Observable<any> {
    return this.baseClientService
      .bulkDelete<any>(`v1/admin/program/delete-programs`, request)
      .pipe(
        map(r => r),
        catchError((err, source) => {
          this.logSvc.logError('Failed to delete program' + err);
          return null;
        })
      );
  }

  checkDuplicateAtlasPolicyName(atlasPolicyCkeckReq: AtlasPolicyCkeckReq): Observable<AtlasPolicyCkeckResp> {
    return this.baseClientService
      .post<any>(`v1/admin/program/policy-name/duplicate`, atlasPolicyCkeckReq)
      .pipe(
        map(r => r.body),
        catchError(err => {
          this.logSvc.logError(err);
          const empty = null;
          return of(empty);
        })
      );
  }

  /**
     * Get value list arry based on key name
     * @param key string it reference name of the value lists
  */
  getvalueLists(key) {
      return this.baseClientService.getOne(`v1/admin/value-list?key=${key}`,'').pipe(
        map(r => r.body),
        catchError(err => {
          this.logSvc.logError(err);
          const empty = null;
          return of(empty);
        })
      )
  }

}
