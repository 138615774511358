import { Component, OnInit, EventEmitter, Output, Input, ViewChild, ElementRef, QueryList, ViewChildren, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProgramManagementService, Benefit, ProgramRequest, ProgramBenefitUpdateRequest, ProgramValueType } from '../../../services/program-management.service';
import { ToastrService } from 'ngx-toastr';
import { ProgramCloseWarningComponent, ProgramModalComponent } from '../../../components/program-modal/program-modal.component';
import { CopyProgramModalComponent } from '../../../components/copy-program-modal/copy-program-modal.component';
import { AdminClient } from './../../../../../core/models/admin-client.model';
import pdfMake from 'pdfmake/build/pdfmake';
import { ProgramReportGenerationService } from './../../../services/program-report-generation.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserTypeService, UserType } from './../../../services/user-type.service';
import { DivisionAssociationComponent } from '../../../components/division-association/division-association.component';
import { EditBenefitDialogComponent } from '../components/edit-benefit-dialog/edit-benefit-dialog.component'
import { UntypedFormControl, FormGroup } from '@angular/forms';
import { DeactivateProgramModalComponent } from '../../../components/deactivate-program-modal/deactivate-program-modal.component';
import { ConsultantOnlyModalComponent } from '../../../components/consultant-only-modal/consultant-only-modal.component';
import * as moment from 'moment';
import { PointsLogicModalComponent } from '../components/points-logic-modal/points-logic-modal.component';
import { Subscription } from 'rxjs';
import { BenefitSettingDialogComponent } from '../components/benefit-setting-dialog/benefit-setting-dialog.component';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { CategoryService } from '../../../services/category.service';
import { ClientContactsService } from '../../../services/client-contacts.service';
import { FeatureFlagService } from '../../../../../core/services/feature-flag.service';
import { DynamicBenefitService } from '../../../../../shared/services/dynamic-benefit.service';

export interface Program {
  id: string;
  programName: string;
  templateName: string;
  valueSystem?: ProgramValueType;
  totalPoints: number;
  currency?: string;
  totalAmount?: string | number;
  moveType: string;
  contractSeries: Array<number>;
  atlasPolicy: Array<any>;
  selectedClientId: string;
  cartusClientId?: string;
  isNew?: boolean;
  programExpirationDate?: string;
  programActivationDate?: string;
  policyCallRequired?: boolean;
  policyCallDuration?: number;
  programTypeInfo?:{
    type?: string;
    subType?: Array<string>;
  };
  initialAuditRequired:boolean;
  divisions?: Array<{
    division_id: string;
    division_name: string
  }>;
  editTitle?: boolean; // Flag to set the benefit title in edit mode
  editDescription?: boolean; // Flag to set the benefit Description in edit mode
  draft?: boolean;//Flag for draft
  isCartusInsured?: boolean; // indicate whether household goods insurance and high value items insurance is provided
  initialContactRequired?: boolean;
  initialContactBy?: string;
  pointsValueAllocationType?: string;
  active?: boolean; //Flag for active
  isDEIEnabled?: boolean;
  isSustainabilityEnabled?: boolean;
}

@Component({
  selector: 'app-program-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class DetailComponent implements OnInit {

  @Input() program: Program;
  /** To be displayed in download report */
  @Input() clientDetails: AdminClient;
  /** LumpSum FeatureFlag */
  @Input() isLumpSumEnabled?: boolean;


  @Output() close = new EventEmitter<boolean>();

  programDivisions: Array<any> = [];

  searchValue: string = null;
  ProgramValueType = ProgramValueType;
  userValueSelection: ProgramValueType = ProgramValueType.POINTS; // track toggle choice for cashAndPoints

  benefitsList: Array<{ group: string, items: Array<Benefit> }>;

  originalBenefitsList: Array<Benefit> = [];
  initialBenefitsList: Array<Benefit> = [];
  /** Unsused benefits list */
  unusedBenefitsList: Array<Benefit> = [];
  // to display Benefit Builder Program Fields
  isBBProgFields:boolean = true;

  /** To store list of divisions */
  divisions;

  /** user role */
  userRole: UserType;

  /* manage programadmin capabilities*/
  userCapabilities: number;
  mpInternalCapability: number;
  isMMUser = this.clientContactsService.isMMUser;

  isGOUser = this.clientContactsService.isGOUser;

  BenefitCategories;

  assignedDivision: number = null;

  cloneBenefitCount: number = 1;

  // tslint:disable-next-line: no-inferrable-types
  hideDivisionCount: boolean = true;

  //  dataviewSelection to access the dialog
  divisionSelection: MatDialogRef<DivisionAssociationComponent>;

  deactivateModalRef: MatDialogRef<DeactivateProgramModalComponent>;

  validForDeactivation: boolean = true;

  connectedTo = []; //for drag and drop connection
  itemDragged: any;
  disableDrag: boolean = true;
  diasbleDynamicForm = false;


  // to capture edit flex point
  points: any;
  rangeIncrementValue: number;
  // rangeIncrementUnit :string;
  timeSpanValue = new UntypedFormControl();
  publishDisable: boolean;
  draftDisable: boolean;
  CloneCopyDisable: boolean;
  perPointCurrency: number;
  panelOpenState = false;
  publishDivisionFlag = false;
  editExpiration = false;
  editPolicy = false;
  editpolicyCallRequired = false;
  editContract = false;
  editTotalPoints = false;
  editTotalAmmount = false;
  editCurrency = false;
  editMoveType = false;
  editValueSystem = false;
  editAllocationType = false;
  editPoint: Array<boolean> = [];
  editDisplayLogic: Array<boolean> = [];
  editMultiplicity: Array<boolean> = [];
  editIncremental: Array<boolean> = [];
  editIncrementalCurrency: Array<boolean> = [];
  editPerPointCurrency: Array<boolean> = [];
  editIsCartusInsured = false;
  editRaneIncrementVal: Array<boolean> = [];
  editInitialContactRequired = false;
  @ViewChild(MatMenuTrigger) matMenuTrigger: MatMenuTrigger;
  @ViewChild('pointVal', { static: true }) pointVal: ElementRef;
  @ViewChildren('focusable') focusable: QueryList<MatButton>;
  @ViewChild('perPointCurrencyVal', { static: true }) perPointCurrencyVal: ElementRef;
  @ViewChildren('title') title: QueryList<ElementRef>;
  @ViewChild('rangeIncrementVal', { static: true }) rangeIncrementVal: ElementRef;
  @ViewChildren('rangeIncrementUnit') rangeIncrementUnit: QueryList<ElementRef>;
  updatedBenefits: Array<Benefit> = [];
  benefitService: any;
  benefit: any;
  clientSubscription: Subscription
  isNewView = false;

  timespanValues: string[] = ['Hours', 'Days', 'Weeks', 'Months', 'Years'];
  timeSpanValues: string[] = ['Days','Months'];
  isChipEditable: Array<boolean> = [];
  newelyAddedBenefits = [];
  programType:string;
  isTriggeredAccessAdded : boolean = true;
  categoriesList;
  // settingsModel: any;
  public visibleIndex = -1;
  public totalChoice = 0;
  public tiersValues: [];
  public displayNames: any;

  public isShowInitialContact = false;
  public isInitialcontactCheck = false;

  public initialContactRequired=[
    {value:'Consultant', viewValue:'Consultant'},
    {value:'Welcome Center', viewValue:'Welcome Center'}
  ];
  // public initialContactForm: FormGroup;
  public initialRequiredByValue: any;
  public isCheckboxDisable = false;
  produstSpecAttributes = {};
  public dynamicFormValid= true; //Output from dynamic-upsert-form
  isDraftProgram
  constructor(
    public dialog: MatDialog,
    public programService: ProgramManagementService,
    private toastrService: ToastrService,
    private userTypeService: UserTypeService,
    private readonly pdfgenerator: ProgramReportGenerationService,
    private spinner: NgxSpinnerService,
    private readonly toastr: MatSnackBar,
    private clientContactsService: ClientContactsService,
    private readonly categoryService: CategoryService,
    public cd: ChangeDetectorRef,
    public featureFlagService:FeatureFlagService,
    public dynamicBenefitService: DynamicBenefitService
  ) {  }

  ngOnInit() {
    if(this.isLumpSumEnabled){
      this.isBBProgFields = this.program?.programTypeInfo?.type !== 'Benefits Builder' ? false : true;
      this.programType  = this.program?.programTypeInfo?.type;
    }
    this.BenefitCategories = this.categoryService.categorySub.getValue();
    this.categoriesList = JSON.parse(JSON.stringify(this.BenefitCategories));
    let closeFlag = false;
    this.userRole = this.userTypeService.userType;
    this.program.valueSystem = this.program.valueSystem ? this.program.valueSystem : ProgramValueType.CASH;
    this.userTypeService.capabilities$.subscribe(ele => {
      ele ? this.userCapabilities = ele.findIndex(element => element.name == "Benefits Builder Program Administration Portal" && element.operation == "write") : null;
      ele ? this.mpInternalCapability = ele.findIndex(element => element.name == "MP - Internal View" && element.operation == "read") : null;
    });
    this.editPoint.forEach((i, index) => {
      i = false;
      this.editMultiplicity[index] = false;
      this.editDisplayLogic[index] = false;
      this.editIncremental[index] = false;
      this.editRaneIncrementVal[index] = false;
      this.editIncrementalCurrency[index] = false;
      this.editPerPointCurrency[index] = false;
      this.isChipEditable[index] = false;
    });
    this.close.subscribe(val => {if(val)closeFlag = val});
    this.clientSubscription = this.programService.benefitList.subscribe(data => {
      if (data.benefits.length > 0 && !closeFlag) {
        data.benefits = data.benefits.sort((a, b) => a.displayName.localeCompare(b.displayName));
        data.benefits.forEach((item) => {
          // to add NewlyAddedRef for newlyadded card  to api and delete before sending
          if (item.hasOwnProperty('isNewlyAdded')) {
           item['isNewlyAddedRef'] = true;
          }
          if (item.hasOwnProperty('productSubDetails')) {
            if (item.productSubDetails && item.productSubDetails.hasOwnProperty('internationalProduct')) {
              if (item.productSubDetails && !item.productSubDetails.internationalProduct.hasOwnProperty('requiresRevision')) {
                item.productSubDetails.internationalProduct.requiresRevision = false;
              }
            } else {
              let req =
              {
                "productName": "",
                "subProductName": "",
                "requiresRevision": false
              }
              item.productSubDetails.internationalProduct = req;
            }
            if (item.productSubDetails && item.productSubDetails.hasOwnProperty('usDomesticProduct')) {
              if (!item.productSubDetails.usDomesticProduct.hasOwnProperty('requiresRevision')) {
                item.productSubDetails.usDomesticProduct.requiresRevision = false;
              }
            } else {
              let req =
              {
                "productName": "",
                "subProductName": "",
                "requiresRevision": false
              }
              item.productSubDetails.usDomesticProduct = req;
            }
          } else {
            let req = {
              "internationalProduct": {
                "productName": "",
                "subProductName": "",
                "requiresRevision": false
              },
              "usDomesticProduct": {
                "productName": "",
                "subProductName": "",
                "requiresRevision": false
              }
            }
            item.productSubDetails = req;
          }
        });
        this.originalBenefitsList = JSON.parse(JSON.stringify(data.benefits));
        this.initialBenefitsList = JSON.parse(JSON.stringify(data.benefits));
        this.unusedBenefitsList = this.originalBenefitsList.filter(benefit => benefit.suppress);
        this.configureProgramBenefits(data.benefits, this.program);
      } else if (!closeFlag) {
        this.loadProgramBenefits(this.program);
      }
    });
    this.loadDivision();
    if (this.program && this.program.programExpirationDate) {
      const trimmedDate = (moment(this.program.programExpirationDate)).format('MM/DD/YYYY');
      (moment(moment().format('MM/DD/YYYY')).isSameOrAfter(moment(trimmedDate).format('MM/DD/YYYY'))) ? this.validForDeactivation = false : this.validForDeactivation = true;
    }
    // this.initInitialContactForm();
    // this.initialContactFormPatchValue(this.program);
    if((this.program && this.program.draft) || (this.program && this.program.isNew)){
      this.isCheckboxDisable = true;
    }
    if(this.program?.active){
      this.diasbleDynamicForm = true;
    } else {
      if (this.program && this.program.programExpirationDate) {
        const formattedDate = (moment(this.program.programExpirationDate)).format('MM/DD/YYYY');
        if (moment(moment().format('MM/DD/YYYY')).isSameOrAfter(moment(formattedDate).format('MM/DD/YYYY'))) {
          this.diasbleDynamicForm = true;
        }
      }
    }
    if(this.program?.draft){
      this.isDraftProgram = this.program?.draft;
    }
  }

  focusNextItem(event: KeyboardEvent) {
    const nextElement = (event.target as HTMLElement).nextElementSibling;
    if (nextElement instanceof HTMLElement) {
      nextElement.focus();
    }
  }

  // ...new code...
  onToggleChange(toggledOn: boolean): void {
    // Only update if valueSystem = 'cashAndPoints'
    if (this.program?.valueSystem === ProgramValueType.BOTH) {
      this.userValueSelection = toggledOn ? ProgramValueType.POINTS : ProgramValueType.CASH;
    }
  }
  // ...new code...

  focusPreviousItem(event: KeyboardEvent) {
    const previousElement = (event.target as HTMLElement).previousElementSibling;
    if (previousElement instanceof HTMLElement) {
      previousElement.focus();
    }
  }

  /** Rearranges original benefit array with updated category of dragged item*/
  drop(event: CdkDragDrop<string[]>) {
    if ( this.userCapabilities > -1 && (this.program && this.program.isNew || this.program && this.program.draft)) {
      this.originalBenefitsList.filter(ele => {
        if (ele.reference === this.itemDragged.reference && ele.category !== event.container.id) {
          ele.category = event.container.id;
        }
      })
      this.updatedBenefits = this.originalBenefitsList;
      this.benefitsList = this.groupBenefits(this.originalBenefitsList);
    }
  }
  /** Captures index of dragged item */
  dragStarted(event, itemIndex: number, benefitIndex: number) {
    if (this.userCapabilities > -1 && (this.program.isNew || this.program.draft)) {
      this.disableDrag = false;
      event?event.source.element.nativeElement.setAttribute('style', 'border:3px solid #ffae4e; flex-direction: row;box-sizing: border-box;display: flex;'):null;
      this.itemDragged = this.benefitsList[benefitIndex].items[itemIndex];
    } else {
      this.disableDrag = true;
    }
  }

  /** Rearranges original benefit array with updated category of dragged item*/
  changeCategory(benefit, category) {
    if ((this.userRole == 'program-admin' || this.userRole == 'product-mgmt-admin') && (this.program.isNew || this.program.draft)) {
      this.spinner.show();
      this.originalBenefitsList.filter(ele => {
        if (ele.reference === benefit.reference && ele.category !== category) {
          ele.category = category;
        }
      })
      this.updatedBenefits = this.originalBenefitsList;
      setTimeout(()=>{
        this.benefitsList = this.groupBenefits(this.originalBenefitsList);
      },0)
      this.spinner.hide();
    }
  }

  loadDivision() {
    if (this.program && this.program.divisions && this.program.divisions.length > 0) {
      this.programDivisions = this.program.divisions;
    }
  }
  assignDivision(selectedProg?: any) {
    this.divisionSelection = this.dialog.open(DivisionAssociationComponent, {
      data: {
        clientId: this.program.selectedClientId,
        program: this.program,
        from: 'edit'
      },
      panelClass: 'DataviewSelectionModal',
      autoFocus: false,
      restoreFocus: false
    });
    this.divisionSelection.afterClosed().subscribe((publishDivision: any) => {
      if (publishDivision.length > 0) {
        this.programDivisions = publishDivision;
        this.program.divisions = publishDivision;
      } else {
        this.publishDivisionFlag = false;
      }
      if (this.publishDivisionFlag) {
        if (this.program.atlasPolicy && this.program.contractSeries) {
          this.publishProgram();
        } else {
          this.toastrService.error(`${this.program.atlasPolicy ? null : 'Policy'}${this.program.atlasPolicy && this.program.contractSeries ? null : ' and '}${this.program.contractSeries ? null : 'Legacy Contract'} is required to publish the Program.`);
        }
      }
    });
  }

  openDownloadToastr() {
    // tslint:disable-next-line: max-line-length
    const message = 'Downloading files with sensitive information is a security risk, please clear your cache afterwards';
    const toaster = this.toastr.open(message, 'DOWNLOAD', {
      duration: 10000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: ['toasterMessage']
    });
    toaster.onAction().subscribe(_ => this.downloadProgram());
  }
  getNonDeletedComplex_Rules(benefit: any) {
    let validComplexRule = [];
    validComplexRule = benefit.scopes? benefit.scopes.complex_rules.filter((item) => {
      if (item.action !== 'delete') {
        return item;
      }
    }):null;
    return validComplexRule;
  }
  configureProgramBenefits(benefitList, program) {
    let benefits: Benefit[] = this.loadRule(benefitList);
    if (this.isMMUser) {
      const guaranteedBenefits = benefits.filter(benefit => benefit && benefit.hasOwnProperty('amount') && benefit.amount?.toString() === 'Guaranteed');
      benefitList = guaranteedBenefits.concat(benefits.filter(benefit => benefit && benefit.hasOwnProperty('amount') && benefit.amount?.toString() !== 'Guaranteed'));
      this.newelyAddedBenefits.push(benefitList.filter(e => e.isNewlyAdded == true));
    } else {
    const guaranteedBenefits = benefits.filter(benefit => benefit && benefit.hasOwnProperty('points') && benefit.points?.toString() === 'Guaranteed');
    benefitList = guaranteedBenefits.concat(benefits.filter(benefit => benefit && benefit.hasOwnProperty('points') && benefit.points?.toString() !== 'Guaranteed'));
    this.newelyAddedBenefits.push(benefitList.filter(e => e.isNewlyAdded == true));
    }
    this.newelyAddedBenefits.push(benefitList.filter(e => e.isNewlyAdded == true));
    if (program.isNew || program.draft || program.active) {
      benefitList.forEach((item) => {
        !item.consultantBenefitInfo ? item.consultantBenefitInfo = { consultant: false } : null;
        let maxSelection = 0;
        if ((item.advCashOut || item.advCashOutV2) && item.hasOwnProperty('tierConfigs')) {
          item.tierConfigs.forEach((point) => maxSelection = point.maxSelection + maxSelection);
          item.maxSelection = maxSelection;
        }
        //item.scopes.valid_complex_rules = this.getNonDeletedComplex_Rules(item);
      });
      this.disableDrag = false;
    }
    // else {
    //   benefitList.forEach((item) => {
    //     item.icon = this.programService.mapIconToBenefit(item.reference, this.program.isNew);
    //   });
    // }
   if(this.tiersValues != undefined){
    benefitList.map(element=>{
      if(element.displayName === this.displayNames){
        element['tierValues'] = this.tiersValues;
      }
  });
   }
    this.benefitsList = this.groupBenefits(benefitList);
  }

  loadProgramBenefits(program: Program) {
    let manageMoveUser = this.isMMUser === true ? true : false;
    if (program) {
      const clientid = program.isNew ? program.cartusClientId : program.selectedClientId;
      const programName = program.isNew ? program.templateName : program.programName;
      const programType = program.isNew ? program.programTypeInfo && program.programTypeInfo.type ? program.programTypeInfo.type : 'Benefits Builder' : null;
      setTimeout(() => {
        this.spinner.show();
     }, 1000, true);
      this.programService.getProgramBenefits(clientid, programName, program.isNew ? false : true, manageMoveUser, programType).subscribe(data => {
        if (data) {
          data.benefits = data.benefits.sort((a, b) => a.displayName.localeCompare(b.displayName));
          data.benefits.forEach((item) => {

            if (item.hasOwnProperty('isNewlyAdded')) {
              item['isNewlyAddedRef'] = true;
             }

            if (item.hasOwnProperty('productSubDetails')) {
              if (item.productSubDetails.hasOwnProperty('internationalProduct')) {
                if (!item.productSubDetails.internationalProduct.hasOwnProperty('requiresRevision')) {
                  item.productSubDetails.internationalProduct.requiresRevision = false;
                }
              } else {
                let req =
                {
                  "productName": "",
                  "subProductName": "",
                  "requiresRevision": false
                }
                item.productSubDetails.internationalProduct = req;
              }
              if (item.productSubDetails && item.productSubDetails.hasOwnProperty('usDomesticProduct')) {
                if (!item.productSubDetails.usDomesticProduct.hasOwnProperty('requiresRevision')) {
                  item.productSubDetails.usDomesticProduct.requiresRevision = false;
                }
              } else {
                let req =
                {
                  "productName": "",
                  "subProductName": "",
                  "requiresRevision": false
                }
                item.productSubDetails.usDomesticProduct = req;
              }
            } else {
              let req = {
                "internationalProduct": {
                  "productName": "",
                  "subProductName": "",
                  "requiresRevision": false
                },
                "usDomesticProduct": {
                  "productName": "",
                  "subProductName": "",
                  "requiresRevision": false
                }
              }
              item.productSubDetails = req;
            }

          });
          this.originalBenefitsList = JSON.parse(JSON.stringify(data.benefits));
          this.unusedBenefitsList = this.originalBenefitsList.filter(benefit => benefit.suppress);
          this.configureProgramBenefits(data.benefits, program);
          // this.benefit.icon = this.programService.mapIconToBenefit(this.benefit.reference);

          /*let benefits : Benefit[]= this.loadRule(data.benefits);
          const guaranteedBenefits = data.benefits.filter(benefit => benefit.points.toString() === 'Guaranteed');
          data.benefits = guaranteedBenefits.concat(data.benefits.filter(benefit => benefit.points.toString() !== 'Guaranteed'));
          if (program.isNew || program.draft) {
            data.benefits.forEach((item) => {
              !item.consultantBenefitInfo ? item.consultantBenefitInfo = {consultant : false} :null;
            });
          }
          this.benefitsList = this.groupBenefits(benefits);*/
          this.spinner.hide();
        } else {
          this.toastrService.error('Error fetching Benefits', 'Error');
          this.spinner.hide();
        }
      }, () => {
        this.toastrService.error('Error fetching Benefits', 'Error');
        this.spinner.hide();
      });
    }
  }

  loadRule(benefitList) {
    benefitList.forEach((benefit, key) => {
      if ((benefit.exclusions && benefit.exclusions.length > 0) || (benefit.inclusions && benefit.inclusions.length > 0) || (benefit.prerequisites && benefit.prerequisites.length > 0)) {
        benefitList[key].excludeRule = [];
        benefitList[key].includeRule = [];
        benefit.exclusions ? benefit.exclusions.forEach(exclude => {
          const benefitName = benefitList[benefitList.findIndex(item => exclude === item.reference)].displayName;
          benefitList[key].excludeRule && benefitList[key].excludeRule.length > 0 && benefitList[key].excludeRule.length  ?
            benefitList[key].excludeRule.push(benefitName) :
            benefitList[key].excludeRule = [benefitName];
        }) : benefitList[key].excludeRule = [];
        benefit.inclusions ? benefit.inclusions.forEach(exclude => {
          const benefitName = benefitList[benefitList.findIndex(item => exclude === item.reference)].displayName;
          benefitList[key].includeRule && benefitList[key].includeRule.length > 0 && benefitList[key].includeRule.length  ?
            benefitList[key].includeRule.push(benefitName) :
            benefitList[key].includeRule = [benefitName];
        }) : benefitList[key].includeRule = [];
        //benefitList[key].prerequisiteRule = [];
        benefit.prerequisites ? benefit.prerequisites.forEach(prerequisite => {
          const parentIndex = benefitList.findIndex(item => prerequisite === item.reference);
          const benefitIndex = benefitList[parentIndex] && benefitList[parentIndex].prerequisiteRule ? benefitList[parentIndex].prerequisiteRule.findIndex(rule => rule.reference === benefitList[key].reference) : null;
          if (benefitList[parentIndex] && benefitList[parentIndex].prerequisiteRule && benefitList[parentIndex].prerequisiteRule.length > 0 &&
            benefitIndex === -1) {
            benefitList[parentIndex].prerequisiteRule.push(benefitList[key])
          } else {
            if (benefitList[parentIndex] && benefitList[parentIndex].prerequisiteRule) {
              benefitList[parentIndex].prerequisiteRule = [benefitList[key]];
            } else if (benefitList[parentIndex] && benefitList[key] && !benefitList[key].prerequisiteRule && !benefitList[parentIndex].prerequisiteRule) {
              benefitList[parentIndex].prerequisiteRule = [benefitList[key]];
            } else if (benefitList[parentIndex] && benefitList[key] && benefitList[key].prerequisiteRule && !benefitList[parentIndex].prerequisiteRule) {
              let keyBenefit = { ...benefitList[key] };
              delete keyBenefit.prerequisiteRule;
              benefitList[parentIndex].prerequisiteRule = [keyBenefit];
            }
          }
          // benefitList[parentIndex] && benefitList[parentIndex].prerequisiteRule && benefitList[parentIndex].prerequisiteRule.length > 0 &&
          //   benefitIndex === -1 ? benefitList[parentIndex].prerequisiteRule.push(benefitList[key]) :
          //   //benefitList[parentIndex].prerequisiteRule? benefitList[parentIndex].prerequisiteRule.splice(benefitList[parentIndex].prerequisiteRule.findIndex(item => prerequisite === item.reference), 1):
          //   benefitList[parentIndex] && benefitList[parentIndex].prerequisiteRule ? benefitList[parentIndex].prerequisiteRule = [benefitList[key]] : 
          //   benefitList[parentIndex] && benefitList[key] && !benefitList[key].prerequisiteRule && !benefitList[parentIndex].prerequisiteRule ? benefitList[parentIndex].prerequisiteRule = [benefitList[key]] : 
          //   benefitList[parentIndex] && benefitList[key] && benefitList[key].prerequisiteRule && !benefitList[parentIndex].prerequisiteRule ? benefitList[parentIndex].prerequisiteRule = [benefitList[key]] 
          //   : null;
        }) : null;
      } else {
        benefit.exclusions && benefit.exclusions.length === 0 ? benefitList[key].excludeRule = [] : null;  
        benefit.inclusions && benefit.inclusions.length === 0 ? benefitList[key].includeRule = [] : null;
        benefit.prerequisites && benefit.prerequisites.length === 0 ? benefitList[key].prerequisiteRule = [] : null;
      }

      benefit.scopes? benefit.scopes.valid_complex_rules = this.getNonDeletedComplex_Rules(benefit) : null;
    });
    return benefitList;
  }

  groupBenefits(benefits: Array<Benefit>): Array<{ group: string, items: Array<Benefit> }> {
    const benefitsByGroup = this.BenefitCategories.map(category => {
      if(category){
        this.connectedTo.push(category);
        return {
          group: category,
          items: benefits.filter(benefit => benefit.category === category).sort((a, b) => a.displayName.localeCompare(b.displayName))
        };
      }
    });
    return benefitsByGroup;
  }

  textSearchBenefits(searchText) {
    if (searchText && searchText.length >= 3 || searchText.length === 0) {
      const benefitList = this.loadRule(this.originalBenefitsList);
      const searchBenefits = benefitList.filter(benefit =>
        benefit.displayName.toLowerCase().search(searchText.toLowerCase()) !== -1);
      this.benefitsList = this.groupBenefits(searchBenefits);
      this.searchValue = searchText;
    }
  }

  textSearch(event) {
    if (event.key === 'Backspace' && event.target.value.length === 0) {
      this.textSearchBenefits(event.target.value);
    }
  }

  clearTextSearch() {
    this.benefitsList = this.groupBenefits(this.originalBenefitsList);
    this.searchValue = '';
  }

  publishProgram() {
    if (this.program.atlasPolicy && this.program.atlasPolicy.length && this.program.contractSeries) {
      const divisions = this.programDivisions.map((element: any) => {
        return (element.division_id);
      });
      const cashOutBenefit = this.originalBenefitsList.find(item => (item.advCashOut || item.advCashOutV2) && !item.suppress);
      const cashOutMMUBenefit = this.originalBenefitsList.find(item => (item.cashOut) && !item.suppress);
      const singleSelectmatch = this.originalBenefitsList.filter(item => (item.singleSelectTier && !item.suppress && (!item.hasOwnProperty('tierValues') || (item.tierValues && item.tierValues.length === 0))))
      .map(ele => {
        return ele.displayName
      });
      this.isTriggeredAccessAdded = true;
      if (divisions.length === 0 && !this.publishDivisionFlag) {
        this.assignDivision(this.program);
        this.publishDivisionFlag = true;
      } 
      else if (cashOutBenefit && (!cashOutBenefit.tierConfigs || cashOutBenefit.tierConfigs.length === 0)) {
        this.toastrService.error(`Please configure the cash out logic for <br> Cash Out Card before Publishing for Use.`, '', { enableHtml: true });
      }
       //logic for cashout for MMClient with triggeredaccess info
      else if (cashOutMMUBenefit && (cashOutMMUBenefit.scopes && (!cashOutMMUBenefit.scopes.triggered_access || cashOutMMUBenefit.scopes.triggered_access.action))) {
        this.isTriggeredAccessAdded = false;
        this.toastrService.error(`Please configure triggered access logic for ${cashOutMMUBenefit.displayName} card before Publishing for Use`,'', { enableHtml: true });
      }
      else if (singleSelectmatch && singleSelectmatch.length > 0) {
        this.toastrService.error(`Please configure the Variable Benefit Choices logic for ${singleSelectmatch.join(" , ")} card before Publishing for Use`, '', { enableHtml: true });
      } else if (!this.dynamicFormValid) {
        this.toastrService.error(`Please configure the Lump Sum logic before Publishing for use`, '', { enableHtml: true });
      } else {
        if (this.program.draft) {
          this.originalBenefitsList.map(benefit => {
            if(benefit.reference.includes(this.dynamicBenefitService.lumpSumBenefitPrefix)){
              if(this.produstSpecAttributes){
                this.updatedBenefits = [];
                benefit['productAttributes'] = this.produstSpecAttributes;
                this.updatedBenefits.push(benefit);
                return benefit;
              }   
            }
          })
          this.updateBenefitForProgram(this.updatedBenefits, false); // for publishing drafted program
          return;
        }
        this.spinner.show();

        const benefitList: Array<Benefit> = Array.prototype.concat.apply([], [...this.benefitsList
          .map(group => group.items.map(benefit => benefit))
        ]);

        const suppressList = benefitList.filter(benefit => benefit.suppress).map(benefit => benefit.reference);
        let originalBenefitList = JSON.parse(JSON.stringify(this.originalBenefitsList));
        originalBenefitList = originalBenefitList.map(benefit => {
          if (suppressList.includes(benefit.reference)) {
            benefit.suppress = true;
          }
          benefit.points = isNaN(benefit.points) ? benefit.points : benefit && benefit.points ? parseFloat(benefit.points.toString()) : benefit.points;
          delete benefit.icon;
          if (benefit.multiplicity || benefit.advCashOut || benefit.advCashOutV2) {
            benefit.maxSelection = isNaN(benefit.maxSelection) ? benefit.maxSelection : benefit && benefit.maxSelection ?  parseFloat(benefit.maxSelection.toString()) : benefit.maxSelection;
          } else {
            delete benefit.maxSelection;
          }
          if (benefit.incrementalTimespan) {
            delete benefit.incrementalTimespan;
          }
          if (benefit.isNewlyAddedRef) {
            delete benefit.isNewlyAddedRef;
          }
          if (benefit.consultantBenefitInfo && !benefit.consultantBenefitInfo.consultant) {
            delete benefit.consultantBenefitInfo;
          }
          if(benefit.parentClonedCopy) {
            delete benefit.parentClonedCopy;
          }
          if(benefit.parentClonedCopy) {
            delete benefit.parentClonedCopy;
          }
          if (benefit.preRangeIncrementValue) {
            delete benefit.preRangeIncrementValue;
          }
          if (benefit.preRangeIncrementUnit) {
            delete benefit.preRangeIncrementUnit;
          }
          if (benefit.preCoreUnitsPerSelection) {
            delete benefit.preCoreUnitsPerSelection;
          }
          (benefit && benefit.preMaxSelection) ? delete benefit.preMaxSelection : null;
          (benefit && benefit.prerequisiteRule) ? delete benefit.prerequisiteRule : null;
          (benefit && benefit.excludeRule) ? delete benefit.excludeRule : null;
          (benefit && benefit.includeRule) ? delete benefit.includeRule : null;
          (benefit && benefit.scopes && benefit.scopes.valid_complex_rules) ? delete benefit.scopes.valid_complex_rules : null;
          benefit.hasOwnProperty('currencyCode') ? delete benefit.currencyCode : null;
          if(benefit.productSpecificationReference && benefit.productSpecificationReference?.reference) {
            benefit.productSpecificationReference = benefit.productSpecificationReference.reference
          }
          return benefit;
        });
        let newProgram: ProgramRequest= {
          cartusClientId: this.program.cartusClientId,
          clientId: this.program.selectedClientId,
          // tslint:disable-next-line: radix
          contractId: this.program.contractSeries,
          // tslint:disable-next-line: radix
          policyName: this.program.atlasPolicy,
          programName: this.program.programName,
          standardProgramName: this.program.templateName,
          benefits: originalBenefitList,
          divisions: divisions,
          policyCallRequired: this.program.policyCallRequired,
          isCartusInsured: this.program.isCartusInsured,          
          initialContactRequired: this.program.initialContactRequired,
          isDEIEnabled: this.program.isDEIEnabled,
          isSustainabilityEnabled: this.program.isSustainabilityEnabled,
          valueSystem: this.program.valueSystem,
          totalAmount: Number(this.program.totalAmount),
          currency: this.program.currency
        };
        newProgram.policyCallDuration = this.program.policyCallDuration ? this.program.policyCallDuration : 60;
        this.program.initialContactBy ? newProgram.initialContactBy =  this.program.initialContactBy : '';
        // if(this.initialContactForm.value.initialContactRequired){
        //   newProgram.initialContactRequired = this.program.initialContactRequired ? this.program.initialContactRequired : this.initialContactForm.value.initialContactRequired
        //   newProgram.initialContactBy = this.program.initialContactBy ? this.program.initialContactBy :this.initialContactForm.value.initialContactBy
        // }
        if (this.isMMUser) {
          newProgram['currency']= this.program.currency ;
          newProgram['totalAmount']= this.program && this.program.totalAmount ?  parseInt(this.program.totalAmount.toString()) : null;
        } else if (!this.isMMUser && !this.isGOUser) {
          newProgram['points']= this.program && this.program.totalPoints ?  parseInt(this.program.totalPoints.toString()) : null ;
        }
          else if (this.isGOUser) {
            if (newProgram['points'] || newProgram['totalAmount']) {
              delete newProgram['points'];
              delete newProgram['totalAmount'];
              delete newProgram['currency'];
            }
            newProgram['pointsValueAllocationType']= this.program && this.program.pointsValueAllocationType ?  this.program.pointsValueAllocationType.toString() : null;
          }
        if (this.program.programExpirationDate) {
          newProgram.programExpirationDate = this.program.programExpirationDate;
        }
        // ProgramType Information
        if(this.program && this.program.programTypeInfo) {
          if(this.program.programTypeInfo.type == 'Traditional'){
            delete newProgram['policyCallRequired'];
            delete newProgram['policyCallDuration'];
            delete newProgram['isCartusInsured'];
            delete newProgram['isDEIEnabled'];
            delete newProgram['isSustainabilityEnabled'];
            delete newProgram['points'];
            newProgram['initialAuditRequired'] = this.program.initialAuditRequired;
          }
          newProgram['programTypeInfo'] = this.program.programTypeInfo;
        }
        if(this.program.programTypeInfo.type == 'Benefits Builder' && this.program.moveType ==="Core/Flex" || "Capped Move"){
          newProgram['programTypeInfo']['subType'][0] = this.program.moveType;
        }

        newProgram.benefits.forEach(benefit => {
          if (benefit.reference.includes(this.dynamicBenefitService.lumpSumBenefitPrefix)) {
            if (this.produstSpecAttributes) {
              benefit['productAttributes'] = this.produstSpecAttributes;
            }
          }
          if (benefit.isNewlyAddedRef) {
            delete benefit.isNewlyAddedRef;
          }
          benefit.hasOwnProperty('currencyCode') ? delete benefit.currencyCode : null;
          if(benefit.productSpecificationReference && benefit.productSpecificationReference?.reference) {
            benefit.productSpecificationReference = benefit.productSpecificationReference.reference
          }
        });
        if(this.isMMUser && this.isTriggeredAccessAdded){
          this.programService.publishProgram(newProgram).subscribe(data => {
            if (data) {
              this.toastrService.info('Program set to active', null, {
                progressBar: true,
                timeOut: 2000
              });
              this.spinner.hide();
              setTimeout(() => {
                this.program.isNew = false;
                this.closeProgram();
              }, 2000);
            } else {
              this.spinner.hide();
              this.toastrService.error('Error publishing program, please try again');
            }
          }, () => {
            this.spinner.hide();
            this.toastrService.error('Error publishing program, please try again');
          });
        }
        else if (!this.isMMUser){
          this.programService.publishProgram(newProgram).subscribe(data => {
            if (data) {
              this.toastrService.info('Program set to active', null, {
                progressBar: true,
                timeOut: 2000
              });
              this.spinner.hide();
              setTimeout(() => {
                this.program.isNew = false;
                this.closeProgram();
              }, 2000);
            } else {
              this.spinner.hide();
              this.toastrService.error('Error publishing program, please try again');
            }
          }, () => {
            this.spinner.hide();
            this.toastrService.error('Error publishing program, please try again');
          });
        }
      }
    } else {
      if (!this.program?.atlasPolicy.length) {
        this.toastrService.error('Policy is required to publish the Program.');
      } else {
        this.toastrService.error(`${this.program.atlasPolicy ? null : 'Policy'}${this.program.atlasPolicy &&
          this.program.contractSeries ? null : ' and '}${this.program.contractSeries ? null : 'Legacy Contract'} is required to publish the Program.`);
      }     
    }

  }


  closeProgram() {
    if (this.program && this.program.isNew) {
      const dialog = this.dialog.open(ProgramCloseWarningComponent, {
        width: '300px',
        data: { message: "Are you sure you want to cancel the Program creation?" }
      });
      dialog.afterClosed().subscribe(data => {
        if (data) {
          this.close.emit(true);
          this.programService.benefitListSub.next({ benefits: [] });
        }
      });
    } else {
      this.close.emit(true);
      this.programService.benefitListSub.next({ benefits: [] });

    }
  }

  /**
   * To download program as PDF
   */
  downloadProgram() {
    let filteredBenefits;
    if (this.benefitsList) {
      if(!this.isMMUser) {
        filteredBenefits = this.benefitsList.map(category => {
          return {
            group: category.group,
            items: category.items.filter(item => !item.suppress),
            points: category.items.filter(benefit => benefit && benefit.points && benefit.points.toString() === 'Guaranteed').
              concat(category.items.filter(benefit => benefit && benefit.points && benefit.points.toString() !== 'Guaranteed'))
          };
        }).filter(category => category.items.length !== 0);
      } else {
        filteredBenefits = this.benefitsList.map(category => {
          return {
            group: category.group,
            items: category.items.filter(item => !item.suppress),
            totalAmount: category.items.filter(benefit => benefit && benefit.amount && benefit.amount.toString() === 'Guaranteed').
              concat(category.items.filter(benefit => benefit && benefit.amount && benefit.amount.toString() !== 'Guaranteed'))
          };
        }).filter(category => category.items.length !== 0);
      }
      // if(this.program.hasOwnProperty('totalAmount')) {
      //   this.program.totalAmountWithCurrency = this.program.totalAmount + ' ' + this.program.currency;
      // }
      pdfMake.createPdf(this.pdfgenerator.generateDocumentDefinition(this.program, this.program.divisions,
        filteredBenefits, this.clientDetails)).download(this.program.programName);
    }
  }

  deleteBenefit(benefit: Benefit, pushToUnused:boolean = true) {
    if (this.userCapabilities > -1 && (this.program.isNew || this.program.draft || this.program.active)) {
      benefit.suppress = !benefit.suppress;
      this.updateProductDetails(benefit, 'suppress');
      this.addBenefitToUpdatedList(benefit);
      pushToUnused ? this.addToUnusedBenefit(benefit) : '';
    }
  }


  deleteisNewlyAdded(benefit: Benefit) {
    if (this.userCapabilities > -1 && (this.program && this.program.draft)) {
      benefit.isNewlyAddedRef = !benefit.isNewlyAddedRef;
      this.addBenefitToUpdatedList(benefit);
      this.updateProductDetails(benefit, 'isNewlyAddedRef');
    }
  }
  groupIndex: number = 0;
  itemIndex: number = 0;

  cloneBenefit(benefit: Benefit, el) {
    this.groupIndex = this.benefitsList.findIndex(xs => xs.group === benefit.category)
    this.itemIndex = this.groupIndex > -1 && this.benefitsList[this.groupIndex] &&  this.benefitsList[this.groupIndex].items ? this.benefitsList[this.groupIndex].items.findIndex((list: Benefit) => list.reference === benefit.reference) : null;
    if(this.groupIndex > -1 && this.itemIndex > -1) {
      if (benefit.clonedCopy) {
        delete this.benefitsList[this.groupIndex].items[this.itemIndex].clonedCopy;
        delete this.benefitsList[this.groupIndex].items[this.itemIndex - 1].parentClonedCopy;
        this.addBenefitToUpdatedList(this.benefitsList[this.groupIndex].items[this.itemIndex]);
        this.CloneCopyDisable = false;
      } else {
        var re = "_BBClone";
        let cloneBenefit = JSON.parse(JSON.stringify(benefit));
        this.benefitsList[this.groupIndex].items.splice(this.itemIndex + 1, 0, {
          ...cloneBenefit,
          displayName: "Clone of " + benefit.displayName,
          reference: benefit.reference.includes("_BBClone_") ? benefit.reference.slice(0, -1) + (+benefit.reference.slice(-1) + 1) : benefit.reference + "_BBClone_" + this.cloneBenefitCount,
          isCloned: true,
          clonedCopy: true,
          editTitle: true,
          clonedFrom: benefit.reference.includes("_BBClone_") ? benefit.reference.slice(0, benefit.reference.lastIndexOf(re)) : benefit.reference
        });
        this.cloneBenefitCount++;
  
        this.benefitsList[this.groupIndex].items[this.itemIndex].parentClonedCopy = true;
        this.originalBenefitsList.push(this.benefitsList[this.groupIndex].items[this.itemIndex + 1]);

        this.publishDisable = this.originalBenefitsList.filter((item) => {
          return (item.multiplicity ? !item.maxSelection || (item.points !== 0 && !item.points) :
            (item.points !== 0 && !item.points && !item.hasOwnProperty('advCashOut') && !item.hasOwnProperty('advCashOutV2')) || item.clonedCopy);
        }).length > 0
        this.CloneCopyDisable = this.publishDisable;
        this.draftDisable = this.publishDisable;
  
        setTimeout(() => {
          this.focusable ? this.focusable?.last?._elementRef?.nativeElement?.focus() : null;
          this.title ? this.title?.last?.nativeElement?.focus(): null;
        }, 0);
      }
    }
   
    if(this.isMMUser) {
      this.publishDisable = false;
    }
  }

  discardBenefit(benefit, j, i) {
    this.cloneBenefitCount--;
    this.benefitsList[j].items.splice(i, 1);
    delete this.benefitsList[j].items[this.benefitsList[j].items.findIndex(item => benefit.clonedFrom == item.reference)].parentClonedCopy;
    this.originalBenefitsList.splice(this.originalBenefitsList.findIndex(item => benefit.reference === item.reference), 1);
    this.publishDisable = this.originalBenefitsList.filter((item) => {
      return (item.multiplicity ? !item.maxSelection || (item.points !== 0 && !item.points) :
        item.points !== 0 && !item.points && !item.hasOwnProperty('advCashOut') && !item.hasOwnProperty('advCashOutV2') && item.clonedCopy);
    }).length > 0
    this.draftDisable = false;
    this.publishDisable = false;
    this.CloneCopyDisable = false;

  }
  /**
   * To open edit program popup to update program title and program expiration date
   */
  editProgramDetails() {
    let editData;
    !this.validForDeactivation
      ? editData = {
        clientId: this.program.selectedClientId,
        program: this.program,
        validForDeactivation: this.validForDeactivation,
        isLumpSumEnabled: this.isLumpSumEnabled
      }
      : editData = {
        clientId: this.program.selectedClientId,
        program: this.program,
        isLumpSumEnabled: this.isLumpSumEnabled
      };
    const dialogRef = this.dialog.open(ProgramModalComponent, {
      panelClass: 'dialog-full-width',
      data: editData
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.program.contractSeries = [];
        this.program.programExpirationDate = data.programExpirationDate !== '' ? data.programExpirationDate : null;
        this.program.policyCallRequired = data.policyCallRequired;
        this.program.policyCallDuration = data.policyCallDuration === "" ? 60 : data.policyCallDuration;
        this.program.programName = data.programName;
        this.program.atlasPolicy = data.atlasPolicy;
        this.program.totalPoints = data.totalPoints;
        this.program.contractSeries = data.contractSeries.length > 0 ? [...data.contractSeries] : [];
        this.program.isCartusInsured = data.isCartusInsured;
        this.program.valueSystem =  data.valueSystem;
        this.program.moveType = data.moveType; 
        this.program.currency = data.currency;
        this.program.totalAmount = data.totalAmount;
        this.program.pointsValueAllocationType = data.pointsValueAllocationType;
        this.program.initialContactRequired = data.initialContactRequired;
        this.program.initialContactBy = data.initialContactBy;
        this.program.isDEIEnabled = data.isDEIEnabled;
        this.program.isSustainabilityEnabled = data.isSustainabilityEnabled;
      }
      if (this.program.programExpirationDate) {
        (moment(moment().format('MM/DD/YYYY')).isSameOrAfter(moment(this.program.programExpirationDate).format('MM/DD/YYYY'))) ? this.validForDeactivation = false : this.validForDeactivation = true;
      }
    });
  }

  closePointMenu(benefit) {
    const index = this.originalBenefitsList.findIndex((item) => item.reference === benefit.reference);
    this.originalBenefitsList[index].points = benefit.points !== 'Guaranteed' && benefit.points && benefit.points !== 0 ?
      Number(benefit.points) : benefit.points;
    this.originalBenefitsList[index].maxSelection = +benefit.maxSelection;
    this.publishDisable = this.originalBenefitsList.filter((item) => {
      return (item.multiplicity ? !(item.maxSelection) || (item.points !== 0 && !item.points) :
        (item.perPointCurrency ? (+item.maxSelection <= 0) : item.points !== 0 && !item.points && !item.hasOwnProperty('advCashOut') && !item.hasOwnProperty('advCashOutV2')));
    }).length > 0;
    this.draftDisable = this.originalBenefitsList.filter((item) => {
      return (item.multiplicity ? !item.maxSelection || (item.points !== 0 && !item.points) :
        item.points !== 0 && !item.points && !item.hasOwnProperty('advCashOut') && !item.hasOwnProperty('advCashOutV2'));
    }).length > 0;
    if (this.program.draft) {
      this.addBenefitToUpdatedList(benefit);
    }
    if (benefit.incremental || benefit.incrementalCurrency) {
      if (benefit.maxSelection <= 0 || benefit.maxSelection == "") {
        benefit.maxSelection = 0;
        this.publishDisable = true;
        this.draftDisable = true;
      } else {
        this.draftDisable = false;
      }
      benefit.rangeMax = (benefit.maxSelection * benefit.rangeIncrementValue);
      this.originalBenefitsList.map(ele => {
        if (ele.reference === benefit.reference) {
          ele.rangeMax = benefit.rangeMax;
        }
        return ele;
      })
      this.addBenefitToUpdatedList(benefit);
    }
    if(this.isMMUser) {
      this.publishDisable = false;
      this.draftDisable = false;
    }
  }

  openPointMenu() {
    this.publishDisable = true;
    this.draftDisable = true;
  }

  closeRangeIncrementValueMenu(benefit, index) {
    //benefit.rangeMax = this.rangeIncrementValue *benefit.rangeMin;
    benefit.rangeMax = this.rangeIncrementValue * benefit.preMaxSelection;
    benefit.rangeIncrementValue = this.rangeIncrementValue;
    let indexOfBenefit = this.originalBenefitsList.findIndex(item => item.reference === benefit.reference);
    if (((this.program.draft || this.program.isNew) && (Number(benefit.rangeIncrementValue) > 0) ||
      (Number(benefit.rangeIncrementValue) > 0 && benefit.incrementalTimespan && this.timeSpanValue.value))) {
        benefit.rangeIncrementValue = Number(benefit.rangeIncrementValue);
        benefit.rangeMin > 0 ? benefit.rangeMin = Number(benefit.rangeIncrementValue) : null;
        let maxSelection = benefit.rangeMax / Number(this.rangeIncrementValue);
        benefit.rangeMax = maxSelection * Number(benefit.rangeIncrementValue);
        benefit.incrementalTimespan? benefit.rangeIncrementUnit = this.timeSpanValue.value: null;
        // benefit.rangeIncrementUnit = this.timeSpanValue.value;
        this.originalBenefitsList[indexOfBenefit] = JSON.parse(JSON.stringify(benefit));
      // }
      // if(this.timeSpanValue.value) {
      //   benefit.rangeIncrementUnit = this.timeSpanValue.value;
      // }
      if (this.program && this.program.draft) {
        this.addBenefitToUpdatedList(benefit);
      }
    } else {
      benefit.rangeIncrementValue = (benefit.rangeMin > 0) ? benefit.rangeMin : this.originalBenefitsList[indexOfBenefit].rangeIncrementValue;
      const toastmsg = 'Please enter value greater than 0';
      this.toastrService.info(toastmsg, null, {
        progressBar: true,
        timeOut: 2000
      });
    }
    // this.draftDisable = false;
    this.draftDisable = this.updatedBenefits.filter((item) => {
      return (item.multiplicity ? !(item.maxSelection) || (item.points !== 0 && !item.points) :
        (item.perPointCurrency ? (+item.maxSelection <= 0) : item.points !== 0 && !item.points && !item.hasOwnProperty('advCashOut') && !item.hasOwnProperty('advCashOutV2')));
    }).length > 0;
    // this.publishDisable = false;
    // this.publishDisable = this.originalBenefitsList.filter((item) => {
    //   return (item.multiplicity ? !item.maxSelection || (item.points !== 0 && !item.points) :
    //     (item.points !== 0 && !item.points && !item.hasOwnProperty('advCashOut')) || item.clonedCopy);
    // }).length > 0;
    this.publishDisable = this.originalBenefitsList.filter((item) => {
      return (item.multiplicity ? !(item.maxSelection) || (item.points !== 0 && !item.points) :
        (item.perPointCurrency ? (+item.maxSelection <= 0) : item.points !== 0 && !item.points && !item.hasOwnProperty('advCashOut') && !item.hasOwnProperty('advCashOutV2')));
    }).length > 0;
    benefit.preMaxSelection = 1;
    this.rangeIncrementValue = 0;
    this.isChipEditable[index] = false;
  }

  openRangeIncrementValueMenu() {
    // if(this.program.draft){
      this.publishDisable = true;
      this.draftDisable = true;
    // }
  }

  closePerPointCurrencyMenu(benefit) {
    benefit.perPointCurrency = Number(benefit.perPointCurrency);
    if (this.program && this.program.draft || this.program && this.program.isNew) {
      if (Number(benefit.perPointCurrency) > 0) {
        this.originalBenefitsList.map(ele => {
          if (ele.reference === benefit.reference) {
            ele.perPointCurrency = benefit.perPointCurrency;
          }
          return ele;
        });
        this.addBenefitToUpdatedList(benefit);
      } else {
        this.originalBenefitsList.forEach(ele => {
          if (ele.reference === benefit.reference) {
            benefit.perPointCurrency = ele.perPointCurrency;
          }
        });
        const toastmsg = 'Please enter value greater than 0';
        this.toastrService.info(toastmsg, null, {
          progressBar: true,
          timeOut: 2000
        });
      }
      this.draftDisable = false;
      this.publishDisable = this.originalBenefitsList.filter((item) => {
        return (item.multiplicity ? !item.maxSelection || (item.points !== 0 && !item.points) :
          (item.points !== 0 && !item.points && !item.hasOwnProperty('advCashOut') && !item.hasOwnProperty('advCashOutV2')) || item.clonedCopy);
      }).length > 0
    }
  }

  /**
   * To update rangeMax value of benefit object
   * @param benefit benefit
   * @param rangeMax modified field
   */
  editRangeMax(benefit) {
    if (benefit.incremental) {
      benefit.maxSelection = benefit.rangeMax / benefit.rangeIncrementValue;
      this.addBenefitToUpdatedList(benefit);
    }
  }

  /**
   * @param benefit benefit
   * @param flag editIncrementCurrency[i] value
   * called on icon click for Dollar Per Point card
   */
  editMaxSelection(benefit, flag) {
    if (benefit.incrementalCurrency) {
      isNaN(benefit.rangeMax) ? benefit.rangeMax = 0 : null;
      benefit.maxSelection = benefit.rangeMax / benefit.rangeIncrementValue;
      (benefit.rangeMax / benefit.rangeIncrementValue === 0) ? benefit.maxSelection = 0 :
        this.addBenefitToUpdatedList(benefit);

      let checkLength = this.updatedBenefits.filter((item) => {
        return (item.multiplicity ? !(item.maxSelection) || (item.points !== 0 && !item.points) :
          (item.perPointCurrency ? (+item.maxSelection <= 0) : item.points !== 0 && !item.points && !item.hasOwnProperty('advCashOut') && !item.hasOwnProperty('advCashOutV2')));
      }) //.length > 0;
      !flag ? this.publishDisable = checkLength.length > 0 : this.publishDisable = true;
      !flag ? this.draftDisable = checkLength.length > 0 : this.draftDisable = true;
    }
  }

  /**
   * To update product object
   * @param benefit benefit
   * @param field modified field
   */
  updateProductDetails(benefit, field: 'title' | 'desc' | 'points' | 'consultantBenefitInfo' | 'rules' | 'restriction' | 'maxSelection' | 'suppress' | 'productSubDetails' | 'isNewlyAdded' | 'isNewlyAddedRef' | 'tasks' | 'isDEIEnabled' | 'isSustainabilityEnabled') {
    const index = this.originalBenefitsList.findIndex((item) => item.reference === benefit.reference);
    if (index != -1) {
    if (field === 'title') {
      this.originalBenefitsList[index].displayName = benefit.displayName;
    } else if (field === 'desc') {
      this.originalBenefitsList[index].description = benefit.description;
    } else if (field === 'consultantBenefitInfo') {
      this.originalBenefitsList[index].consultantBenefitInfo = benefit.consultantBenefitInfo;
    } else if (field === 'rules') {
      benefit.exclusions ? this.originalBenefitsList[index].exclusions = [...benefit.exclusions] : null;
      benefit && benefit.inclusions ? this.originalBenefitsList[index].inclusions = [...benefit.inclusions] : null;
      benefit.prerequisites ? this.originalBenefitsList[index].prerequisites = [...benefit.prerequisites] : null;
      benefit.prerequisiteRule ? this.originalBenefitsList[index].prerequisiteRule = [...benefit.prerequisiteRule] : null;
      benefit.excludeRule ? this.originalBenefitsList[index].excludeRule = [...benefit.excludeRule] : null;
      benefit && benefit.includeRule ? this.originalBenefitsList[index].includeRule = [...benefit.includeRule] : null;
    } else if (field === 'restriction') {
      benefit && benefit.scopes ? (benefit.scopes.valid_complex_rules = this.getNonDeletedComplex_Rules(benefit)) : null;
      benefit && benefit.scopes ? this.originalBenefitsList[index].scopes = { ...benefit.scopes } : null;
    } else if (field === 'maxSelection' && (benefit.advCashOut || benefit.advCashOutV2)) {
      benefit.points = 0;
      this.originalBenefitsList[index].tierConfigs = benefit.tierConfigs;
      benefit && benefit.maxSelection ? this.originalBenefitsList[index].maxSelection = benefit.maxSelection : null;
      benefit && benefit.pointsPerSelection ? this.originalBenefitsList[index].pointsPerSelection = benefit.pointsPerSelection: null;
    } else if (field === 'suppress') {
      this.originalBenefitsList[index].suppress = benefit.suppress;
    } else if (field === 'productSubDetails') {
      this.originalBenefitsList[index].productSubDetails = benefit.productSubDetails;
    } else if (field === 'isNewlyAdded') {
      this.originalBenefitsList[index].isNewlyAdded = benefit.isNewlyAdded;
    } else if (field === 'isNewlyAddedRef') {
      this.originalBenefitsList[index].isNewlyAddedRef = benefit.isNewlyAddedRef;
    } else if(field === 'isDEIEnabled') {
      this.originalBenefitsList[index].isDEIEnabled = benefit.isDEIEnabled;
    } else if(field === 'isSustainabilityEnabled') {
      this.originalBenefitsList[index].isSustainabilityEnabled = benefit.isSustainabilityEnabled
    } else if (field === 'tasks') {
      this.originalBenefitsList[index].tasks = benefit.tasks;
    } else {
      this.originalBenefitsList[index].points = benefit.points;
    }
  }
  }

  /**
   * To be invoked on click of edit icon for description
   * @param benefit benefit
   */
  editProgramDescription(benefit) {
    if (benefit && benefit.editDescription) {
      this.updateProductDetails(benefit, 'desc');
      delete benefit.editDescription;
      if (this.program && (this.program.draft  || this.program.active)) {
        this.addBenefitToUpdatedList(benefit);
      }
      this.draftDisable = false;
      this.publishDisable = false;
    } else {
      benefit.editDescription = true;
      this.draftDisable = true;
      this.publishDisable = true;
    }
  }
  /**
   * To be invoked on click of edit icon for title
   * @param index index of benefit
   * @param benefit benefit
   */
  editProgramTitle(benefit) {
    if (benefit && benefit.editTitle) {
      this.updateProductDetails(benefit, 'title');
      delete benefit.editTitle
      if (this.program && (this.program.draft || this.program.active)) {
        this.addBenefitToUpdatedList(benefit);
      }
      this.draftDisable = false;
      this.publishDisable = false;
    } else {
      benefit.editTitle = true;
      this.draftDisable = true;
      this.publishDisable = true;
    }
  }

  editSustanabilityDEI(benefit, type) {
    this.updateProductDetails(benefit, type);
    if (this.program) {
      this.addBenefitToUpdatedList(benefit);
    }
  }


  openDialog(benefit: any) {
    const triggeredAccess = { type: 'triggered_access', name: 'Triggered Access', configurations: {} };
    const allRestrictionList: Array<any> = [
      { type: 'transferee_related', name: 'Transferee with Spouse' },
      { type: 'complex_rules', name: 'Intra-US or intra-Canada', description: 'Moves within the US or within Canada' },
      { type: 'complex_rules', name: 'US/Canada cross-border', description: 'Moves between the US and Canada' },
      { type: 'complex_rules', name: 'International', description: 'US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada' },
      { type: 'complex_rules', name: 'Intra-country', description: 'Moves where the departure country and destination country match' },
      { type: 'complex_rules', name: 'Inter-country', description: 'Moves where the departure country and destination country do not match' },
      { type: 'complex_rules', name: 'Accompanied', description: 'For transferees that are accompanied by a spouse or partner and/or dependents' },
      { type: 'departure_locations', name: 'Departure Location Includes…', includes: [] },
      { type: 'departure_locations', name: 'Departure Location Excludes…', excludes: [] },
      { type: 'destination_locations', name: 'Destination Location Includes…', includes: [] },
      { type: 'destination_locations', name: 'Destination Location Excludes…', excludes: [] },
      { type: 'complex_rules', name: 'Inter-region', description: 'Moves where the departure region and destination region do not match' },
      { type: 'complex_rules', name: 'Intra-region', description: 'Moves where the departure region and destination region match' },
      { type: 'simple_rules', name: 'Number of Relocating Children'},
      { type: 'simple_rules', name: 'Number of Relocating Dependents'},
      { type: 'simple_rules', name:'Total Number of Relocating Members'}
    ];
    if (benefit && benefit.cashOut && this.isMMUser) { // triggered access added only for MM client cashout benefit
      if(benefit.scopes.triggered_access &&
        (Object.keys(benefit.scopes.triggered_access).length <= 0 || benefit.scopes.triggered_access.action)) {
          delete benefit.scopes.triggered_access;
        }
        allRestrictionList.push(triggeredAccess);
    }
    (benefit && benefit.scopes && benefit.scopes.complex_rules && benefit.scopes.complex_rules.length >0 && benefit.scopes.hasOwnProperty('complex_rules'))
      ? benefit.scopes.valid_complex_rules.forEach(ele => {
        benefit.scopes.complex_rules.findIndex(rule => ele.id === rule.id) >= 0 ? null : benefit.scopes.complex_rules.push(ele);
      })
      : null;
    const prerequisites = [];
    benefit.prerequisiteRule && benefit.prerequisiteRule.length > 0 ? benefit.prerequisiteRule.forEach(item => {
      const index = this.originalBenefitsList.findIndex(benefitRef => benefitRef.reference === item.reference);
      prerequisites.push(this.originalBenefitsList[index]);
    }) : null;
    //prerequisitesBenefit.length > 0 ? prerequisitesBenefit[0].prerequisites.forEach(ref => prerequisites.push(ref)) : null;
    const benefitList = this.originalBenefitsList.filter(item => item.reference !== benefit.reference && !isNaN(item.points));
    benefitList.sort((a, b) => a.displayName.localeCompare(b.displayName));
    const dialogRef = this.dialog.open(EditBenefitDialogComponent, {
      panelClass: 'dialog-full-width',
      autoFocus: false,
      data: {
        benefit: { ...benefit },
        benefitList: [...benefitList],
        allBenefitList: [...this.originalBenefitsList],
        prerequisites: prerequisites,
        allRestrictionList: allRestrictionList
      }
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        //data.benefit.prerequisiteRule.sort((a, b) => a.displayName.localeCompare(b.displayName));
        benefit = { ...data.benefit };
        if (this.program && this.program.draft) {
          this.addBenefitToUpdatedList(benefit);
        }
        this.updateProductDetails(benefit, 'rules');
        this.updateProductDetails(benefit, 'restriction');
        this.updateProductDetails(benefit, 'tasks');
        //this.configureProgramBenefits(this.originalBenefitsList, this.program);

        if (data.prerequisites) {
          benefit.prerequisiteRule = [];
          data.prerequisites.length > 0 ? data.prerequisites.forEach((item) => {
            const parentBenefit = this.originalBenefitsList.find(benefitObj => benefitObj.reference === item);
            /** to set prerequisite rule for benefit */
            benefit.prerequisiteRule && parentBenefit.displayName ?
              benefit.prerequisiteRule.push(parentBenefit) : null;
            benefit.prerequisiteRule.sort((a, b) => a.displayName.localeCompare(b.displayName));
            // (benefit.prerequisiteRule? benefit.prerequisiteRule.splice(benefit.prerequisiteRule.findIndex(rule => rule.displayName == parentBenefit.displayName), 1): benefit.prerequisiteRule = []);
            /** to set parent prerequisite */
            parentBenefit.prerequisites && parentBenefit.prerequisites.length > 0 &&
              !parentBenefit.prerequisites.includes(benefit.reference) ?
              parentBenefit.prerequisites.push(benefit.reference) : parentBenefit.prerequisites = [benefit.reference];
            /** to remove prerequisite from benefit */
            const missingPrerequiiste = prerequisites.filter(prerequisite => data.prerequisites.indexOf(prerequisite.reference) < 0);
            missingPrerequiiste.length > 0 ? missingPrerequiiste.forEach(missprerequisite => {
              const missingPrerequiisteBenefit = this.originalBenefitsList.find(benefitObj => benefitObj.reference === missprerequisite.reference);
              missingPrerequiisteBenefit.prerequisites = missingPrerequiisteBenefit.prerequisites.filter(prerequisiteItem => prerequisiteItem !== benefit.reference);
              this.program.draft ? this.addBenefitToUpdatedList(missingPrerequiisteBenefit) : null;
              this.updateProductDetails(missingPrerequiisteBenefit, 'rules');
            }) : null;

            if (this.program && this.program.draft) {
              this.addBenefitToUpdatedList(benefit);
              this.addBenefitToUpdatedList(parentBenefit);
            }
            this.updateProductDetails(benefit, 'rules');
            this.updateProductDetails(parentBenefit, 'rules');

          }) : prerequisites.forEach((item) => {
            const parentBenefit = this.originalBenefitsList.find(benefitObj => benefitObj.reference === item.reference);
            /** to set prerequisite rule for benefit */
            benefit.prerequisiteRule && benefit.prerequisiteRule.length > 0 ?
              benefit.prerequisiteRule = [] : null;
            /**to set parent prerequisite */
            parentBenefit.prerequisites && parentBenefit.prerequisites.length > 0 ?
              parentBenefit.prerequisites.splice(parentBenefit.prerequisites.findIndex(benefitObj => benefitObj === benefit.reference), 1) : null;
            if (this.program && this.program.draft) {
              this.addBenefitToUpdatedList(benefit);
              this.addBenefitToUpdatedList(parentBenefit);
            }
            this.updateProductDetails(benefit, 'rules');
            this.updateProductDetails(parentBenefit, 'rules');

          });
        }
        this.configureProgramBenefits(this.originalBenefitsList, this.program);
      }
    });
  }

  /**
   * To copy program
   */
  saveCopyAndDraft() {
    const dialogRef = this.dialog.open(CopyProgramModalComponent, {
      panelClass: 'dialog-full-width',
      data: {
        programName: this.program && this.program.programName,
        clientName: this.clientDetails && this.clientDetails.clientEntityName,
        clientNumber: this.clientDetails && this.clientDetails.clientNumber
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.draftProgram(data);
      }
    });
  }

  saveCopy() {
    const dialogRef = this.dialog.open(CopyProgramModalComponent, {
      panelClass: 'dialog-full-width',
      data: {
        programName: this.program.programName,
        clientName: this.clientDetails.clientEntityName,
        clientNumber: this.clientDetails.clientNumber
      }
    });

    dialogRef.afterClosed().subscribe(copyDetails => {
      if (copyDetails) {
        const copiedProgram = { copyProgramDetails: copyDetails };
        this.programService.updateBenefitForProgram(copiedProgram, this.program.id, true, true).subscribe((data) => {
          if (data) {
            const toastmsg = 'Program Copied';
            this.toastrService.info(toastmsg, null, {
              progressBar: true,
              timeOut: 2000
            });
            this.spinner.hide();
            setTimeout(() => {
              this.program.isNew = false;
              this.closeProgram();
            }, 2000);
          } else {
            this.spinner.hide();
            const toastmsg = 'Error copying program, please try again';
            this.toastrService.error(toastmsg);
          }
        },
          () => {
            this.spinner.hide();
            const toastmsg = 'Error copying program, please try again';
            this.toastrService.error(toastmsg);
          });
      }
    });
  }

  /**
   * To be invoked save draft button clicked while program creation
   */
  draftProgram(copyProgramDetails = null, onlyCopy = false) {
    const hasCopy = copyProgramDetails ? true : false;
    const divisions = this.programDivisions.map((element: any) => {
      return (element.division_id);
    });
    if (this.program.draft || this.program.active) {
      
     this.originalBenefitsList.map(benefit => {
        if(benefit.reference.includes(this.dynamicBenefitService.lumpSumBenefitPrefix)){
          if(this.produstSpecAttributes){
            this.updatedBenefits = [];
            benefit['productAttributes'] = this.produstSpecAttributes;
            this.updatedBenefits.push(benefit);
            return benefit;
          }   
        }
      })
      this.updateBenefitForProgram(this.updatedBenefits, this.program.draft, copyProgramDetails, this.program.active);
      return;
    }
    // else if (this.program.active) {
    //   this.updateBenefitForProgram(this.updatedBenefits, this.program.draft, copyProgramDetails, this.program.active);
    //   return;
    // }
    this.spinner.show();


    // const benefitList: Array<Benefit> = Array.prototype.concat.apply([], [...this.benefitsList
    //   .map(group => group.items.map(benefit => benefit))
    // ]);
        const benefitList = [...this.updatedBenefits];
        const suppressList = benefitList.filter(benefit => benefit.suppress).map(benefit => benefit.reference);
        const originalBenefitList = this.originalBenefitsList.map(benefit => {
        if (suppressList.includes(benefit.reference)) {
          benefit.suppress = true;
        }
        benefit.points = isNaN(benefit.points) ? benefit.points : benefit && benefit.points ? parseFloat(benefit.points.toString()) : benefit.points;
        if (benefit.multiplicity || benefit.advCashOut || benefit.advCashOutV2) {
          benefit.maxSelection = isNaN(benefit.maxSelection) ? benefit.maxSelection : benefit && benefit.maxSelection  ? parseFloat(benefit.maxSelection.toString()) :  benefit.maxSelection;
        } else {
          delete benefit.maxSelection;
        }
        if (benefit.incrementalTimespan){
          delete benefit.incrementalTimespan;
        }
        if ( benefit.consultantBenefitInfo && !benefit.consultantBenefitInfo.consultant) {
          delete benefit.consultantBenefitInfo;
        }
        if (benefit.preRangeIncrementValue) {
          delete benefit.preRangeIncrementValue;
        }
        if (benefit.preRangeIncrementUnit) {
          delete benefit.preRangeIncrementUnit;
        }
        if (benefit.preCoreUnitsPerSelection) {
          delete benefit.preCoreUnitsPerSelection;
        }
        if(benefit.parentClonedCopy) {
          delete benefit.parentClonedCopy;
        }
        benefit.preMaxSelection ? delete benefit.preMaxSelection : null;
        benefit.editDescription || benefit.editDescription == false ? delete benefit.editDescription : null;
        benefit.editTitle || benefit.editTitle == false ? delete benefit.editTitle : null;
        benefit.prerequisiteRule? delete benefit.prerequisiteRule:null;
        benefit.excludeRule? delete benefit.excludeRule:null;
        (benefit && benefit.includeRule) ? delete benefit.includeRule : null;
        benefit.scopes && benefit.scopes.valid_complex_rules ? delete benefit.scopes.valid_complex_rules : null;
        benefit.hasOwnProperty('currencyCode') ? delete benefit.currencyCode : null;
        if(benefit.productSpecificationReference && benefit.productSpecificationReference?.reference) {
          benefit.productSpecificationReference = benefit.productSpecificationReference.reference
        }
        if(benefit.reference.includes(this.dynamicBenefitService.lumpSumBenefitPrefix)){
          if(this.produstSpecAttributes){
            benefit['productAttributes'] = this.produstSpecAttributes;
          }   
        }        
        return benefit;
      });
      let newProgram: ProgramRequest = {
        cartusClientId: this.program.cartusClientId,
        clientId: this.program.selectedClientId,
        contractId: this.program.contractSeries,
        policyName: this.program.atlasPolicy,
        programName: this.program.programName,
        standardProgramName: this.program.templateName,
        valueSystem: this.program.valueSystem,
        benefits: JSON.parse(JSON.stringify(originalBenefitList)),
        divisions: divisions,
        policyCallRequired: this.program.policyCallRequired,
        isCartusInsured: this.program.isCartusInsured,
        initialContactRequired: this.program.initialContactRequired,
        draft: true,
        isDEIEnabled: this.program.isDEIEnabled,
        isSustainabilityEnabled: this.program.isSustainabilityEnabled
      };
      newProgram.policyCallDuration = this.program.policyCallDuration ? this.program.policyCallDuration : 60;
      this.program.initialContactBy ? newProgram.initialContactBy =  this.program.initialContactBy : '';
      // if((this.initialContactForm.value.initialContactRequired)){
      //   newProgram.initialContactRequired = this.program.initialContactRequired ? this.program.initialContactRequired : this.initialContactForm.value.initialContactRequired
      //   newProgram.initialContactBy = this.program.initialContactBy ? this.program.initialContactBy :this.initialContactForm.value.initialContactBy
      // }
      if (!this.isMMUser && !this.isGOUser) {
        if(this.program?.valueSystem) {
          newProgram['valueSystem']= this.program?.valueSystem;
        }
        if(!this.program?.valueSystem || this.program?.valueSystem === 'Points' || this.program?.valueSystem === 'Both') {
          newProgram['points']= this.program && this.program.totalPoints ? parseInt(this.program.totalPoints.toString()) : null;
        } 
        if(this.program?.valueSystem === 'Cash' || this.program?.valueSystem === 'Both') {
          newProgram['currency']= this.program.currency ;
          newProgram['totalAmount']= this.program && this.program.totalAmount ? parseInt(this.program.totalAmount.toString()) : null;
        }
      }
        else if (this.isGOUser) {
          if (newProgram['points'] || newProgram['totalAmount']) {
            delete newProgram['points'];
            delete newProgram['totalAmount'];
            delete newProgram['currency'];
          }
          newProgram['pointsValueAllocationType']= this.program && this.program.pointsValueAllocationType ? this.program.pointsValueAllocationType.toString() : null;
        }
      // ProgramType Information
      if(this.program && this.program.programTypeInfo) {
        if(this.program.programTypeInfo.type == 'Traditional'){
          delete newProgram['policyCallRequired'];
          delete newProgram['policyCallDuration'];
          delete newProgram['isCartusInsured'];
          delete newProgram['isDEIEnabled'];
          delete newProgram['isSustainabilityEnabled'];
          delete newProgram['points'];
          newProgram['initialAuditRequired'] = this.program.initialAuditRequired;
        }
      

        newProgram['programTypeInfo'] = this.program.programTypeInfo;
      }
      if(this.program.programTypeInfo.type == 'Benefits Builder' && this.program.moveType ==="Core/Flex" || "Capped Move"){
        newProgram['programTypeInfo']['subType'][0] = this.program.moveType;
      }
    //removing maxSelection from request object
    newProgram.benefits = [...newProgram.benefits.map(benefit => {
      if (benefit.advCashOut || benefit.advCashOutV2) {
        delete benefit.maxSelection;
      }
      if (benefit.incrementalTimespan) {
        delete benefit.incrementalTimespan;
      }
      if (benefit.isNewlyAddedRef) {
        delete benefit.isNewlyAddedRef;
      }
      if (benefit.consultantBenefitInfo && !benefit.consultantBenefitInfo.consultant) {
        delete benefit.consultantBenefitInfo;
      }
      if (benefit.preRangeIncrementValue) {
        delete benefit.preRangeIncrementValue;
      }
      if (benefit.preRangeIncrementUnit) {
        delete benefit.preRangeIncrementUnit;
      }
      if (benefit.preCoreUnitsPerSelection) {
        delete benefit.preCoreUnitsPerSelection;
      }
      if(benefit.parentClonedCopy) {
        delete benefit.parentClonedCopy;
      }
      benefit.preMaxSelection ? delete benefit.preMaxSelection : null;
      benefit.editDescription || benefit.editDescription == false ? delete benefit.editDescription : null;
      benefit.editTitle || benefit.editTitle == false ? delete benefit.editTitle : null;
      benefit.prerequisiteRule ? delete benefit.prerequisiteRule : null;
      benefit.excludeRule ? delete benefit.excludeRule : null;
      (benefit && benefit.includeRule) ? delete benefit.includeRule : null;
      benefit.scopes && benefit.scopes.valid_complex_rules ? delete benefit.scopes.valid_complex_rules : null;
      benefit.hasOwnProperty('currencyCode') ? delete benefit.currencyCode : null;
      return benefit;
    })];



    if (copyProgramDetails) {
      newProgram.copyProgramDetails = copyProgramDetails;
    }
    if (this.program.programExpirationDate) {
      newProgram.programExpirationDate = this.program.programExpirationDate;
    }
    //removing maxSelection from request object
    newProgram.benefits = [...newProgram.benefits.map(benefit => {
      if (benefit.advCashOut || benefit.advCashOutV2) {
        delete benefit.maxSelection;
      }
      return benefit;
    })];
    this.programService.publishProgram(newProgram, hasCopy).subscribe(data => {
      if (data) {
        const toastMsg = hasCopy ? 'Program Copied' : 'Program set to draft';
        this.toastrService.info(toastMsg, null, {
          progressBar: true,
          timeOut: 2000
        });
        this.spinner.hide();
        setTimeout(() => {
          this.program.isNew = false;
          this.closeProgram();
        }, 2000);
      } else {
        this.spinner.hide();
        this.toastrService.error('Error drafting program, please try again');
      }
    }, () => {
      this.spinner.hide();
      this.toastrService.error('Error drafting program, please try again');
    });
  }

  updateProductAttibutes(event){
     this.produstSpecAttributes = event;
  }

  updateBenefitForProgram(updatedBenefits = [], isDraft: boolean = false, copyProgramDetails = null, isActive: boolean = false) {
    const hasCopy = copyProgramDetails ? true : false;
    const updatedlist = updatedBenefits.map((data) => {
      delete data.editTitle;
      delete data.editDescription;
      data.points = isNaN(data.points) ? data.points : parseFloat(data.points);
      delete data.editPoint;
      if (data.multiplicity || data.advCashOut || data.advCashOutV2) {
        data.maxSelection = isNaN(data.maxSelection) ? data.maxSelection : parseFloat(data.maxSelection);
      } else {
        delete data.maxSelection;
      }
      const index = this.originalBenefitsList.findIndex(benefit => benefit.reference === data.reference);
      if(data.consultantBenefitInfo && !data.consultantBenefitInfo.consultant && this.originalBenefitsList[index] &&
          this.originalBenefitsList[index].consultantBenefitInfo && !this.originalBenefitsList[index].consultantBenefitInfo.consultant ) {
        delete data.consultantBenefitInfo.qualifyingInput;
      }
      if(isActive) {
        Object.keys(data).forEach(ele => {
          if(ele !== 'reference' && ele !== 'productSpecificationReference' && ele !== 'displayName' && ele !== 'description'
             && ele !== 'productSubDetails' && ele !== 'suppress' && ele !== 'consultantBenefitInfo' && ele !== 'isDEIEnabled' && ele !== 'isSustainabilityEnabled' ) {
              delete data[ele];
            }
        })
      }
      data.prerequisiteRule ? delete data.prerequisiteRule : null;
      data.excludeRule ? delete data.excludeRule : null;
      data.includeRule ? delete data.includeRule : null;
      data.parentClonedCopy? delete data.parentClonedCopy : null;
      data.scopes && data.scopes.valid_complex_rules ? delete data.scopes.valid_complex_rules : null;
      data.hasOwnProperty('currencyCode') ? delete data.currencyCode : null;
      if(data.productSpecificationReference && data.productSpecificationReference?.reference) {
        data.productSpecificationReference = data.productSpecificationReference.reference
      } 
      return data;
    });
    let requestObj: ProgramBenefitUpdateRequest = {
      benefits: []
    }
    // if(this.initialContactForm.value.initialContactRequired){
    //    requestObj = {
    //     draft: isDraft,
    //     initialContactBy: this.initialContactForm.value.initialContactBy,
    //     initialContactRequired: this.initialContactForm.value.initialContactRequired,
    //     benefits: JSON.parse(JSON.stringify(updatedlist))
    //   };
    // } 
    if(isActive) {
      requestObj = {
        benefits: JSON.parse(JSON.stringify(updatedlist))
      };
    } else {
      requestObj = {
        draft: isDraft,
        benefits: JSON.parse(JSON.stringify(updatedlist))
      };
    }

    //removing maxSelection only for cash out card from request object
    requestObj.benefits = [...requestObj.benefits.map(benefit => {
      if (benefit.advCashOut || benefit.advCashOutV2) {
        delete benefit.maxSelection;
      }
      if (benefit.incrementalTimespan){
        delete benefit.incrementalTimespan;
      }
      if (benefit.isNewlyAddedRef) {
        delete benefit.isNewlyAddedRef;
      }
      if (benefit.preRangeIncrementValue) {
        delete benefit.preRangeIncrementValue;
      }
      if (benefit.preRangeIncrementUnit) {
        delete benefit.preRangeIncrementUnit;
      }
      if (benefit.preCoreUnitsPerSelection) {
        delete benefit.preCoreUnitsPerSelection;
      }
      if(benefit.parentClonedCopy) {
        delete benefit.parentClonedCopy;
      }
      benefit.preMaxSelection ? delete benefit.preMaxSelection : null;
      return benefit;
    })];

    if (copyProgramDetails) {
      requestObj.copyProgramDetails = copyProgramDetails;
    }
    this.spinner.show();
    //for touched benefits
    requestObj.benefits.map(benefit => {
      if (benefit.hasOwnProperty('isNewlyAddedRef')) {
        delete benefit.isNewlyAddedRef;
      }
      if (benefit.hasOwnProperty('isNewlyAdded')) {
        benefit.isNewlyAdded = true;
      }
    });
    // for untouched benefits
    let unTouchedBenefits = [];
    if (!isDraft) {
      if(this.newelyAddedBenefits && this.newelyAddedBenefits.length > 0) {
        this.newelyAddedBenefits[0].forEach(e => {
          if (e.incrementalTimespan) {
            delete e.incrementalTimespan;
          }
          if (e.isNewlyAddedRef) {
            unTouchedBenefits.push(e)
          }
        })
      }

      if(unTouchedBenefits && unTouchedBenefits.length > 0) {
        unTouchedBenefits.forEach(e => {
          if (e.incrementalTimespan) {
            delete e.incrementalTimespan;
          }
          if (e.hasOwnProperty('isNewlyAddedRef')) {
            e.isNewlyAdded = false;
            delete e.isNewlyAddedRef;
          }
          if (e.hasOwnProperty('scopes')) {
            delete e.scopes.valid_complex_rules ;
          }
          e.hasOwnProperty('excludeRule') ? delete e.excludeRule : null;
          e.hasOwnProperty('includeRule') ? delete e.includeRule : null;
          requestObj.benefits.push(e)
        });
      }

    }
    if(this.program.active) {
      delete requestObj.draft;
    }
    if (this.isMMUser && this.isTriggeredAccessAdded){
      this.programService.updateBenefitForProgram(requestObj, this.program.id, hasCopy).subscribe((data) => {
        if (data) {
          const toastmsg = isActive ? 'Program Updated' : isDraft ? (hasCopy ? 'Program Copied' : 'Program set to draft') : 'Program set to active';
          this.toastrService.info(toastmsg, null, {
            progressBar: true,
            timeOut: 2000
          });
          this.spinner.hide();
          setTimeout(() => {
            this.program.isNew = false;
            this.closeProgram();
          }, 2000);
        } else {
          this.spinner.hide();
          const toastmsg = isDraft ? 'Error drafting program, please try again' : 'Error publishing program, please try again';
          this.toastrService.error(toastmsg);
        }
      },
        () => {
          this.spinner.hide();
          const toastmsg = isDraft ? 'Error drafting program, please try again' : 'Error publishing program, please try again';
          this.toastrService.error(toastmsg);
        });
    } else if(!this.isMMUser){
      this.programService.updateBenefitForProgram(requestObj, this.program.id, hasCopy).subscribe((data) => {
        if (data) {
          const toastmsg = isActive ? 'Program Updated' : isDraft ? (hasCopy ? 'Program Copied' : 'Program set to draft') : 'Program set to active';
          this.toastrService.info(toastmsg, null, {
            progressBar: true,
            timeOut: 2000
          });
          this.spinner.hide();
          setTimeout(() => {
            this.program.isNew = false;
            this.closeProgram();
          }, 2000);
        } else {
          this.spinner.hide();
          const toastmsg = isDraft ? 'Error drafting program, please try again' : 'Error publishing program, please try again';
          this.toastrService.error(toastmsg);
        }
      },
        () => {
          this.spinner.hide();
          const toastmsg = isDraft ? 'Error drafting program, please try again' : 'Error publishing program, please try again';
          this.toastrService.error(toastmsg);
        });
    }

  }

  addBenefitToUpdatedList(benefit) {
    const index = this.updatedBenefits.findIndex((data) => data.reference === benefit.reference);
    if (index !== -1) {
      this.updatedBenefits[index] = benefit;
    } else {
      this.updatedBenefits.push(benefit);
    }
  }

  /**
  * Get Deactivate program
  */
  deactivateProgram() {
    this.deactivateModalRef = this.dialog.open(DeactivateProgramModalComponent, {
      data: {
        programList: [this.program && this.program.programName],
        programIdList: [this.program && this.program.id],
        label: 'Deactivate'
      },
      panelClass: 'DataviewSelectionModal',
      autoFocus: false,
      restoreFocus: false
    });
    this.deactivateModalRef.afterClosed().subscribe((data: any) => {
      if (data.isSuccessful) {
        this.program.programExpirationDate = data.data;
        this.displayToastr("Deactivated successfully");
      }
    });
  }

  /**
   * To display informative messages on screen
   * @param message message to be displayed
   */
  displayToastr(message) {
    this.toastrService.info(message, null, {
      closeButton: true,
      enableHtml: true,
      disableTimeOut: false,
    });
  }

  openConsultantModal(benefit: any) {
    if ((benefit.consultantBenefitInfo && !benefit.consultantBenefitInfo.consultant) || !benefit.consultantBenefitInfo) {
      const dialogRef = this.dialog.open(ConsultantOnlyModalComponent, {
        panelClass: 'dialog-full-width',
        autoFocus: false,
        disableClose: true,
        data: {
          benefit: benefit
        }
      });

      dialogRef.afterClosed().subscribe(data => {
        if (data) {
          benefit.consultantBenefitInfo = data;
          if (this.program.draft || this.program.active) {
            this.addBenefitToUpdatedList(benefit);
          }
          this.updateProductDetails(benefit, 'consultantBenefitInfo');
        }
      });
    } else if (benefit.consultantBenefitInfo) {
      benefit.consultantBenefitInfo.qualifyingInput = 0;
      this.addBenefitToUpdatedList(benefit);
      this.updateProductDetails(benefit, 'consultantBenefitInfo');
    }
  }

  openPointsLogicModal(benefit: any) {
    const dialogRef = this.dialog.open(PointsLogicModalComponent, {
      panelClass: 'dialog-full-width',
      autoFocus: false,
      data: {
        benefit: { ...benefit },
        program: this.program
      }
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data && data.benefit) {
        this.cd.detectChanges()
        benefit.maxSelection = data.benefit.maxSelection;
        benefit.tierConfigs = data.benefit.tierConfigs;
        benefit.tierValues = data.benefit.tierValues;
        this.tiersValues = data.benefit.tierValues;
        this.displayNames= data.benefit.displayName
        benefit.pointsPerSelection? benefit.pointsPerSelection = data.benefit.pointsPerSelection: null;
        if (this.program.draft) {
          this.addBenefitToUpdatedList(benefit);
        }
        this.updateProductDetails(benefit, 'maxSelection');
        this.configureProgramBenefits(this.originalBenefitsList, this.program);
      }
      if(this.isMMUser) {
        this.publishDisable = true;
      }
    });
  }

  deleteProgram(): void {
    this.deactivateModalRef = this.dialog.open(DeactivateProgramModalComponent, {
      data: {
        programList: this.program ? [this.program.programName] : [],
        programIdList: [this.program.id],
        label: 'Delete'
      },
      panelClass: 'DataviewSelectionModal',
      autoFocus: false,
      restoreFocus: false
    });
    this.deactivateModalRef.afterClosed().subscribe((data: any) => {
      if (data.isSuccessful) {
        this.displayToastr("Deleted successfully");
        this.close.emit(true);
      }
    });
  }

  public enabledChipToEdit(i,j?:any) {
    if (this.program && this.program.draft || this.program && this.program.isNew) {
      this.publishDisable = true;
      this.draftDisable = true;
    }
    //this.maxSelection = this.benefit.rangeMax / this.rangeIncrementValue;
    this.isChipEditable[i] = true;
    if(j && this.benefitsList[j].items[i].hybridTempLiving ){
      this.benefitsList[j].items[i]['preRangeIncrementValue'] =this.benefitsList[j].items[i]['rangeIncrementValue'];
      this.benefitsList[j].items[i]['preRangeIncrementUnit'] =this.benefitsList[j].items[i]['rangeIncrementUnit'];
      this.benefitsList[j].items[i]['preCoreUnitsPerSelection'] =this.benefitsList[j].items[i]['coreUnitsPerSelection'];
    }
    this.benefitsList[j].items[i]['incremental'] ? this.benefitsList[j].items[i]['preMaxSelection'] = this.benefitsList[j].items[i].rangeMax/this.benefitsList[j].items[i]['rangeIncrementValue'] : null;


  }

  closeHybrid(i, j) {
    this.isChipEditable[i] = !this.isChipEditable[i];
    this.benefitsList[j].items[i].rangeIncrementValue = this.benefitsList[j].items[i].preRangeIncrementValue;
    this.benefitsList[j].items[i].rangeIncrementUnit = this.benefitsList[j].items[i].preRangeIncrementUnit;
    this.benefitsList[j].items[i].coreUnitsPerSelection = this.benefitsList[j].items[i].preCoreUnitsPerSelection;
    delete this.benefitsList[j].items[i].preRangeIncrementValue;
    delete this.benefitsList[j].items[i].preRangeIncrementUnit;
    delete this.benefitsList[j].items[i].preCoreUnitsPerSelection;
    if ((this.program.draft || this.program.isNew) && !this.isChipEditable[i]) {
      this.publishDisable = false;
      this.draftDisable = false;
    }

  }

  /** to enable backspace for input in mat-chip */
  onMatChipKeyPress(event, value, j, i) {
    // if(Math.floor(this.benefitsList[j].items[i].rangeIncrementValue  / 10) > 0) {
    //   this.benefitsList[j].items[i].rangeIncrementValue = Math.floor(this.benefitsList[j].items[i].rangeIncrementValue  / 10);
    // }
    if (value) {
      value = value.toString();
      value = value.slice(0, -1);
      this.rangeIncrementValue = value;
      //this.benefitsList[j].items[i].rangeIncrementValue = value;
    }
 }
 onMatChipKeyPressCore(event, value, j, i) {
  if (value) {
    value = value.toString();
    value = value.slice(0, -1);
    this.benefitsList[j].items[i].coreUnitsPerSelection = value;
  }
}

 //"onMatChipKeyPressCore($event,benefit.coreUnitsPerSelection,j,i)"

 benefitSetting(benefit: Benefit) {
  const dialogRef = this.dialog.open(BenefitSettingDialogComponent, {
    data: {
      program: this.program,
      validForDeactivation: this.validForDeactivation,
      clientId: this.program && this.program.selectedClientId,
      description : benefit.description ,
      displayName : benefit.displayName,
      internationalProductName :benefit.productSubDetails && benefit.productSubDetails.internationalProduct.productName,
      usDomesticProductName :benefit.productSubDetails && benefit.productSubDetails.usDomesticProduct.productName,
      internationalSubProductName :benefit.productSubDetails && benefit.productSubDetails.internationalProduct.subProductName,
      usDomesticSubProductName  :benefit.productSubDetails && benefit.productSubDetails.usDomesticProduct.subProductName,
      internationlRevision :benefit.productSubDetails && benefit.productSubDetails.internationalProduct.requiresRevision,
      usDomesticRevision :benefit.productSubDetails && benefit.productSubDetails.usDomesticProduct.requiresRevision,
    },
    width: '35vw',
    height:'44.5vw',
    autoFocus: false,
    restoreFocus: false
  });

  dialogRef.afterClosed().subscribe(data =>{
    if (data && data.data && data.data.productSubDetails) {
      benefit.productSubDetails.internationalProduct.productName = data.data.productSubDetails.internationalProduct.productName;
      benefit.productSubDetails.internationalProduct.subProductName = data.data.productSubDetails.internationalProduct.subProductName;
      benefit.productSubDetails.internationalProduct.requiresRevision = data.data.productSubDetails.internationalProduct.requiresRevision;
      benefit.productSubDetails.usDomesticProduct.productName = data.data.productSubDetails.usDomesticProduct.productName
      benefit.productSubDetails.usDomesticProduct.subProductName = data.data.productSubDetails.usDomesticProduct.subProductName
      benefit.productSubDetails.usDomesticProduct.requiresRevision = data.data.productSubDetails.usDomesticProduct.requiresRevision;
      if (this.program.draft || this.program.active) {
        this.addBenefitToUpdatedList(benefit);
      }
      this.updateProductDetails(benefit, 'productSubDetails');
      this.configureProgramBenefits(this.originalBenefitsList, this.program);
    }
  })
 }

 /**
 * This function is used to open list of tiervalue as per index selection
 * @param index
 */
public onChoiceIconClick(index){
  if (this.visibleIndex === index) {
    this.visibleIndex = -1;
  } else {
    this.visibleIndex = index;
  }
 }
/**
 * This function is used to initialize the initial contact form
 */
//  public initInitialContactForm(){
//    this.initialContactForm = new FormGroup({
//     initialContactRequired: new FormControl(''),
//     initialContactBy: new FormControl('')
//    });
//  }
 /**
  * This function is used to get value from mat checkbox
  * @param event  value true
  */
//  public initialContact(event){
//   if(event){
//     this.isShowInitialContact = true;
//     this.isInitialcontactCheck = true;
//   } else {
//     this.isShowInitialContact = false;
//     this.isInitialcontactCheck = false;
//     this.initialContactForm.value.initialContactRequired = false;
//     this.initialContactForm.value.initialContactBy = "";
//   }
//  }
/**
 * This function is used to get value from dropdown
 * @param event value
 */
 public initialContactRequiredByChange(event){
 this.initialRequiredByValue = event;
 this.isInitialcontactCheck = false;
 }
/**
 * This function is used to patch value to initial contact form
 * @param program program value
 */
//  public initialContactFormPatchValue(program){
//   if(program && program.initialContactRequired){
//     this.isShowInitialContact = true;
//   } else{
//     this.isShowInitialContact = false;
//   }
//    this.initialContactForm.patchValue({
//     initialContactRequired: program && program.initialContactRequired ?  program && program.initialContactRequired : false,
//     initialContactBy: program &&  program.initialContactBy ?  program && program.initialContactBy : ''
//    });
//  }

 sortCategories() {
  this.categoriesList.sort();
 }
 deleteFromUnusedBenefit(benefit: Benefit) {
    this.deleteBenefit(benefit, false);
    this.unusedBenefitsList.forEach((unusedBenefit, index) => {
      unusedBenefit.reference == benefit.reference ? this.unusedBenefitsList.splice(index, 1) : '';
    });
    this.updatedBenefits = this.originalBenefitsList;
    this.benefitsList = this.groupBenefits(this.originalBenefitsList);
  }

  addToUnusedBenefit(benefit: Benefit) {
    const unusedBenefit = this.unusedBenefitsList.find(item => {if(item.reference === benefit.reference) return item;});
    !unusedBenefit ? this.unusedBenefitsList.push(benefit) : '';
  }

  checkDynamicFormIsValid(event: boolean) {
    this.dynamicFormValid = event;
  }

}




