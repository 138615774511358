import { Component, OnInit, Input, Inject, Injector, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ProgramManagementService, AtlasPolicyCkeckReq } from '../../services/program-management.service';
import { Observable, Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { RemoteLoggingService } from './../../../../core/services/remote-logging.service';
import { UserContextService } from './../../../../core/services/user-context.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UserType, UserTypeService } from '../../services/user-type.service';
import { DatePipe } from '@angular/common';
import {map, startWith} from 'rxjs/operators';
import moment from 'moment';
import { LocationsService } from '../../services/locations.service';
import { Country } from '../../../../core/models/country.model';
import { ClientContactsService } from '../../services/client-contacts.service';
import { AtlasPolicyWarningComponent } from '../atlas-policy-warning/atlas-policy-warning.component';
import { ClientService } from '../../services/client.service';
import { FeatureFlag, FeatureFlagService } from '../../../../core/services/feature-flag.service';
import { FormControl } from '@angular/forms';

export interface Currency {
  value: string;
}

@Component({
  selector: 'app-program-modal',
  templateUrl: './program-modal.component.html',
  styleUrls: ['./program-modal.component.scss']
})

export class ProgramModalComponent implements OnInit, OnDestroy {

  @ViewChild('contractSelect') contractSelect: ElementRef;
  isIncludeInActiveContract: boolean = false;
  programForm: UntypedFormGroup;

  selectedClientId: string;

  selectedValueCash: boolean= false;

  selectedValuePoints: boolean= false;
  
  moveType: string = 'Core/Flex';

  valueSystem: string;

  cartusClientId: string;

  _subsList: Subscription;

  focusCurrency: string;


  onMoveTypeChange(event: any): void {
    this.moveType = event.value;
  }

  onValueSystemChange(event: any): void {
    const selectedValue = event.value;
    this.selectedValueCash = ['Cash', 'Both'].includes(selectedValue);
    this.selectedValuePoints = ['Points', 'Both'].includes(selectedValue);
  }

  showCurrencyClass: boolean = false;

  programList: Array<string> = [];

  clientPolicies: Array<Array<any>> = [];

  originalClientPolicies: Array<any> = [];
  /** For program Expiration Date manipulation */
  programExpirationDate: any;
  userRole: UserType;

  isMMUser: boolean = false;

  isGOUser: boolean = false;

  countryList: Array<Country>;

  // currencyList: Array<Country> = [];

  filteredSingleSelect: any [] = [];

  minDate: Date = new Date();
  atlasPolicyFilterData: any;
  contractSeries = [];
  contractSeriesList: Array<any> = [];
  isDrafted: boolean = false;
  isActive: boolean = false;

  currencyValue: string;

  currencyValueEdit: string;

  currency = new UntypedFormControl();
//  For Currency field
  myControl = new FormControl<string | Currency>('');
  currencyList = [];
  options: Currency[] = this.currencyList
  filteredOptionss: Observable<string[]>;
  
  currencyChanged: boolean = false;

  filteredOptions: Observable<string[]>;
  copyclientPolicies: any[];
  unique: Array<any> = [];
  pointsValueAllocationType: Array<any> = [];
  programFormDetails: any;
  policyNameSubscription: Subscription;
  policyCallDurationList = [30, 60, 90];
  initialContactRequired = ['Consultant','Welcome Center'];
  // Lump sum variables
  isLumpSumEnabled: boolean = false;
  isLumpSum: boolean = false;
  enableLumpSumOption: boolean = false;
  enableBBOption: boolean = false;
  selectedProgramType: string = 'benefitsBuilder';
  
  constructor(
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private programService: ProgramManagementService,
    private spinner: NgxSpinnerService,
    private locationService: LocationsService,
    private logSrv: RemoteLoggingService,
    private toastrService: ToastrService,
    public dialogRef: MatDialogRef<ProgramModalComponent>,
    private userTypeService: UserTypeService,
    public datepipe: DatePipe,
    private clientContactsService: ClientContactsService,
    private clientService: ClientService,
    private injector: Injector,
    @Inject(MAT_DIALOG_DATA) public data

  ) {
    this.isMMUser = this.clientContactsService.isMMUser;
    this.isGOUser = this.clientContactsService.isGOUser;
    this.selectedClientId = data.clientId;
  }

  ngOnInit() {
    if(this.isMMUser && this.data && this.data.program && this.data.program.currency) {
      this.currencyValueEdit = this.data.program.currency;
    }
    this.userRole = this.userTypeService.userType;
    if(this.data && this.data.isLumpSumEnabled) 
      this.isLumpSumEnabled =  this.data.isLumpSumEnabled 
    this.getProgramTypeInfo();
    if (this.enableLumpSumOption && !this.enableBBOption) {
      this.selectedProgramType = 'traditional';
    }
    this.getClientPolicies();
    this.getCurrencies();
    this.getCurrency();

    this.filteredOptionss = this.myControl.valueChanges.pipe(
      startWith(''),
      map(values => {
        const value = typeof values === 'string' ? values : values.value;
        return value ? this._filterCurrencyList(value as string) : this.currencyList.slice();
      })
    );

    this.generateProgramForm();
    if (this.data.program) {
      // Grab the saved valueSystem (fallback to 'Cash' if not set)
      const savedValue: string = this.data.program.valueSystem || 'Cash';
 
      // Update the form’s valueSystem
      this.programForm.patchValue({ valueSystem: savedValue });
 
      // Update booleans for *ngIf
      this.selectedValueCash = ['Cash', 'Both'].includes(savedValue);
      this.selectedValuePoints = ['Points', 'Both'].includes(savedValue);
    }
 
if (this.data.program && this.data.program.valueSystem) {
  this.valueSystem = this.data.program.valueSystem;
}
    this.getBaseContract();
    if(this.isGOUser) {
      this.getApplicationType();
    }
    if(this.isLumpSumEnabled) {
      if(this.data.program && this.data.program.programTypeInfo) {
        if(this.data.program.programTypeInfo.type == 'Traditional')
          this.selectedProgramType = 'traditional';
      }
      this.generateProgramTemplates();
    }
    else
      this.getStandardPrograms();
    this.minDate = new Date(this.minDate.setDate(this.minDate.getDate() + 1));
    if (this.data.program) {
      this.data.program.draft ? this.isDrafted = this.data.program.draft : null;
      this.data.program.active ? this.isActive = this.data.program.active : null;
      if(this.isLumpSumEnabled && this.data.program.programTypeInfo) {
        if(this.data.program.programTypeInfo.type == 'Traditional')
        this.selectedProgramType = 'traditional';
        this.getProgramTypeInfo();
        this.generateProgramForm();
      }
    }
    if(this.isLumpSumEnabled)
      this.getProgramTypeInfo();
    this.generateProgramForm();
    this.isMMUser ? this.getFilteredOptions() : null;
  }

  generateProgramTemplates() {
    const programType = this.generateProgramTypeInfo();
    if (programType && programType.hasOwnProperty('type')) {
      this.spinner.show();
      this.programService.getProgramTemplates(programType['type']).subscribe(data => {
        this.spinner.hide();
        if (data) {
          if (data && data.clientId && data.programs) {
            this.cartusClientId = data.clientId;
            this.programList = data.programs;
          }
        } else {
          this.programList = [];
          this.logSrv.logError('Failed to load Standard Programs');
        }
      }, (error: any)  => {
        if(error && error.statusCode === '204'){
          console.error("204 error message", error);
          const errorMessage = 'No Traditional Templates currently exist for this client, new programs cannot be created at this time';
          this.logSrv.logError(errorMessage);
          this.toastrService.error(errorMessage,null,{
            closeButton: true,
            timeOut: 5000,
            enableHtml: true,
            disableTimeOut: false
          });
        } else {
          this.logSrv.logError('Failed to load Standard Programs');
        }
      })
    }
  }

  getFilteredOptions() {
    this.filteredOptions = this.programForm.get('currency').valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

   _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.filteredSingleSelect.filter(option => option.toLowerCase().includes(filterValue));

  }

  initPolicy(policyData, policyTypesList: Array<Array<string>>): { policyTypes: Array<Array<string>> } {
    const usedPolicyTypes = [];
    if (!this.programForm.get("atlasPolicy").value.length) {
      policyTypesList = [];
      policyTypesList[0] = [...this.originalClientPolicies];
      return { policyTypes: policyTypesList };
    }
    policyData.length> 0? policyData.forEach((element, index) => {
      usedPolicyTypes.push(element.atlasPolicyName);
    }):null;
    policyTypesList = [...this.originalClientPolicies];
    policyTypesList.forEach((value, index) => {
      policyTypesList[index] = this.originalClientPolicies;
      policyTypesList[index] = [...policyTypesList[index].filter(policy => !usedPolicyTypes.includes(policy))];
    });
    policyData.length> 0? policyData.forEach((element, index) => {
      policyTypesList[index].push(element.atlasPolicyName);
    }) : null;
    return { policyTypes: policyTypesList };
  }

  /**
   * Checks for Value or will return 'empty'
   * @param value any
   */
  isNullCheck(obj: Object, key: string) {
    try {
      // tslint:disable-next-line: triple-equals
      if(key !== 'policyCallDuration') {
        return ((obj[key] || obj[key] == false) && obj[key] !== null) ? obj[key] : '';
      } else {
        /**
         * Setting the default of policyCallDuration to be 60. Already active / draft before program will be displayed as 60 be default
         *  */
        return ((obj[key] || obj[key] == false) && obj[key] !== null) ? obj[key] : 60;
      }
    } catch (error) {
      return '';
    }
  }

  generateProgramForm() {
    const check = this.isNullCheck;
    const numberPattern = "^[0-9]*$";
    const stringPattern = "^[A-Za-z]+$";
    if(this.isMMUser) {
      if(this.data && this.data.program && this.data.program.currency) {
        this.currencyValue = this.data.program.currency;
      }
      this.programForm = this.fb.nonNullable.group({
        programName: [this.data ? check(this.data.program, 'programName') : '', [Validators.required, Validators.maxLength(49)]],
        templateName: [this.data ? check(this.data.program, 'templateName') : '', Validators.required],
        policyCallDuration: [this.data ? check(this.data.program, 'policyCallDuration') : null, !(this.data.program && this.data.program.policyCallRequired) ? [Validators.required] : null],
        totalAmount: [this.data ? check(this.data.program, 'totalAmount') : '', [Validators.required, Validators.minLength(1), Validators.pattern(numberPattern), Validators.min(1)]],
        currency: [this.data ? check(this.data.program, 'currency') : '', [Validators.required, Validators.maxLength(3),Validators.minLength(3)]],
        contractSeries: [this.data ? check(this.data.program, 'contractSeries') : '', [Validators.required, Validators.max(999)]],
        programExpirationDate: [this.data ? check(this.data.program, 'programExpirationDate') : null],
        atlasPolicy: new UntypedFormArray( this.generateAtlasPoliciesGroup()),
        policyCallRequired: [this.data && check(this.data.program, 'policyCallRequired') !== '' ?
        this.data.program.policyCallRequired : true],
        isCartusInsured: [this.data && check(this.data.program, 'isCartusInsured') !== '' ?
        this.data.program.isCartusInsured : false],
        initialContactRequired: [this.data && check(this.data.program, 'initialContactRequired') !== '' ?
        this.data.program.initialContactRequired : false],
        initialContactBy: [this.data ? check(this.data.program, 'initialContactBy') : ''],
        isSustainabilityEnabled: [this.data && check(this.data.program, 'isSustainabilityEnabled') !== '' ?
          this.data.program.isSustainabilityEnabled : false],
        isDEIEnabled: [this.data && check(this.data.program, 'isDEIEnabled') !== '' ?
          this.data.program.isDEIEnabled : false]
      });
    } else if (this.isGOUser) {
      this.programForm = this.fb.nonNullable.group({
        atlasPolicy: new UntypedFormArray( this.generateAtlasPoliciesGroup()),
        programName: [this.data ? check(this.data.program, 'programName') : '', [Validators.required, Validators.maxLength(49)]],
        templateName: [this.data ? check(this.data.program, 'templateName') : '', Validators.required],
        policyCallDuration: [this.data ? check(this.data.program, 'policyCallDuration') : null, !(this.data.program && this.data.program.policyCallRequired) ? [Validators.required] : null],
        // totalPoints: [this.data ? check(this.data.program, 'totalPoints') : '', [Validators.required, Validators.max(99999)]],
        pointsValueAllocationType: [ this.data ? check(this.data.program, 'pointsValueAllocationType') :'', [Validators.required, Validators.max(999)]],
        contractSeries: [ this.data ? check(this.data.program, 'contractSeries') :'', [Validators.required, Validators.max(999)]],
        programExpirationDate: [this.data ? check(this.data.program, 'programExpirationDate') : null],
        policyCallRequired: [this.data && check(this.data.program, 'policyCallRequired') !== '' ?
        this.data.program.policyCallRequired : true],
        isCartusInsured: [this.data && check(this.data.program, 'isCartusInsured') !== '' ?
        this.data.program.isCartusInsured : false],
        initialContactRequired: [this.data && check(this.data.program, 'initialContactRequired') !== '' ?
        this.data.program.initialContactRequired : false],
        initialContactBy: [this.data ? check(this.data.program, 'initialContactBy') : ''],
        isSustainabilityEnabled: [this.data && check(this.data.program, 'isSustainabilityEnabled') !== '' ?
        this.data.program.isSustainabilityEnabled : false],
        isDEIEnabled: [this.data && check(this.data.program, 'isDEIEnabled') !== '' ?
        this.data.program.isDEIEnabled : false]

      });
    } else if (this.isLumpSumEnabled && this.isLumpSum) {
      this.programForm = this.fb.nonNullable.group({
        atlasPolicy: new UntypedFormArray( this.generateAtlasPoliciesGroup()),
        programName: [this.data ? check(this.data.program, 'programName') : '', [Validators.required, Validators.maxLength(49)]],
        templateName: [this.data ? check(this.data.program, 'templateName') : '', Validators.required],
        contractSeries: [ this.data ? check(this.data.program, 'contractSeries') :'', [Validators.required, Validators.max(999)]],
        programExpirationDate: [this.data ? check(this.data.program, 'programExpirationDate') : null],
        initialContactRequired: [this.data && check(this.data.program, 'initialContactRequired') !== '' ?
        this.data.program.initialContactRequired : false],
        initialContactBy: [this.data ? check(this.data.program, 'initialContactBy') : ''],
        initialAuditRequired: [this.data && check(this.data.program, 'initialAuditRequired') !== '' ?
        this.data.program.initialAuditRequired : true]
      });
    } else {
        let moveType = '';
        if(this.data?.program?.programTypeInfo && !this.data?.program?.isNew){
          moveType = (this.data?.program?.programTypeInfo.subType[0] == 'Standard' || this.data?.program?.programTypeInfo.subType[0] == 'Points Matrix') ? 'Core/Flex' : this.data?.program?.programTypeInfo.subType[0];
        } else {
          moveType = this.data?.program?.moveType
        } 
        this.programForm = this.fb.nonNullable.group({
        atlasPolicy: new UntypedFormArray( this.generateAtlasPoliciesGroup()),
        programName: [this.data ? check(this.data.program, 'programName') : '', [Validators.required, Validators.maxLength(49)]],
        templateName: [this.data ? check(this.data.program, 'templateName') : '', Validators.required],
        policyCallDuration: [this.data ? check(this.data.program, 'policyCallDuration') : null],
        valueSystem: [this.data ? check(this.data.program, 'valueSystem') : '', [Validators.required]],
        totalPoints: [this.data ? check(this.data.program, 'totalPoints') : '', [Validators.max(99999)]],
        moveType: [this.data?.program ? moveType : 'Core/Flex'],
        contractSeries: [ this.data ? check(this.data.program, 'contractSeries') :'', [Validators.required, Validators.max(999)]],
        programExpirationDate: [this.data ? check(this.data.program, 'programExpirationDate') : null],
        policyCallRequired: [this.data && check(this.data.program, 'policyCallRequired') !== '' ?
        this.data.program.policyCallRequired : false],
        isCartusInsured: [this.data && check(this.data.program, 'isCartusInsured') !== '' ?
        this.data.program.isCartusInsured : false],
        initialContactRequired: [this.data && check(this.data.program, 'initialContactRequired') !== '' ?
        this.data.program.initialContactRequired : false],
        initialContactBy: [this.data ? check(this.data.program, 'initialContactBy') : ''],
        isSustainabilityEnabled: [this.data && check(this.data.program, 'isSustainabilityEnabled') !== '' ?
        this.data.program.isSustainabilityEnabled : false],
        isDEIEnabled: [this.data && check(this.data.program, 'isDEIEnabled') !== '' ?
        this.data.program.isDEIEnabled : false],
        totalAmount: [this.data ? check(this.data.program, 'totalAmount') : '', [ Validators.minLength(1), Validators.pattern(numberPattern), Validators.min(1)]],
        currency: [this.data ? check(this.data.program, 'currency') : '', [Validators.maxLength(3),Validators.minLength(3)]],
      });
      if(this.data?.program?.currency) {
        this.currencyValue = this.data.program.currency;
      }

      // Dynamically apply required validator for policyCallDuration if policyCallRequired toggle is on
      this.programForm.get('policyCallRequired')?.valueChanges.subscribe(required => {
        const durationControl = this.programForm.get('policyCallDuration');
        if (required) {
          durationControl?.setValidators([Validators.required]);
        } else {
          durationControl?.clearValidators();
        }
        durationControl?.updateValueAndValidity();
      });
    }
  }

  lettersOnly(event: KeyboardEvent): void {
    if (!/[a-zA-Z]/.test(event.key)) {
      event.preventDefault();
    }
  }

  currencyChange(): void {
    this.currencyValue = this.currencyValue.toUpperCase();
  }

  currencyOption(data: string): void {
    if(data.length > 0 ) {
      this.currencyChanged = true;
    }
  }

  displayFn(currency: string | Currency): string {
    if (!currency) return '';
    return typeof currency === 'string' ? currency : currency.value;
  }

  private _filterCurrencyList(value: string): string[] {

    const filterValue = value.toLowerCase();
    return this.currencyList.filter(option => option.toLowerCase().includes(filterValue));
  }

  getCurrencies() : void {
    let group = {};
    this.locationService.countryList.subscribe(countryList => {
      if(countryList.length > 0) {
        const currList = [];
        countryList.forEach((curr) => {
          curr.currency? currList.push(curr): null;
        });
        currList.sort((a, b) =>  a.currency &&  b.currency ? a.currency.code.localeCompare(b.currency.code) : null);
        for(const item of currList) {
          this.filteredSingleSelect.push(item.currency.code);
        }
        this.filteredSingleSelect = this.filteredSingleSelect.filter( function( item, index, inputArray ) {
          return inputArray.indexOf(item) == index;
        });
      }
    });
  }

  getCurrency() {
    if(!this.currencyList.length){
      this.spinner.show();
      this.programService.getvalueLists('billingCurrencies').subscribe((response)=>{
        this.spinner.hide();
        if(response){
          this.currencyList = response['values'].map(item => item.value);
        }
      },(error)=>{
        this.spinner.hide();
      })
    }
  }

  generateAtlasPoliciesGroup(): Array<UntypedFormGroup> {
    const check = this.isNullCheck;
    const fb = this.injector.get(UntypedFormBuilder);
    let policyData;
    if (this.data.program && this.data.program.atlasPolicy != undefined && this.data.program.atlasPolicy.length) {
      policyData = this.data.program.atlasPolicy.map(element => {
          return fb.group({
            atlasPolicyName: [element]
          })
      })
      return policyData
    }
    else {
        return [fb.group({
          atlasPolicyName: ['']
        })];
    }
  }

  getStandardPrograms() {
    this.spinner.show();
    this.programService.getStandardPrograms().subscribe(data => {
      this.spinner.hide();
      if (data) {
        this.cartusClientId = data.clientId;
        this.programList = data.programs;
      } else {
        this.programList = [];
        this.logSrv.logError('Failed to load Standard Programs');
      }
    },(error: any)  => {
      if(error && error.statusCode === '204'){
        console.error("204 error message",error);
        const errorMessage = 'No Traditional Templates currently exist for this client, new programs cannot be created at this time';
        this.logSrv.logError(errorMessage);
        this.toastrService.error(errorMessage,null,{
          closeButton: true,
          timeOut: 5000,
          enableHtml: true,
          disableTimeOut: false
        });      
      } else {
        this.logSrv.logError('Failed to load Standard Programs');
      }
    })
  }

  getClientPolicies() {
    this.programService.getClientPolicies(this.selectedClientId).subscribe(data => {
      if (data) {
        this.originalClientPolicies = data.policies;
        if (this.programForm.get('atlasPolicy')) {
          const {
            policyTypes
          } = this.
            initPolicy(
              (this.programForm.controls.atlasPolicy as UntypedFormGroup).value
              , this.originalClientPolicies)
          this.clientPolicies = policyTypes;
        }
      } else {
        this.logSrv.logError('Failed to load Client Policies');
      }
    }, () => {
      this.logSrv.logError('Failed to load Client Policies');
    });
  }

  getFilteredPolicies(curIndex) {
    let policyTypesList = [];
    let existingPolicies: Array<string> = ((this.programForm.controls.atlasPolicy as UntypedFormArray).
      getRawValue() as any).map((policy, index) => policy.atlasPolicyName.trim());
    existingPolicies = existingPolicies.filter((policy, index) => index !== curIndex);
    policyTypesList[curIndex] = [...this.originalClientPolicies];
    policyTypesList[curIndex] = policyTypesList[curIndex].filter((policy, index) => !existingPolicies.includes(policy));
    this.clientPolicies[curIndex] = policyTypesList[curIndex];
    this.atlasPolicyFilterData = this.clientPolicies[curIndex];
  }

  getBaseContract() {
    this.programService.getBaseContract(this.selectedClientId).subscribe(data => {
      if (data) {
        data.forEach(element => {
          this.unique.push({baseContract: Number(element.baseContract), status: element.status});
          this.contractSeriesList = this.unique.filter((item, i, ar) => ar.indexOf(item) === i);
        });
        this.contractSeriesList = this.contractSeriesList.map(ele => ele.baseContract)
      } else {
        this.logSrv.logError('Failed to load Client Policies');
      }
    }, () => {
      this.logSrv.logError('Failed to load Client Policies');
    });
  }

  getApplicationType() {
    this.programService.getApplicationType().subscribe(data => {
      if (data) {
        this.pointsValueAllocationType = data.values;
      } else {
        this.logSrv.logError('Failed to load Application Types');
      }
    }, () => {
      this.logSrv.logError('Failed to load Application Types');
    });
  }

  closeDialog(programFormValue: any) : void {
    const data = {
      selectedClientId: this.selectedClientId,
      cartusClientId: this.cartusClientId,
      isNew: true,
      divisions: [],
      programExpirationDate: programFormValue.programExpirationDate !== '' ? `${moment( programFormValue.programExpirationDate).format().split('T')[0]}T00:00:00.000Z` : null,
      policyCallRequired: programFormValue.policyCallRequired,
      isCartusInsured: programFormValue.isCartusInsured,
      currency: programFormValue.currency,
      pointsValueAllocationType: programFormValue.pointsValueAllocationType,
      initialContactRequired: programFormValue.initialContactRequired,
      initialContactBy: programFormValue.initialContactBy,
      isSustainabilityEnabled: programFormValue.isSustainabilityEnabled,
      isDEIEnabled: programFormValue.isDEIEnabled
    };
    data['programTypeInfo'] = this.generateProgramTypeInfo();
    if(this.isLumpSumEnabled) {
      // If not isMMuser and isGoUser
      if(!this.isMMUser && !this.isGOUser && this.isLumpSum) {
        data['initialAuditRequired'] = programFormValue.initialAuditRequired
      }
    }

    this.dialogRef.close(Object.assign(programFormValue, data, ));
  }

  submitProgramForm() {
    const programFormValue = JSON.parse(JSON.stringify(this.programForm.value));
    
    let atlasPoliciesData = [];
    this.programForm.value.atlasPolicy.forEach(element => {
      if(element && element.atlasPolicyName){
        atlasPoliciesData.push(element.atlasPolicyName);
      }
    });
    programFormValue.atlasPolicy = atlasPoliciesData;
    this.programFormDetails = JSON.parse(JSON.stringify(programFormValue));
    this.programService.checkDuplicatePrograms(this.selectedClientId, programFormValue.programName).subscribe(data => {
      
      if (data && !this.data.program) {
        if (data.programs && Array.isArray(data.programs) && data.programs.length === 0) {
          if(this.isMMUser && this.currencyChanged) {
            this.closeDialog(programFormValue);
          } else if(!this.isMMUser) {
            this.checkDuplicateAtlasPolicyName(this.selectedClientId, atlasPoliciesData);
          } else {
            this.toastrService.error('Please select currency from dropdown', 'Error');
          }
        } else {
          this.toastrService.error('Program name already exists', 'Error');
        }
      } else if (this.data.program && this.data.program.isNew) {
          data['programTypeInfo'] = this.generateProgramTypeInfo();
        if (this.isMMUser && this.currencyChanged) {
          this.dialogRef.close(Object.assign(programFormValue, {
            programExpirationDate: programFormValue.programExpirationDate !== '' ? `${moment( programFormValue.programExpirationDate).format().split('T')[0]}T00:00:00.000Z` : "",
            programName: programFormValue.programName,
            policyCallRequired: programFormValue.policyCallRequired,
            policyCallDuration: programFormValue.policyCallDuration,
            isCartusInsured: programFormValue.isCartusInsured,
            currency: programFormValue.currency,
            totalAmount:programFormValue.totalAmount,
            initialContactRequired: programFormValue.initialContactRequired,
            initialContactBy: programFormValue.initialContactBy,
            isSustainabilityEnabled: programFormValue.isSustainabilityEnabled,
            isDEIEnabled: programFormValue.isDEIEnabled
          }));
        } else if(this.isMMUser && !this.data.program.isNew && !this.currencyChanged){
          this.toastrService.error('Please select currency from dropdown', 'Error');
        } else if(!this.isMMUser && !this.isGOUser && this.isLumpSum) {
          this.checkDuplicateAtlasPolicyName(this.selectedClientId, atlasPoliciesData);
          const data = {
            programExpirationDate: programFormValue.programExpirationDate !== ''? `${moment( programFormValue.programExpirationDate).format().split('T')[0]}T00:00:00.000Z` : "",
            programName: programFormValue.programName,
            initialContactRequired: programFormValue.initialContactRequired,
            initialContactBy: programFormValue.initialContactBy,
            initialAuditRequired: programFormValue.initialAuditRequired
          };
          let programValue = Object.assign(programFormValue, data)

          this.programFormDetails = JSON.parse(JSON.stringify(programValue));
        } else {
          this.checkDuplicateAtlasPolicyName(this.selectedClientId, atlasPoliciesData);
          const data = {
            programExpirationDate: programFormValue.programExpirationDate !== ''? `${moment( programFormValue.programExpirationDate).format().split('T')[0]}T00:00:00.000Z` : "",
            programName: programFormValue.programName,
            policyCallRequired: programFormValue.policyCallRequired,
            isCartusInsured: programFormValue.isCartusInsured,
            initialContactRequired: programFormValue.initialContactRequired,
            initialContactBy: programFormValue.initialContactBy,
            isSustainabilityEnabled: programFormValue.isSustainabilityEnabled,
            isDEIEnabled: programFormValue.isDEIEnabled
          };
          let programValue = Object.assign(programFormValue, data)

          this.programFormDetails = JSON.parse(JSON.stringify(programValue));
        }
      } else if (this.data.program && !this.data.program.isNew) {
        if (data.programs.length > 0 && (this.data.program.programName !== programFormValue.programName)) {
          this.toastrService.error('Program name already exists', 'Error');
        } else if (this.currencyValueEdit != programFormValue.currency && this.isMMUser && !this.currencyChanged) {
          this.toastrService.error('Please select currency from dropdown', 'Error');
        } else {
          const isSameAtlasPolicy = this.data.program.atlasPolicy && this.data.program.atlasPolicy.length === atlasPoliciesData.length && 
            this.data.program.atlasPolicy.every(ele => atlasPoliciesData.includes(ele))

           isSameAtlasPolicy ? this.updateProgram(atlasPoliciesData) : this.checkDuplicateAtlasPolicyName(this.selectedClientId, atlasPoliciesData);
        }
      } else {
        this.logSrv.logError('Error checking duplicate program');
      }
    }, () => {
      this.logSrv.logError('Error checking duplicate program');
    });
  }

  updateProgram(atlasPoliciesData) {
    const programFormValue = JSON.parse(JSON.stringify(this.programForm.value));
    programFormValue.atlasPolicy = atlasPoliciesData;
    const req = {
      'programId': this.data.program.id,
      'clientId': this.selectedClientId,
      'fieldsToUpdate': {
        'programName': programFormValue.programName
      }
    };
    !programFormValue.programExpirationDate ? req.fieldsToUpdate['programExpirationDate']='' : 
          req.fieldsToUpdate['programExpirationDate'] = `${moment( programFormValue.programExpirationDate).format().split('T')[0]}T00:00:00.000Z`;
    // if (this.isDrafted || this.isActive) {
      if(this.isMMUser) {
        req.fieldsToUpdate['policyCallRequired'] = programFormValue.policyCallRequired;
        req.fieldsToUpdate['clientPolicy'] = atlasPoliciesData;
        req.fieldsToUpdate['contractId'] = programFormValue['contractSeries'] ;
        req.fieldsToUpdate['totalAmount'] = parseInt(programFormValue.totalAmount);
        req.fieldsToUpdate['isCartusInsured'] = programFormValue.isCartusInsured;
        req.fieldsToUpdate['currency'] = programFormValue.currency;
        req.fieldsToUpdate['initialContactRequired'] = programFormValue.initialContactRequired;
        programFormValue.initialContactRequired ? req.fieldsToUpdate['initialContactBy'] =  programFormValue.initialContactBy : '' ;
        req.fieldsToUpdate['isSustainabilityEnabled'] = programFormValue.isSustainabilityEnabled;
        req.fieldsToUpdate['isDEIEnabled'] = programFormValue.isDEIEnabled;
      } else if (this.isGOUser) {
        req.fieldsToUpdate['policyCallRequired'] = programFormValue.policyCallRequired;
        req.fieldsToUpdate['clientPolicy'] = atlasPoliciesData;
        req.fieldsToUpdate['contractId'] = programFormValue['contractSeries'] ;
        req.fieldsToUpdate['isCartusInsured'] = programFormValue.isCartusInsured;
        req.fieldsToUpdate['pointsValueAllocationType'] = programFormValue.pointsValueAllocationType;
        req.fieldsToUpdate['initialContactRequired'] = programFormValue.initialContactRequired;
        programFormValue.initialContactRequired ? req.fieldsToUpdate['initialContactBy'] =  programFormValue.initialContactBy : '' ;
        req.fieldsToUpdate['isSustainabilityEnabled'] = programFormValue.isSustainabilityEnabled;
        req.fieldsToUpdate['isDEIEnabled'] = programFormValue.isDEIEnabled;
     } else if(!this.isMMUser && !this.isGOUser && this.isLumpSum) {
        req.fieldsToUpdate['clientPolicy'] = atlasPoliciesData;
        req.fieldsToUpdate['contractId'] = programFormValue['contractSeries'] ;
        req.fieldsToUpdate['initialContactRequired'] = programFormValue.initialContactRequired;
        programFormValue.initialContactRequired ? req.fieldsToUpdate['initialContactBy'] =  programFormValue.initialContactBy : '' ;
        req.fieldsToUpdate['initialAuditRequired'] = programFormValue.initialAuditRequired;
     } else {
        req.fieldsToUpdate['policyCallRequired'] = programFormValue.policyCallRequired;
        req.fieldsToUpdate['clientPolicy'] = atlasPoliciesData;
        req.fieldsToUpdate['contractId'] = programFormValue['contractSeries'] ;
        req.fieldsToUpdate['isCartusInsured'] = programFormValue.isCartusInsured;
        req.fieldsToUpdate['policyCallDuration'] = programFormValue.policyCallDuration;
        req.fieldsToUpdate['initialContactRequired'] = programFormValue.initialContactRequired;
        programFormValue.initialContactRequired ? req.fieldsToUpdate['initialContactBy'] =  programFormValue.initialContactBy : '' ;
        req.fieldsToUpdate['isSustainabilityEnabled'] = programFormValue.isSustainabilityEnabled;
        req.fieldsToUpdate['isDEIEnabled'] = programFormValue.isDEIEnabled;
        if(programFormValue.valueSystem === "Cash" || programFormValue.valueSystem === "Both") {
          req.fieldsToUpdate['totalAmount'] = parseInt(programFormValue.totalAmount);
        }
        if(programFormValue.valueSystem === "Points" || programFormValue.valueSystem === "Both") {
          req.fieldsToUpdate['totalPoints'] = parseInt(programFormValue.totalPoints);
        }
     }
    // }
    this.programService.updateProgram(req).subscribe(data => {
      this.dialogRef.close(Object.assign(programFormValue, {
        programExpirationDate: programFormValue.programExpirationDate,
        programName: programFormValue.programName,
        valueSystem: programFormValue.valueSystem,
        currency: programFormValue.currency,
        totalAmount: programFormValue.totalAmount,
        pointsValueAllocationType : programFormValue.pointsValueAllocationType
      }));
    });
  }

  onDismiss(evt) {
   // evt.defaultPrevented();
    if (this.programForm.dirty && !this.data.program) {
      // tslint:disable-next-line: no-use-before-declare
      const dialog = this.dialog.open(ProgramCloseWarningComponent, {
        width: '300px',
        data: { message: "Are you sure you want to cancel the Program creation?" }
      });
      dialog.afterClosed().subscribe(data => {
        if (data) {
          this.dialogRef.close(null);
        }
      });
    } else {
      this.dialogRef.close(null);
    }
  }

  onSeriesRemoved(contractSeries: number) {
    const series = this.programForm.controls.contractSeries.value as number[];
    this.removeFirst(series, contractSeries);
    this.programForm.controls.contractSeries.setValue(series);
  }

  public removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  atlasPolicyFilter(event, i) {
    const val = event.target.value.toLowerCase();
    const temp = this.atlasPolicyFilterData.filter((e) => {
      return e.toLowerCase().indexOf(val) !== -1 || !val
    })
    this.clientPolicies[i] = temp;
  }

  addAtlasPolicy() {
    const creds = this.programForm.controls.atlasPolicy as UntypedFormArray;
    creds.push(this.fb.group({
      atlasPolicyName: ['']
    }));
  }
  removePolicy(i) {
    (this.programForm.controls.atlasPolicy as UntypedFormArray).removeAt(i);
    this.programForm.controls.atlasPolicy.value.forEach( (ele, key) => {
      this.getFilteredPolicies(key);
    });
  }

  get atlasPolicy(): UntypedFormArray {
    return this.programForm.get('atlasPolicy') as UntypedFormArray;
  } 

  checkDuplicateAtlasPolicyName(selectedClientId, atlasPoliciesData) {
    let atlasPolicyCkeckReq = new AtlasPolicyCkeckReq();
    atlasPolicyCkeckReq.clientPartyId = selectedClientId;
    atlasPolicyCkeckReq.policies = atlasPoliciesData;
    
    this.policyNameSubscription = this.programService.checkDuplicateAtlasPolicyName(atlasPolicyCkeckReq).subscribe(
      (res) => {
        if(res && res.duplicatePolicies && res.duplicatePolicies.length !== 0) {
          this.openAtlasPolicyWarningDialog(res,atlasPoliciesData);
        } else {
          if(this.isDrafted) {
            this.updateProgram(atlasPoliciesData);
          } else {
            this.closeDialog(this.programFormDetails);
          } 
        }
          
      }, (err) => {
        this.logSrv.logError('Error checking duplicate atlas policy.');
      }
    )
  }

  onChange(){
    this.programForm.controls.initialContactBy.setValue('');
  }

  openAtlasPolicyWarningDialog(data, atlasPoliciesData) {
    const dialog = this.dialog.open(AtlasPolicyWarningComponent, {
      width: '500px',
      data: data
    });

    dialog.afterClosed().subscribe(result => {
      if (result.action === 'confirm') {
        if(this.isDrafted || this.isActive) {
          this.updateProgram(atlasPoliciesData);
        }else {
          this.closeDialog(this.programFormDetails);
        }
      } else if(result.action === 'dismiss') {
          while (this.atlasPolicy.length !== 0) {
            this.atlasPolicy.removeAt(0);
          }
          this.addAtlasPolicy();
      }
    });
  }

  checkForExistBaseContract(Contract) {
    if(this.data && this.data.program && this.data.program.contractSeries) {
      return this.data.program.contractSeries.findIndex(ele => ele === Contract) > -1 ? true : false
    }
    return false;
  }

  setCallDurationValidator(value) {
    if(value && !this.programForm.get('policyCallDuration').value) {
      this.programForm.get('policyCallDuration').setErrors({'incorrect': true})
    } else {
      this.programForm.get('policyCallDuration').reset();
      this.programForm.get('policyCallDuration').setErrors(null);
    }
  } 

  onDropdownClosed(isOpen: boolean) {
    if (!isOpen && this.isIncludeInActiveContract) {
      if (this.isIncludeInActiveContract) {
        const dialog = this.dialog.open(ProgramCloseWarningComponent, {
          width: '500px',
          data: { 
            message: "You have selected 1 or more inactive contracts. Would you like to continue?", 
            title : 'Inactive Contract Alert'
          }
        });
        dialog.afterClosed().subscribe(result => {
          if(!result) {
            this.programForm.controls.contractSeries.setValue([])
          }
          this.isIncludeInActiveContract = false;
        });
      }
    }
  }

  checkForInActive(event) {
    if(event.source._selected) {
      const res = this.unique.filter(ele => ele.status === 'Inactive' && ele.baseContract === event.source.value );
      res.length ? this.isIncludeInActiveContract = true : null
    }
  }

  programTypeChange(event) {
    this.selectedProgramType = event.value;
    this.generateProgramTemplates();
    if(event.value == 'traditional') {
      this.isLumpSum = true;
    } else {
      this.isLumpSum = false;
    }
    this.programForm.reset();
    this.generateProgramForm();
  }


  getProgramTypeInfo() {
    if(this.clientService.bbRole.includes('traditionalAuthorization')  && this.clientService.traditionalRole.includes('lumpSum')) {
      this.enableLumpSumOption = true;
    }
    if (this.clientService.bbRole.includes('benefitsBuilder')) {
      this.enableBBOption = true;
    }
    if(this.clientService.bbRole.includes('traditionalAuthorization') && this.clientService.traditionalRole.length && this.clientService.traditionalRole.includes('lumpSum') && this.selectedProgramType != 'benefitsBuilder') {
      this.isLumpSum = true;
    }
  }

  generateProgramTypeInfo() {
    let programTypeInfo = {};
    if(this.selectedProgramType === "benefitsBuilder") {
      programTypeInfo['type'] =  "Benefits Builder";
      if(this.clientService.bbRole.includes('managedMove')) {
        programTypeInfo['subType'] = ["Managed Move"];
      } else if (this.clientService.bbRole.includes('pointsMatrix')){
        programTypeInfo['subType'] = ["Points Matrix"];
      } else if((this.moveType === "Core/Flex" || "Capped Move")) {
        programTypeInfo['subType'] = [this.moveType];
    } 
    else {
        programTypeInfo['subType'] = ["Standard"];
    }
    } else {
      programTypeInfo['type'] =  "Traditional";
    }
    return programTypeInfo;
  }

  ngOnDestroy(): void {
    this.policyNameSubscription ? this.policyNameSubscription.unsubscribe() : null;
  }

}

@Component({
  selector: 'app-program-close-warning',
  template: `
    <h1 mat-dialog-title>{{data.title ? data.title : 'Close?'}}</h1>
    <div mat-dialog-content>{{data.message}}</div>
    <div mat-dialog-actions align="end" style="padding-bottom: 20px;">
      <button mat-button mat-dialog-close class="mat-button text-button">No</button>
      <button mat-button (click)="close()" class="mat-button contained-button" cdkFocusInitial>Yes</button>
    </div>
  `,
})
export class ProgramCloseWarningComponent {

  constructor(
    public dialogRef: MatDialogRef<ProgramCloseWarningComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  close() {
    this.dialogRef.close(true);
  }

}
