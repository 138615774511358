<div class="admin-container" role="group">
    <div class="admin-content-container" role="none">

        <mat-card class="mat-card-client-details" fxLayout fxLayoutAlign="start start" fxLayoutAlign.xs="start start" style="margin-top: 10px;">
            <div class="help-icon-wrapper">
                <span>?</span>
            </div>
            <div class="client-detail" role="table">
                <div fxLayout class="additional-detail" fxLayoutAlign="start center" role="cell">
                    <span class="detail-header" role="none">Program Name: </span>
                </div>
                <div class="client-name" role="cell" *ngIf="program?.programName" tabindex="0" (keyup.enter)="editProgramDetails()" (mouseover)="editProgramName = true"
                  (mouseout)="editProgramName = false" (focus)="editProgramName = true" (focusout)="editProgramName = false">
                  <span role="text" tabindex="0">{{ program?.programName }}</span>
                  <mat-icon tabindex="0" aria-label="Edit" class="edit-program-name" *ngIf="(userCapabilities > -1) && validForDeactivation" [ngStyle]="editProgramName? {'visibility': 'visible'} : {'visibility': 'hidden'} "
                   (keydown.enter)="editProgramDetails()"  (click)="editProgramDetails()" fxLayoutAlign="end">edit</mat-icon>
                   <ng-container *ngIf="isLumpSumEnabled && programType">
                    <span class ="program-type" *ngIf="programType === 'Traditional'"><img src="../../../../assets/images/Traditional_Group.svg"/></span>
                    <span class ="program-type" *ngIf="programType === 'Benefits Builder'"><img src="../../../../assets/images/BB_Group.svg"/></span>
                   </ng-container>
                </div>
            </div>
            <div class="download-report" tabindex="-1" role="none">
                <button mat-button matSuffix mat-icon-button tabindex="-1" aria-label="Download Program Detail" title="Download Program Detail" role="button" (keydown.enter)="openDownloadToastr()" (click)="openDownloadToastr()">
          <mat-icon aria-label="Download Program Detail" tabindex="0">system_update_alt</mat-icon>
          <span> Download Report </span>
        </button>
            </div>
            <div class="close" tabindex="-1" role="none">
                <button mat-button matSuffix mat-icon-button tabindex="-1" aria-label="Close Program Detail" title="Close Program Detail" role="button" (keydown.enter)="closeProgram()" (click)="closeProgram()">
          <mat-icon aria-label="Close Program Detail" tabindex="0">close</mat-icon>
        </button>
            </div>
        </mat-card>

         <!--Accordion for Divisions -->
        <mat-accordion class="client-details-panel">
           
            <mat-expansion-panel class="candidate-detail">
                <mat-expansion-panel-header class="division-expansion">
                    <span class="panel-heading" role="none">Divisions</span>
                    <span class="divisionFont" *ngIf="program?.divisions && program?.divisions.length > 0" role="none">Divisions Assigned: {{ program?.divisions.length }}</span>
                    <span class="divisionFont" *ngIf="program?.divisions.length === 0" role="none">Divisions Assigned: None</span>
                </mat-expansion-panel-header>
                <mat-panel-description class="division-description" fxLayout="column" [fxLayoutAlign]="program?.divisions && program?.divisions.length === 0?'center center':'unset unset'">
                    <ng-container *ngIf="program?.divisions.length === 0 && (userCapabilities > -1)">
                        <div><span tabindex="0">You must assign Divisions to publish this program for use</span></div>
                        <div><a  tabindex="0" aria-label="Assign Divisions" [ngClass]="!validForDeactivation ? 'division-disabled' : '' " (keydown.enter)="validForDeactivation ? assignDivision():''" (click)="validForDeactivation ? assignDivision():''">Assign Divisions</a></div>
                    </ng-container>
                    <!-- If divisions are assigned-->
                    <ng-container *ngIf="program?.divisions && program?.divisions.length > 0">
                        <div fxLayout="row" fxLayoutAlign="space-between baseline" fxFlex="100%">
                            <div fxFlex="20%" class="left-content" fxLayout="column" fxLayoutGap="20px">
                                <!-- <mat-icon class="search-icon" aria-label="search for assigned division by name">search</mat-icon> -->
                                <!-- <input placeholder="Search for assigned divisions by name" class="search-division" /> -->
                                <button *ngIf="(userCapabilities > -1)" [disabled]="!validForDeactivation" mat-button class="edit-division" (click)="assignDivision(program)">Edit Divisions</button>
                            </div>
                            <div class="right-content" fxFlex="75%" fxLayout="column">
                                <p class="assigned-div-title">Assigned divisions: ({{program?.divisions.length || 0}})</p>
                                <div fxLayout="row">
                                    <div class="division-panel-body">
                                        <ng-container *ngFor="let divisionData of program?.divisions;let i=index">
                                            <div>{{divisionData.division_name}} ({{ divisionData.division_number }})</div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    
                </mat-panel-description>
            </mat-expansion-panel>
        </mat-accordion>

        <!--Accordion for Program Details -->
        <mat-accordion class="client-details-panel" >
            <mat-expansion-panel class="candidate-detail">
                <mat-expansion-panel-header class="division-expansion">
                    <span class="panel-heading" role="none">Program Details</span>
                </mat-expansion-panel-header>
                <mat-panel-description>
                    <div class="program-details" role="group" *ngIf="isBBProgFields">
                        <div fxLayout="row" fxLayoutAlign="start start" role="row" fxLayoutGap="15px">
                            <div fxFlex="20" role="cell" *ngIf="isBBProgFields">
                                <div class="field"
                                    tabindex="0" (keyup.enter)="editProgramDetails()"
                                    (mouseover) = "editMoveType = true" (mouseout) = "editMoveType = false"
                                    (focus) = "editMoveType = true" (focusout) = "editMoveType = false"
                                    role="listitem" aria-label="Move Type {{ (program?.programTypeInfo && !program?.isNew) ? program?.programTypeInfo?.subType[0] : program?.moveType }}">
                                    <div class="label" fxLayout = "row" role="none">Move Type
                                        <mat-icon tabindex="0" class="edit-expiration-date" *ngIf = "!program?.programTypeInfo?.subType && (program?.draft || program?.isNew) && validForDeactivation" [ngStyle]= "editMoveType? {'visibility': 'visible'} : {'visibility': 'hidden'} " (keydown.enter)="editProgramDetails()" (click)="editProgramDetails()" fxLayoutAlign="end">edit</mat-icon>
                                    </div>
                                    <div class="value" fxLayout = "row" *ngIf="program?.programTypeInfo?.subType" role="none">{{ (program?.programTypeInfo && !program?.isNew) ? program?.programTypeInfo?.subType[0] : program?.moveType }}
                                        <mat-icon tabindex="0" class="edit-expiration-date" *ngIf = "(program?.draft || program?.isNew) && validForDeactivation" [ngStyle]= "editMoveType? {'visibility': 'visible'} : {'visibility': 'hidden'} " (keydown.enter)="editProgramDetails()" (click)="editProgramDetails()" fxLayoutAlign="end">edit</mat-icon>
                                    </div>
                                </div>
                            </div>
                            <div fxFlex="20" role="cell" *ngIf="isBBProgFields">
                                <div class="field"
                                    tabindex="0" (keyup.enter)="editProgramDetails()"
                                    (mouseover) = "editValueSystem = true" (mouseout) = "editValueSystem = false" 
                                    (focus) = "editValueSystem = true" (focusout) = "editValueSystem = false" 
                                    role="listitem" aria-label="Value System {{ program?.valueSystem }}">
                                    <div class="label" fxLayout = "row" role="none">Value System
                                        <mat-icon tabindex="0" class="edit-expiration-date" *ngIf = "!program?.valueSystem && (program?.draft || program?.isNew) && validForDeactivation" [ngStyle]= "editValueSystem? {'visibility': 'visible'} : {'visibility': 'hidden'} " (keydown.enter)="editProgramDetails()" (click)="editProgramDetails()" fxLayoutAlign="end">edit</mat-icon>
                                    </div>
                                    <div class="value" fxLayout = "row" *ngIf="program?.valueSystem" role="none">{{ program?.valueSystem }}
                                        <mat-icon tabindex="0" class="edit-expiration-date" *ngIf = "(program?.draft || program?.isNew) && validForDeactivation" [ngStyle]= "editValueSystem? {'visibility': 'visible'} : {'visibility': 'hidden'} " (keydown.enter)="editProgramDetails()" (click)="editProgramDetails()" fxLayoutAlign="end">edit</mat-icon>
                                    </div>
                                </div>
                            </div>
                            <div fxFlex="20" role="cell" *ngIf="isBBProgFields && (program?.valueSystem === ProgramValueType.CASH || program?.valueSystem === ProgramValueType.BOTH)">
                                <div class="field"
                                    tabindex="0" (keyup.enter)="editProgramDetails()"
                                    (mouseover) = "editCurrency = true" (mouseout) = "editCurrency = false" 
                                    (focus) = "editCurrency = true" (focusout) = "editCurrency = false" 
                                    role="listitem" aria-label="Currency {{ program?.currency }}">
                                    <div class="label" fxLayout = "row" role="none">Currency
                                        <mat-icon tabindex="0" class="edit-expiration-date" *ngIf = "!program?.currency && (program?.draft || program?.isNew) && validForDeactivation" [ngStyle]= "editCurrency? {'visibility': 'visible'} : {'visibility': 'hidden'} " (keydown.enter)="editProgramDetails()" (click)="editProgramDetails()" fxLayoutAlign="end">edit</mat-icon>
                                    </div>
                                    <div class="value" fxLayout = "row" *ngIf="program?.currency" role="none">{{ program?.currency }}
                                        <mat-icon tabindex="0" class="edit-expiration-date" *ngIf = "(program?.draft || program?.isNew) && validForDeactivation" [ngStyle]= "editCurrency? {'visibility': 'visible'} : {'visibility': 'hidden'} " (keydown.enter)="editProgramDetails()" (click)="editProgramDetails()" fxLayoutAlign="end">edit</mat-icon>
                                    </div>
                                </div>
                            </div>
                            <div fxFlex="20" role="cell" *ngIf="isBBProgFields && (program?.valueSystem === ProgramValueType.CASH || program?.valueSystem === ProgramValueType.BOTH)">
                                <div class="field" 
                                    tabindex="0" (keyup.enter)="editProgramDetails()"
                                    (mouseover) = "editTotalAmmount = true" (mouseout) = "editTotalAmmount = false" 
                                    (focus) = "editTotalAmmount = true" (focusout) = "editTotalAmmount = false" 
                                    role="listitem" aria-label="Total Budget {{ program?.totalAmount }}">
                                    <div class="label" fxLayout = "row" role="none">Total Budget
                                        <mat-icon tabindex="0" class="edit-expiration-date" *ngIf = "!program?.totalAmount && userCapabilities > -1 && (program?.draft || program?.isNew) && validForDeactivation" [ngStyle]= "editTotalAmmount? {'visibility': 'visible'} : {'visibility': 'hidden'} " (keydown.enter)="editProgramDetails()" (click)="editProgramDetails()" fxLayoutAlign="end">edit</mat-icon>
                                    </div>
                                    <div class="value" fxLayout = "row" *ngIf="program?.totalAmount" role="none">{{ program?.totalAmount  | number:'1.0':'en-US'  }}
                                        <mat-icon tabindex="0" class="edit-expiration-date" *ngIf = "(userCapabilities > -1) && (program?.draft || program?.active || program?.isNew) && validForDeactivation" [ngStyle]= "editTotalAmmount? {'visibility': 'visible'} : {'visibility': 'hidden'} " (keydown.enter)="editProgramDetails()" (click)="editProgramDetails()" fxLayoutAlign="end">edit</mat-icon>
                                    </div>
                                </div>
                            </div>
                            <div fxFlex="20" role="cell" *ngIf="!isMMUser && !isGOUser && isBBProgFields && (!program?.valueSystem || program?.valueSystem === ProgramValueType.POINTS || program?.valueSystem === ProgramValueType.BOTH)">
                                <div class="field" 
                                    tabindex="0" (keyup.enter)="editProgramDetails()"
                                    (mouseover) = "editTotalPoints = true" (mouseout) = "editTotalPoints = false" 
                                    (focus) = "editTotalPoints = true" (focusout) = "editTotalPoints = false" 
                                    role="listitem" aria-label="Total Points for Assigned Transferees {{ program?.totalPoints }}">
                                    <div class="label" fxLayout = "row" role="none">Total Points
                                        <mat-icon tabindex="0" class="edit-expiration-date" *ngIf = "!program?.totalPoints && (userCapabilities > -1) && (program?.draft || program?.active) && validForDeactivation" [ngStyle]= "editTotalPoints? {'visibility': 'visible'} : {'visibility': 'hidden'} " (click)="editProgramDetails()" fxLayoutAlign="end">edit</mat-icon>
                                    </div>
                                    <div class="value" fxLayout = "row" *ngIf="program?.totalPoints" role="none">{{ program?.totalPoints }}
                                        <mat-icon tabindex="0" class="edit-expiration-date" *ngIf = "(userCapabilities > -1) && (program?.draft || program?.active || program?.isNew) && validForDeactivation" [ngStyle]= "editTotalPoints? {'visibility': 'visible'} : {'visibility': 'hidden'} " (click)="editProgramDetails()" fxLayoutAlign="end">edit</mat-icon>
                                    </div>
                                </div>
                            </div>
                            <div fxFlex="20" role="cell" *ngIf="isGOUser && isBBProgFields">
                                <div class="field" 
                                    tabindex="0" (keyup.enter)="editProgramDetails()"
                                    (mouseover) = "editTotalPoints = true" (mouseout) = "editTotalPoints = false" 
                                    (focus) = "editTotalPoints = true" (focusout) = "editTotalPoints = false" 
                                    role="listitem" aria-label="Total Points for Assigned Transferees {{ program.totalPoints }}">
                                    <div class="label" fxLayout = "row" role="none">Total Points for Assigned Transferees
                                        <!-- <mat-icon tabindex="0" class="edit-expiration-date" *ngIf = "!program.totalPoints && userRole === 'program-admin' && program.draft && validForDeactivation" [ngStyle]= "editTotalPoints? {'visibility': 'visible'} : {'visibility': 'hidden'} " (click)="editProgramDetails()" fxLayoutAlign="end">edit</mat-icon> -->
                                    </div>
                                    <div class="value" fxLayout = "row"  role="none">Custom Points
                                        <mat-icon tabindex="0" class="edit-expiration-date" *ngIf = "userCapabilities > -1 && program?.draft && validForDeactivation" [ngStyle]= "editTotalPoints? {'visibility': 'visible'} : {'visibility': 'hidden'} " (click)="editProgramDetails()" fxLayoutAlign="end">edit</mat-icon>
                                    </div>
                                </div>
                            </div>

                            <div fxFlex="40" role="cell">
                                <div class="field" role="none" aria-label="Program Template {{ program?.templateName }}">
                                    <div class="label" role="none">Program Template</div>
                                    <div class="value" *ngIf="program?.templateName" role="none">{{ program?.templateName }}</div>
                                </div>
                            </div>
                            <ng-container *ngIf="isBBProgFields; else legacyContract"></ng-container>
                            <ng-container *ngIf="isBBProgFields; else policy"></ng-container>
                            <ng-container *ngIf="isBBProgFields; else programExpiration"></ng-container>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start start" role="row" fxLayoutGap="15px">
                            <div fxFlex="30" role="cell">
                                <div class="field" role="none" aria-label="Program Activation Date {{ program?.programActivationDate || 'N/A' }}">
                                    <div class="label" role="none">Program Activation Date</div>
                                    <div class="value" role="none">{{ program?.programActivationDate ? (program?.programActivationDate | date:'MM/dd/yyyy') : 'N/A'}}</div>
                                </div>
                            </div>
                            <div fxFlex="15" role="cell" *ngIf="isBBProgFields">
                                <div class="field" tabindex="0" (keyup.enter)="editProgramDetails()"
                                    (mouseover) = "editIsCartusInsured = true" (mouseout) = "editIsCartusInsured = false" 
                                    (focus) = "editIsCartusInsured = true" (focusout) = "editIsCartusInsured = false" 
                                    role="none" aria-label="Program Expiration (Optional) {{ program?.programExpirationDate }}">
                                    <div class="label" role="none">Cartus Insured Shipping</div>
                                    <div class="value" fxLayout = "row" role="none">{{ program?.isCartusInsured ? 'Insured by Cartus' : 'Not Insured by Cartus'}}
                                        <mat-icon tabindex="0" class="edit-expiration-date" *ngIf = "(userCapabilities > -1) && program?.draft && validForDeactivation" [ngStyle]= "editIsCartusInsured? {'visibility': 'visible'} : {'visibility': 'hidden'} " (click)="editProgramDetails()" fxLayoutAlign="end">edit</mat-icon>
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="!isBBProgFields; else legacyContract"></ng-container>
                            <ng-template #legacyContract>
                                <div *ngIf="!((userRole=== 'client-contact-initiator')||(userRole==='client-contact'))" fxFlex="17" role="cell">
                                    <div class="field" role="none" 
                                        tabindex="0" (keyup.enter)="editProgramDetails()"
                                        (mouseover) = "editContract = true" (mouseout) = "editContract = false" 
                                        (focus) = "editContract = true" (focusout) = "editContract = false"
                                        aria-label="Legacy Contract {{ program?.contractSeries }}">
                                        <div class="label" fxLayout = "row" role="none">Legacy Contract
                                            <mat-icon tabindex="0" class="edit-expiration-date" *ngIf = "!program?.contractSeries && (userCapabilities > -1) && (program?.draft || program?.active) && validForDeactivation" [ngStyle]= "editContract? {'visibility': 'visible'} : {'visibility': 'hidden'} " (click)="editProgramDetails()" fxLayoutAlign="end">edit</mat-icon>
                                        </div>
                                        <div class="value" fxLayout = "row" *ngIf="program?.contractSeries" role="none">
                                            <textarea readonly fxFlex="100">{{ program?.contractSeries }}</textarea>
                                            <mat-icon tabindex="0" class="edit-expiration-date" *ngIf = "(userCapabilities > -1) && (program?.draft || program?.active) && validForDeactivation" [ngStyle]= "editContract? {'visibility': 'visible'} : {'visibility': 'hidden'} " (click)="editProgramDetails()" fxLayoutAlign="end">edit</mat-icon>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-container *ngIf="!isBBProgFields; else policy"></ng-container>
                            <ng-template #policy>
                                <div fxFlex="26" role="cell">
                                    <div class="field"
                                        tabindex="0" (keyup.enter)="editProgramDetails()"
                                        (mouseover) = "editPolicy = true" (mouseout) = "editPolicy = false" 
                                        (focus) = "editPolicy = true" (focusout) = "editPolicy = false" 
                                        role="none" aria-label="Policy {{ program?.atlasPolicy }}">
                                        <div class="label" fxLayout = "row" role="none">Policy
                                            <mat-icon tabindex="0" class="edit-expiration-date" *ngIf = "!program?.atlasPolicy && (userCapabilities > -1) && (program?.draft || program?.active) && validForDeactivation" [ngStyle]= "editPolicy? {'visibility': 'visible'} : {'visibility': 'hidden'} " (click)="editProgramDetails()" fxLayoutAlign="end">edit</mat-icon>
                                        </div>
                                        <div class="value" fxLayout = "row" *ngIf="program?.atlasPolicy" role="none">{{ program?.atlasPolicy }}
                                            <mat-icon tabindex="0" class="edit-expiration-date" *ngIf = "(program?.draft || program?.active || program?.isNew) && validForDeactivation" [ngStyle]= "editPolicy? {'visibility': 'visible'} : {'visibility': 'hidden'} " (click)="editProgramDetails()" fxLayoutAlign="end">edit</mat-icon>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-container *ngIf="!isBBProgFields; else programExpiration"></ng-container>
                            <ng-template #programExpiration>
                            <div fxFlex="15" role="cell">
                                <div class="field" tabindex="0" (keyup.enter)="editProgramDetails()"
                                    (mouseover) = "editExpiration = true" (mouseout) = "editExpiration = false" 
                                    (focus) = "editExpiration = true" (focusout) = "editExpiration = false" 
                                    role="none" aria-label="Program Expiration (Optional) {{ program?.programExpirationDate }}">
                                    <div class="label" role="none">Program Expiration (Optional)</div>
                                    <div class="value" fxLayout = "row" role="none">{{ program?.programExpirationDate ? (program?.programExpirationDate | date:'MM/dd/yyyy') : 'N/A'}}
                                        <mat-icon tabindex="0" class="edit-expiration-date" *ngIf = "userCapabilities > -1" [ngStyle]= "editExpiration? {'visibility': 'visible'} : {'visibility': 'hidden'} " (click)="editProgramDetails()" fxLayoutAlign="end">edit</mat-icon>
                                    </div>
                                </div>
                            </div>
                            </ng-template>
                            <ng-container *ngIf="isBBProgFields; else sourceProgram"></ng-container>
                            <!-- <div fxFlex="30" role="cell" fxLayout = "row">
                                <form class="initial-form" [formGroup]="initialContactForm" fxLayout="row">
                                    <div class="initial-contatct" fxFlex="100" fxLayout = "row">
                                        <mat-checkbox class="example-margin"  ngDefaultControl formControlName="initialContactRequired" (change)="initialContact($event.checked)">
                                            <div class="initial-lable">Initial Contact <br> Requireds</div>
                                        </mat-checkbox>
                                        </div>
                                        <div class="initial-required" fxLayout = "row" fxFlex="56">
                                                <mat-form-field *ngIf="isShowInitialContact" >
                                                <mat-label class="initial-contact-lable">Initial Contact Required By</mat-label>
                                                <mat-select  formControlName="initialContactBy" >
                                                  <mat-option style="font-size: 14px;" *ngFor="let initialContact of initialContactRequired" [value]="initialContact.value" (onSelectionChange)="initialContactRequiredByChange(initialContact.value)">{{initialContact.viewValue}}</mat-option>
                                                </mat-select>
                                                </mat-form-field>
                                         </div>
                                </form>
                            </div> -->
                            <!-- <ng-template #draft>
                                <div fxFlex="30" role="cell" fxLayout = "row">
                                    <form class="initial-form" [formGroup]="initialContactForm">
                                        <div class="initial-contatct" fxFlex="100" fxLayout = "row">
                                            <mat-checkbox class="example-margin" ngDefaultControl formControlName="initialContactRequired" (change)="initialContact($event.checked)">
                                                <div class="initial-lable">Initial Contact <br> Required</div></mat-checkbox>
                                            </div>
                                            <div class="initial-required"  *ngIf="isShowInitialContact" fxLayout = "row" fxFlex="56">
                                                    <mat-form-field>
                                                    <mat-label class="initial-contact-lable">Initial Contact Required By</mat-label>
                                                    <mat-select formControlName="initialContactBy">
                                                    <mat-option style="font-size: 14px;" *ngFor="let initialContact of initialContactRequired" [value]="initialContact.value" (onSelectionChange)="initialContactRequiredByChange(initialContact.value)">{{initialContact.viewValue}}</mat-option>
                                                    </mat-select>
                                                    </mat-form-field>
                                             </div>
                                    </form>
                                </div>
                            </ng-template> -->
                        </div>
                        <div *ngIf = "mpInternalCapability > -1" fxLayout="row" fxLayoutAlign="start start" role="row" fxLayoutGap="10px">
                            <div fxFlex="{{program?.policyCallRequired !== 'Required' ? '18' : '16'}}" role="cell" *ngIf="isBBProgFields">
                                <div class="field" role="none"
                                    tabindex="0" (keyup.enter)="editProgramDetails()"
                                    (mouseover) = "editpolicyCallRequired = true" (mouseout) = "editpolicyCallRequired = false" 
                                    (focus) = "editpolicyCallRequired = true" (focusout) = "editpolicyCallRequired = false" >
                                    <div class="label" role="none">Policy Call Confirm</div>
                                    <div class="value" fxLayout = "row" role="none">{{ program?.policyCallRequired ? 'Required' : 'Not Required'}}
                                        <mat-chip class="chip-detail" *ngIf="program?.policyCallDuration else defaultPolicyCall">
                                            {{program?.policyCallDuration}} min
                                        </mat-chip>
                                        <ng-template #defaultPolicyCall>
                                            <mat-chip class="chip-detail"> 60 min </mat-chip>
                                        </ng-template>
                                        <mat-icon tabindex="0" class="edit-expiration-date" *ngIf = "(userCapabilities > -1) && (program?.draft || program?.active) && validForDeactivation" [ngStyle]= "editpolicyCallRequired? {'visibility': 'visible'} : {'visibility': 'hidden'} " (click)="editProgramDetails()" fxLayoutAlign="end">edit</mat-icon>   
                                    </div>
                                </div>
                            </div>
                            <div fxFlex="26" role="cell" *ngIf="!isBBProgFields">
                                <div class="field"
                                    tabindex="0" (keyup.enter)="editProgramDetails()" 
                                    role="none" aria-label="{{ program?.initialAuditRequired }}">
                                    <div class="label" fxLayout = "row" role="none">Initial Audit</div>
                                    <div class="value" fxLayout = "row" role="none">{{program?.initialAuditRequired ? 'Required' : 'Not Required'}}</div>
                                </div>
                            </div>
                            <!--new div for initial contact required by-->
                            <div fxFlex="26" role="cell">
                                <div class="field"
                                    tabindex="0" (keyup.enter)="editProgramDetails()"
                                    (mouseover) = "editInitialContactRequired= true" (mouseout) = "editInitialContactRequired = false" 
                                    (focus) = "editInitialContactRequired = true" (focusout) = "editInitialContactRequired = false" 
                                    role="none" aria-label="{{ program?.initialContactBy }}">
                                    <div class="label" fxLayout = "row" role="none">Initial Contact Required By</div>
                                    <div class="value" fxLayout = "row" role="none">{{ program?.initialContactRequired ? program?.initialContactBy : 'N/A'}}
                                        <mat-icon tabindex="0" class="edit-expiration-date" *ngIf = "(userCapabilities > -1)" [ngStyle]= "editInitialContactRequired? {'visibility': 'visible'} : {'visibility': 'hidden'} " (click)="editProgramDetails()" fxLayoutAlign="end">edit</mat-icon>
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="!isBBProgFields; else sourceProgram"></ng-container>
                            <div *ngIf="isBBProgFields" class="field" fxFlex="20" role="cell" aria-label="Diversity, Equity & Inclusion">
                                <div class="label" role="none">Diversity, Equity & Inclusion</div>
                                <div class="value"  role="none">{{ program.isDEIEnabled ? 'Yes' : 'No' }}</div>
                            </div>
                            <div *ngIf="isBBProgFields"   class="field" fxFlex="20" role="cell" aria-label="Sustainability">
                                <div class="label" role="none">Sustainability</div>
                                <div class="value"  role="none">{{ program.isSustainabilityEnabled ? 'Yes' : 'No' }}</div>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start start" role="row" fxLayoutGap="15px" *ngIf="isGOUser">
                            <div fxFlex="20" role="cell" *ngIf="isGOUser">
                                <div class="field" *ngIf="isBBProgFields"
                                    tabindex="0" (keyup.enter)="editProgramDetails()"
                                    (mouseover) = "editAllocationType = true" (mouseout) = "editAllocationType = false" 
                                    (focus) = "editAllocationType = true" (focusout) = "editAllocationType = false" 
                                    role="listitem" aria-label="points Allocation Type {{ program.pointsValueAllocationType }}">
                                    <div class="label" fxLayout = "row" role="none">Points/ Value Allocation Type
                                        <!-- <mat-icon tabindex="0" class="edit-expiration-date" *ngIf = "!program.totalPoints && userRole === 'program-admin' && program.draft && validForDeactivation" [ngStyle]= "editTotalPoints? {'visibility': 'visible'} : {'visibility': 'hidden'} " (click)="editProgramDetails()" fxLayoutAlign="end">edit</mat-icon> -->
                                    </div>
                                    <div class="value" fxLayout = "row" *ngIf="program?.pointsValueAllocationType" role="none">{{ program?.pointsValueAllocationType }}
                                        <mat-icon tabindex="0" class="edit-expiration-date" *ngIf = "userCapabilities > -1" [ngStyle]= "editAllocationType? {'visibility': 'visible'} : {'visibility': 'hidden'} " (click)="editProgramDetails()" fxLayoutAlign="end">edit</mat-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-panel-description>
            </mat-expansion-panel>
        </mat-accordion>
        <ng-template #sourceProgram>
            <div class="field" fxFlex="20" role="cell" aria-label="Source Program Name {{ program?.sourceProgramName }}">
                <div class="label" role="none">Source Program Name</div>
                <div class="value" role="none">{{ program?.sourceProgramName }}</div>
            </div>
        </ng-template>
    </div>
</div>
<div class="search-block" role="search" fxLayout.xs="row" fxLayoutAlign.xs="center center">
    <div fxLayout.xs="column" fxFlex="25%" role="searchbox">
        <mat-form-field class="table-search" appearance="outline">
            <span matPrefix class="lg-only" role="none">
                <mat-icon>search</mat-icon>
            </span>
            <input matInput tabindex="0" placeholder="Search for benefits by name" [(ngModel)]="searchValue" (keyup) = "textSearch($event)" (keyup.enter)="textSearchBenefits($event.target.value)" role="searchbox">
            <button tabindex="0" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="clearTextSearch()" *ngIf="searchValue" aria-label="Clear Search">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
        <!--<button mat-icon-button class="filter-icon" matTooltip="Filter results"><span tabindex="0" aria-hidden="true" class="material-icons">filter_list</span></button> -->
    </div>
    <div fxLayout="row" fxFlex="30%" role="valueSystem">
        <span class="text-value-system">Value System :</span>
        <span class="cash" [ngClass]="{
          'bold': (program?.valueSystem === ProgramValueType.CASH) 
             || (program?.valueSystem === ProgramValueType.BOTH && userValueSelection === ProgramValueType.CASH)
        }">
            Cash
        </span>
    
        <mat-slide-toggle #toggleRef aria-label="Value System" class="value-system-toggle" [checked]="(program?.valueSystem === ProgramValueType.POINTS) 
           || (program?.valueSystem === ProgramValueType.BOTH && userValueSelection === ProgramValueType.POINTS)"
            [disabled]="program?.valueSystem === ProgramValueType.CASH || program?.valueSystem === ProgramValueType.POINTS"
            (change)="onToggleChange($event.checked)">
        </mat-slide-toggle>
    
        <span class="points" [ngClass]="{
          'bold': (program?.valueSystem === ProgramValueType.POINTS) 
             || (program?.valueSystem === ProgramValueType.BOTH && userValueSelection === ProgramValueType.POINTS)
        }">
            Points
        </span>
    </div>
    
</div>
<div  tabindex="0"  class="benefit-list" role="listbox" #scrollContainer >
    <ng-container *ngFor="let benefitGroup of benefitsList; let j=index" >
            <h3 [attr.aria-label]="'Move Phase: ' + benefitGroup.group" *ngIf = "benefitGroup.items.length > 0 ">Move Phase: <span role="none">{{ benefitGroup.group | titlecase }}</span></h3>
            <ul cdkDropList id="{{benefitGroup.group}}" (cdkDropListDropped)="drop($event)" [cdkDropListData]="benefitGroup.group"  [cdkDropListConnectedTo]="connectedTo">
            <li *ngFor="let benefit of benefitGroup.items; let i= index" cdkDrag [cdkDropListScrollContainer]="scrollContainer" (cdkDragStarted)="dragStarted($event,i,j)" [cdkDragDisabled]="1==1" [ngStyle]="benefit.clonedCopy ? {'border-color':'blue','border-style':'solid'}:''">              
                <ng-container *ngIf="!benefit.suppress ">
                    <div class="drag-placeholder" *cdkDragPlaceholder></div>
                    <div *ngIf="(userCapabilities > -1) && (program?.isNew || program?.draft)" fxFlex="2" role="cell" class="drag-handle" >
                        <!-- <span class="dot-handler" cdkDragHandle>::</span> -->
                        <mat-icon class="dot-handler" cdkDragHandle>drag_indicator</mat-icon>
                    </div> 
                    <mat-card  tabindex="0" class="mat-elevation-z3 drag-card " fxFlex="calc(33%-25px)" fxFlex.sm="calc(50%-25px)" [ngClass]="{ 'consultant-only': benefit.consultantBenefitInfo?.consultant && benefit?.points !== 'Guaranteed' ,'isNewelyAdded':benefit.isNewlyAddedRef }" *ngIf = "benefit">
                        <mat-card-content>
                            <div tabindex="0" fxLayout="row" fxLayoutAlign.xs="center center" role="row" fxLayoutGap="15px">
                                <div tabindex="0" fxFlex="10" fxLayout="column" fxLayoutGap="10px" role="cell" class="img-icon">
                                  <mat-chip tabindex="0" class="temp-chip templiving-logo" *ngIf = "benefit?.tempLiving">Supplier Orders <img src="../../../../../../assets/icons/icon_supplierOrders.svg" alt="Icon here" role="figure" class="icon-img"></mat-chip>
                                  <mat-chip tabindex="0"  class="temp-chip insured-logo" *ngIf = "benefit?.clientIsCartusInsured">Cartus Insured<img src="../../../../../../assets/icons/icon_cartusInsured.svg" alt="Icon here" role="figure" class="icon-img"></mat-chip>
                                    <img tabindex="0" [src]="benefit.iconLink" alt="benefit" role="figure" class="mat-elevation-z3">
                                        <div tabindex="0" *ngIf="benefit?.hybridTempLiving" style="border: 0.2px solid gray;">
                                            <mat-chip tabindex="0" *ngIf="benefit?.incremental &&!isChipEditable[i] &&!benefit.isNewlyAddedRef " style="margin-bottom: 5px;"
                                                class="chip-style">
                                                <div tabindex="0" class="rangeIncrementUnit" [class.tn-focus]='edit-point-edit'>Core {{benefit?.rangeIncrementUnit}}
                                                    Per
                                                    Selection </div>
                                                <div tabindex="0">{{benefit?.coreUnitsPerSelection}}</div>
                                                <button tabindex="0" mat-icon-button
                                                    *ngIf="(userCapabilities > -1) && ((program.draft || program.isNew) && benefit?.incremental)"
                                                    (click)="enabledChipToEdit(i,j); rangeIncrementValue = benefit.rangeIncrementValue">
                                                    <!-- (click)="enabledChipToEdit(i,j)" -->
                                                    <mat-icon tabindex="0">edit</mat-icon>
                                                </button>
                                            </mat-chip>
                                            <mat-chip tabindex="0" class="chip-style" (mouseover)="editRaneIncrementVal[i] = true"
                                                *ngIf="benefit?.incremental &&!isChipEditable[i] &&!benefit.isNewlyAddedRef " color="#452D7C" selected>
                                                <div tabindex="0" class="rangeIncrementUnit" [class.tn-focus]='edit-point-edit'>Flex {{ benefit?.rangeIncrementUnit
                                                    }} Per
                                                    Selection </div>
                                                <div tabindex="0"> {{ benefit?.rangeIncrementValue }}</div>
                                                <button tabindex="0" mat-icon-button
                                                    *ngIf="(userCapabilities > -1) && ((program.draft || program.isNew) && benefit?.incremental)"
                                                    (click)="enabledChipToEdit(i,j); rangeIncrementValue = benefit.rangeIncrementValue">
                                                    <!-- (click)="enabledChipToEdit(i,j)" -->
                                        
                                                    <mat-icon tabindex="0">edit</mat-icon>
                                                </button>
                                            </mat-chip>
                                            <div tabindex="0" class="chip-outer-box" *ngIf="benefit?.incremental && isChipEditable[i]">
                                                <div class="timeframeOuter">
                                                    <span class="timeframeSpan"> Timeframe </span>
                                                    <button tabindex="0" mat-icon-button class="matIconStyle" (click)="closeHybrid(i, j)"
                                                        style="margin-right:10px">
                                                        <mat-icon>cancel</mat-icon>
                                                    </button>
                                                    <button tabindex="0" mat-icon-button class="matIconStyle"
                                                        (click)="closeRangeIncrementValueMenu(benefit, index); isChipEditable[i]=false;">
                                                        <mat-icon style="color: #3865f8;">check_circle</mat-icon>
                                                    </button>
                                                </div>
                                                <div tabindex="0">
                                                    <mat-form-field tabindex="0" appearance="fill" style="width: 100%;">
                                                        <mat-select tabindex="0" matNativeControl [formControl]="timeSpanValue" [(ngModel)]="benefit.rangeIncrementUnit"
                                                            name="timeFrame">
                                                            <mat-option tabindex="0" value="Days" selected="selected">Days</mat-option>
                                                            <mat-option tabindex="0" value="Months">Months</mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                    <mat-chip tabindex="0" style="margin-top: -10px;" class="StyleChip">Core {{benefit.rangeIncrementUnit}} per selection
                                                        <input tabindex="0" type="number" number-only class="inputSelection" [(ngModel)]="benefit.coreUnitsPerSelection"
                                                            (keyup.backspace)="onMatChipKeyPressCore($event,benefit.coreUnitsPerSelection,j,i)">
                                                    </mat-chip>
                                                    <mat-chip  tabindex="0" class="StyleChip">Flex {{benefit.rangeIncrementUnit}} per selection
                                                        <input type="number" class="inputSelection" number-only tabindex="0" required name="rangeIncrementVal"
                                                            id="rangeIncrementVal_{{i}}" [(ngModel)]="rangeIncrementValue" #rangeIncrementVal="ngModel"
                                                            (keyup.backspace)="onMatChipKeyPress($event,rangeIncrementValue,j,i)">
                                                    </mat-chip>
                                                </div>
                                            </div>
                                        </div>
                                        <div tabindex="0" *ngIf="!benefit?.hybridTempLiving">
                                            <mat-chip tabindex="0" (mouseover)="editRaneIncrementVal[i] = true"
                                                *ngIf="benefit?.incremental &&!isChipEditable[i] &&!benefit.isNewlyAddedRef" color="#452D7C" selected>
                                                <div tabindex="0" class="rangeIncrementUnit" [class.tn-focus]='edit-point-edit'>{{ benefit?.rangeIncrementUnit }} Per
                                                    Selection </div>
                                                <div tabindex="0"> {{ benefit?.rangeIncrementValue }}</div>
                                                <button tabindex="0" mat-icon-button class="edit-point" (focusout)="editRaneIncrementVal[i] = false"
                                                    [ngStyle]="editRaneIncrementVal[i] ? {'visibility': 'visible'} : {'visibility': 'hidden'}"
                                                    [ngClass]="{'edit-point-edit': editRaneIncrementVal[i]}"
                                                    *ngIf="(userCapabilities > -1) && ((program?.draft || program?.isNew) && benefit?.incremental)"
                                                    (click)="enabledChipToEdit(i,j); rangeIncrementValue = benefit.rangeIncrementValue">
                                                    <mat-icon tabindex="0">edit</mat-icon>
                                                </button>
                                            </mat-chip>
                                            <mat-chip tabindex="0" class="chip-form style-chip " *ngIf="benefit?.incremental && isChipEditable[i]">
                                                <form tabindex="0" style="display: flex;flex-flow: row wrap;align-items: center;">
                                                    <mat-form-field tabindex="0" style="width: 33%; font-size: 13px;" *ngIf="benefit?.incremental">
                                                        <mat-select tabindex="0" [formControl]="timeSpanValue" [(ngModel)]="benefit.rangeIncrementUnit" ngDefaultControl>
                                                            <mat-option tabindex="0" *ngFor="let data of timespanValues" style="font-size: 15px;" [value]="data"
                                                                name="timeSpanValue" class="form-control">
                                                                {{ data }}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                    <mat-form-field tabindex="0" [ngStyle]="benefit?.incremental?{'width': '10%','margin-left':'3%'} : {'width': '20%'}">
                                                        <input matInput number-only ngDefaultControl tabindex="0" number required name="rangeIncrementVal"
                                                            id="rangeIncrementVal_{{i}}" [(ngModel)]="rangeIncrementValue" #rangeIncrementVal="ngModel"
                                                            (keyup.backspace)="onMatChipKeyPress($event,rangeIncrementValue,j,i)">
                                                    </mat-form-field>
                                                    <button tabindex="0" mat-icon-button class="edit-point"
                                                        (focusout)="editRaneIncrementVal[i] = false; isChipEditable[i]=false;"
                                                        [ngStyle]="editRaneIncrementVal[i] ? {'visibility': 'visible'} : {'visibility': 'hidden'}"
                                                        [ngClass]="{'edit-point-edit': editRaneIncrementVal[i]}"
                                                        *ngIf="(userCapabilities > -1) && ((program?.draft || program?.isNew) && benefit?.incremental)"
                                                        (click)="closeRangeIncrementValueMenu(benefit, index); isChipEditable[i]=false;">
                                                        <mat-icon tabindex="0">edit</mat-icon>
                                                    </button>
                                                </form>
                                            </mat-chip>
                                        </div>
                                <mat-chip tabindex="0" class="matchip-css style-chip " (mouseover) = "editPerPointCurrency[i] = true"  *ngIf="benefit?.incrementalCurrency &&!benefit.isNewlyAddedRef" color="#452D7C" selected><div class="dollarPerPointUnit" [class.tn-focus]='edit-point-edit'>Value Per Point </div><div class="dollar-text"> {{ benefit?.perPointCurrency }}</div> <div class="left-margin-style dollar-text">{{benefit?.rangeIncrementUnit}}</div>
                                    <button tabindex="0" mat-icon-button class="edit-point" (focusout) = "editPerPointCurrency[i] = false; "
                                        [ngStyle]= "editPerPointCurrency[i] ? {'visibility': 'visible'} : {'visibility': 'hidden'}" [ngClass]="{'edit-point-edit': editPerPointCurrency[i]}"
                                        *ngIf = "(userCapabilities > -1) && ((program?.draft || program?.isNew) && benefit?.incrementalCurrency)"
                                        [matMenuTriggerFor]="perPointCurrencyMenu"
                                        (menuOpened)="openRangeIncrementValueMenu()"
                                        >
                                        <mat-icon tabindex="0">edit</mat-icon> 
                                    </button>
                                    <mat-menu #rangeIncrementValueMenu="matMenu" xPosition="before" overlapTrigger="true"
                                    (closed) = "$event == undefined && (rangeIncrementVal.dirty || rangeIncrementVal.touched) ? benefit.rangeIncrementValue = rangeIncrementValue : null; closeRangeIncrementValueMenu(benefit, index); rangeIncrementValue = null;editRaneIncrementVal[i] = false;">
                                        <input matInput number-only tabindex="0" ngDefaultControl number required placeholder="Enter Range Increment Value" name="rangeIncrementVal" id="rangeIncrementVal_{{i}}" [(ngModel)]="rangeIncrementValue" #rangeIncrementVal="ngModel" class="form-control input-point"
                                            (click)="$event.stopPropagation()">
                                    </mat-menu>
    
                                    <mat-menu #perPointCurrencyMenu="matMenu" xPosition="before" overlapTrigger="true"
                                        (closed) = "$event == undefined && (perPointCurrencyVal.dirty || perPointCurrencyVal.touched) ? benefit.perPointCurrency = perPointCurrency : null; closePerPointCurrencyMenu(benefit); perPointCurrency = null; editPerPointCurrency[i] = false;">
                                        <input matInput number-only tabindex="0" ngDefaultControl number required placeholder="Enter Per point Value" name="perPointCurrencyVal" id="perPointCurrencyVal_{{i}}" [(ngModel)]="perPointCurrency"  #perPointCurrencyVal="ngModel"  class="form-control input-point" 
                                            (click)="$event.stopPropagation();">
                                    </mat-menu>
                                </mat-chip>
    
                                </div>
                                
                                <div *ngIf="!benefit.isNewlyAddedRef" fxFlex="45" role="cell" class="benefit-detail" [ngStyle]= "benefit.suppress ? {'pointer-events': 'none'} : ''">
                                    <div tabindex="0" fxLayout="row" fxLayoutGap="15px">                                   
                                        <div fxFlex="70" role="cell" [attr.aria-label]="'Benefit Title: ' + benefit.displayName" class="title-container">
                                          <h5>Title</h5>
                                          <h3 tabindex="0">                                       
                                            <span *ngIf="!benefit.editTitle">{{ benefit.displayName || 'N/A' }}</span>
                                            <input matInput tabindex="0" fxflex="80%" name="titleVal" id="titleVal_{{i}}" ngDefaultControl
                                              [(ngModel)]="benefit.displayName" #titleVal="ngModel" #title *ngIf="benefit.editTitle" class="form-control" 
                                              [class.tn-focus]='edit-point-edit'>                                
                                            <button fxFlex="20%" tabindex="0" mat-icon-button aria-label="edit title"
                                              title="edit title" class="edit-point"
                                              [ngClass]="{'edit-point-edit': benefit.editTitle}"
                                              *ngIf="((userCapabilities > -1) &&( (program?.isNew || program?.active) || (!program?.isNew && program?.draft)) && validForDeactivation)"
                                              (click)="editProgramTitle(benefit)" (keydown.enter)="editProgramTitle(benefit)">                                          
                                              <mat-icon tabindex="0">edit</mat-icon>
                                            </button>
                                          </h3>
                                        </div>
    
                                        <div fxFlex="30" tabindex="0" role="cell" [attr.aria-label]="'Total Points: ' + benefit.points" *ngIf="!isMMUser && !benefit.reference.includes(dynamicBenefitService.lumpSumBenefitPrefix)"
                                            (mouseover) = "editPoint[i] = true;" fxLayout = "row" fxLayoutGap="1em" (mouseout) = "editPoint[i] = false">
                                            <div tabindex="0"fxFlex="auto">
                                                <h5 *ngIf="!isMMUser">Points</h5>
                                                <h5 *ngIf="isMMUser"></h5>
                                                <h3 fxLayout = "row" tabindex="0" [ngStyle]= "!(pointVal.touched || pointVal.dirty) || benefit.points ? {'color': '#424242'} : {'color': '#f00'} "
                                            (focus) = "editPoint[i] = true; " >   
                                            <span tabindex="0" *ngIf="benefit.singleSelectTier; else cashout">
                                                <span  tabindex="0" fxFlex="80%" (click) = "openPointsLogicModal(benefit)">Variables</span>
                                                <button fxFlex="20%" tabindex="0" (focusout) = "editPoint[i] = false" mat-icon-button class="edit-point" 
                                                        [ngStyle]= "editPoint[i] ? {'visibility': 'visible'} : {'visibility': 'hidden'}" (click) = "openPointsLogicModal(benefit)">
                                                        <mat-icon tabindex="0">edit</mat-icon> 
                                                    </button>
                                               </span>
                                               <ng-template #cashout>
                                                <span tabindex="0" fxFlex="80%"> {{  (benefit.points || (benefit.points ===0 && (!benefit?.advCashOutV2 && !benefit?.advCashOut) )) ? (benefit.points === 'Guaranteed'? 'Core': benefit.points): (benefit?.advCashOutV2 || benefit?.advCashOut) ? 'Logic':'' }} </span>
                                                <button fxFlex="20%" tabindex="0" (focusout) = "editPoint[i] = false" mat-icon-button class="edit-point" 
                                                        [ngStyle]= "editPoint[i] ? {'visibility': 'visible'} : {'visibility': 'hidden'}" [ngClass]="{'edit-point-edit': editMultiplicity[i] || editIncremental[i]}"
                                                        *ngIf = "userCapabilities > -1 && validForDeactivation && ( program.isNew || (!program.isNew && program.draft)) && !benefit?.advCashOutV2 && !benefit?.advCashOut && !benefit?.incremental && !benefit?.incrementalCurrency && !benefit?.multiplicity || (userCapabilities > -1 && ( program.isNew || (!program.isNew && program.draft)) && (benefit?.multiplicity && editMultiplicity[i]) || (benefit?.incremental && editIncremental[i]))" [matMenuTriggerFor]="pointMenu" (menuOpened)="openPointMenu()">
                                                        <mat-icon tabindex="0">edit</mat-icon> 
                                                    </button>
        
                                                    <!-- Advance point logic for editing -->
                                                    <button fxFlex="20%" tabindex="0" (focusout) = "editPoint[i] = false" mat-icon-button class="edit-point" 
                                                        [ngStyle]= "editPoint[i] ? {'visibility': 'visible'} : {'visibility': 'hidden'}" [ngClass]="{'edit-point-edit': editMultiplicity[i] || editIncremental[i] }"
                                                        *ngIf = "userCapabilities > -1 && validForDeactivation && ( program.isNew || (!program.isNew && program.draft)) && (benefit?.advCashOut || benefit?.advCashOutV2)" (click) = "openPointsLogicModal(benefit)">
                                                        <mat-icon tabindex="0">edit</mat-icon> 
                                                    </button>
        
                                                    <!-- Advance point logic for view -->
                                                    <button fxFlex="20%" tabindex="0" (focusout) = "editPoint[i] = false" mat-icon-button class="edit-point" 
                                                        [ngStyle]= "editPoint[i] ? {'visibility': 'visible'} : {'visibility': 'hidden'}" [ngClass]="{'edit-point-edit': editMultiplicity[i] || editIncremental[i] }"
                                                        *ngIf = "(userCapabilities > -1 && ( !program.isNew && !program.draft && userCapabilities > -1) )  && (benefit.advCashOut || benefit?.advCashOutV2)" (click) = "openPointsLogicModal(benefit)">
                                                        <mat-icon>remove_red_eye</mat-icon> 
                                                    </button>
                                               </ng-template>
    
                                                <button fxFlex="20%" tabindex="0" (focusout) = "editPoint[i] = false; editMultiplicity[i] = false; editIncremental[i] = false;" mat-icon-button class="edit-point" 
                                                    [ngStyle]= "editPoint[i] || (editMultiplicity[i] || editIncremental[i] ) ? {'visibility': 'visible'} : {'visibility': 'hidden'} " [ngClass]="{'edit-point-edit': editMultiplicity[i] || editIncremental[i] }"
                                                    *ngIf = "(userCapabilities > -1) && validForDeactivation && ( program?.isNew || (!program?.isNew && program?.draft)) && ((benefit?.multiplicity && !editMultiplicity[i]) || (benefit?.incremental && !editIncremental[i]))" (click) = "editMultiplicity[i] = true;editIncremental[i] = true; editRangeMax(benefit); openPointMenu()">
                                                    <mat-icon tabindex="0">edit</mat-icon> 
                                                </button>
                                                
                                                <button fxFlex="20%" tabindex="0" mat-icon-button class="edit-point" (focusout) = "!editIncrementalCurrency[i] ? editPoint[i] = false: null;"
                                                    [ngStyle]= "(editPoint[i] || editIncrementalCurrency[i] ) ? {'visibility': 'visible'} : {'visibility': 'hidden'} "
                                                    [ngClass]="{'edit-point-edit': editIncrementalCurrency[i]}"
                                                    *ngIf = "(userCapabilities > -1) && validForDeactivation && ( program?.isNew || (!program?.isNew && program?.draft)) && ((benefit?.incrementalCurrency))" 
                                                    (click) = "editIncrementalCurrency[i]=!editIncrementalCurrency[i]; editMaxSelection(benefit, editIncrementalCurrency[i]); ">
                                                    <mat-icon tabindex="0">edit</mat-icon>
                                                </button>
                                                <mat-menu #pointMenu="matMenu" xPosition="before" overlapTrigger="false" 
                                                    (closed) = "$event == undefined && (pointVal.dirty || pointVal.touched) ? benefit.points = points : null; closePointMenu(benefit); points = null;editMultiplicity[i] = false;editIncremental[i] = false">
                                                    <div tabindex="0" (keydown)="$event.stopPropagation()">
                                                    <button tabindex="0"  (keydown.arrowDown)="focusNextItem($event)"  mat-menu-item *ngIf = "!(benefit?.multiplicity || benefit?.incremental || benefit?.incrementalCurrency) && validForDeactivation" (click) = "benefit.points = 'Guaranteed'">Core</button>
                                                    <input mat-menu-item (keydown.arrowUp)="focusPreviousItem($event)" matInput ngDefaultControl number-only tabindex="0" number required placeholder="Enter Points" name="pointVal" id="pointVal_{{i}}" [(ngModel)]="points" #pointVal="ngModel" class="form-control input-point"
                                                    (click)="$event.stopPropagation()">
                                                    </div>
                                                </mat-menu>
                                                </h3>
                                            </div>
                                            <span *ngIf="benefit.singleSelectTier; else maxSelection">
                                                <div fxFlex="auto">
                                                    <h5># Choices</h5>
                                                    <h3 class="select-edit-tier">   
                                                        <input type="number" readonly matInput number-only number tabindex="0" fxflex="80%" name="tierChoices" [value]="benefit?.tierValues?.length ? benefit?.tierValues?.length : 0">
                                                        <button fxFlex="100%" tabindex="0" (focusout) = "editPoint[i] = false" mat-icon-button class="edit-point" 
                                                        [ngStyle]= "editPoint[i] ? {'visibility': 'visible'} : {'visibility': 'hidden'}"  (click)="onChoiceIconClick(i)">
                                                        <mat-icon>remove_red_eye</mat-icon> 
                                                    </button>
                                                    <div class="selected-tier" *ngIf="visibleIndex === i">
                                                        <mat-card>
                                                            <div *ngFor="let tierValue of benefit.tierValues; let i=index">
                                                                <div>{{tierValue.value}}<span class="tier-value">{{tierValue.displayValue}}</span></div>
                                                              </div>
                                                          <div class="select-edit-option" (click) = "openPointsLogicModal(benefit)">Edit Option <span class="arrow-right-icon"><mat-icon>keyboard_arrow_right</mat-icon></span></div>
                                                            </mat-card>
                                                       </div>
                                                    </h3>
                                                  
                                                </div>
                                               </span>
                                               <ng-template #maxSelection>
                                            <div tabindex="0" fxFlex="auto" *ngIf = "benefit?.multiplicity || benefit?.incremental || benefit?.advCashOutV2 || benefit?.advCashOut || benefit?.incrementalCurrency">
                                                <h5>Max Selections</h5>
                                                <h3 fxLayout = "row" tabindex="0" [ngStyle]= "benefit?.incremental ? benefit.maxSelection == 0 ? {'color': '#f00'} : {'color': '#424242'} : benefit?.incrementalCurrency ? (benefit?.incrementalCurrency && (benefit?.rangeMax / benefit?.rangeIncrementValue === 0)) ? {'color': '#f00'} : {'color': '#424242'} : (!benefit.maxSelection || benefit.maxSelection == 0) ? {'color': '#f00'} : {'color': '#424242'}">   
                                                    <span fxFlex="80%" *ngIf = "!(editMultiplicity[i] || editIncremental[i] || editIncrementalCurrency[i]) && validForDeactivation"> {{  (benefit.maxSelection || benefit.maxSelection ===0) ? benefit.maxSelection : benefit?.incremental || benefit.incrementalCurrency ? (benefit?.rangeMax / benefit?.rangeIncrementValue === 0 || benefit?.rangeIncrementValue === '' || benefit?.rangeIncrementValue === "0" ? '' : benefit?.rangeMax / benefit?.rangeIncrementValue) : (benefit?.advCashOutV2 || benefit?.advCashOut) ? 'N/A':''}} </span>
                                                    <input type="number" ngDefaultControl matInput number-only number tabindex="0" fxflex="80%" name="maxSelection" id="maxSelection_{{i}}" #maxSelection = "ngModel"
                                                    [(ngModel)]="benefit.maxSelection" *ngIf="editMultiplicity[i] || editIncremental[i] || editIncrementalCurrency[i]" (focusout) = "editMultiplicity[i] = false;editIncremental[i] = false; editPoint[i] = false; editIncrementalCurrency[i] = false; closePointMenu(benefit); benefit?.incrementalCurrency? editMaxSelection(benefit, editIncrementalCurrency[i]): null"  class="form-control ">
                                                    
                                                </h3>
                                            </div>
                                            </ng-template>
                                        </div>
                                        <div tabindex="0" fxFlex="30" role="cell" [attr.aria-label]="'Total Points: ' + benefit.points" *ngIf="isMMUser && !benefit.reference.includes(dynamicBenefitService.lumpSumBenefitPrefix)"
                                            (mouseover) = "editPoint[i] = true;" fxLayout = "row" fxLayoutGap="1em" (mouseout) = "editPoint[i] = false">
                                            <div tabindex="0" fxFlex="auto">
                                                <h5 *ngIf="!isMMUser">Points</h5>
                                                <h5 *ngIf="isMMUser" class="pointsHeader"></h5>
                                                <h3 fxLayout = "row" tabindex="0" [ngStyle]= "!(pointVal.touched || pointVal.dirty) || benefit.points ? {'color': '#424242'} : {'color': '#f00'} "
                                            (focus) = "editPoint[i] = true; " >   
                                            <span tabindex="0" fxFlex="80%"> {{  (benefit?.amount === 'Guaranteed'? 'Core': benefit.amount)  }} </span>
                                            <button fxFlex="20%" tabindex="0" (focusout) = "editPoint[i] = false" mat-icon-button class="edit-point"
                                                    [ngStyle]= "editPoint[i] ? {'visibility': 'visible'} : {'visibility': 'hidden'}" [ngClass]="{'edit-point-edit': editMultiplicity[i] || editIncremental[i]}"
                                                    *ngIf = "!isMMUser && userCapabilities > -1 && validForDeactivation && ( program?.isNew || (!program?.isNew && program?.draft)) && !benefit?.advCashOut && !benefit?.incremental && !benefit?.incrementalCurrency && !benefit?.multiplicity || (userCapabilities > -1 && ( program?.isNew || (!program?.isNew && program?.draft)) && (benefit?.multiplicity && editMultiplicity[i]) || (benefit?.incremental && editIncremental[i]))" [matMenuTriggerFor]="pointMenu" (menuOpened)="openPointMenu()">
                                                    <mat-icon tabindex="0" >edit</mat-icon> 
                                                </button>
    
                                                <!-- Advance point logic for editing -->
                                                <button fxFlex="20%" tabindex="0" (focusout) = "editPoint[i] = false" mat-icon-button class="edit-point" 
                                                    [ngStyle]= "editPoint[i] ? {'visibility': 'visible'} : {'visibility': 'hidden'}" [ngClass]="{'edit-point-edit': editMultiplicity[i] || editIncremental[i] }"
                                                    *ngIf = "!isMMUser && userCapabilities > -1 && validForDeactivation && ( program?.isNew || (!program?.isNew && program?.draft)) && (benefit?.advCashOutV2 || benefit?.advCashOut)" (click) = "openPointsLogicModal(benefit)">
                                                    <mat-icon>edit</mat-icon> 
                                                </button>
    
                                                <!-- Advance point logic for view -->
                                                <button fxFlex="20%" tabindex="0" (focusout) = "editPoint[i] = false" mat-icon-button class="edit-point" 
                                                    [ngStyle]= "editPoint[i] ? {'visibility': 'visible'} : {'visibility': 'hidden'}" [ngClass]="{'edit-point-edit': editMultiplicity[i] || editIncremental[i] }"
                                                    *ngIf = "!isMMUser && (userCapabilities > -1 || ( !program?.isNew && !program?.draft && userCapabilities > -1) )  && (benefit?.advCashOutV2 || benefit?.advCashOut)" (click) = "openPointsLogicModal(benefit)">
                                                    <mat-icon>remove_red_eye</mat-icon> 
                                                </button>
    
                                                <button fxFlex="20%" tabindex="0" (focusout) = "editPoint[i] = false; editMultiplicity[i] = false; editIncremental[i] = false;" mat-icon-button class="edit-point" 
                                                    [ngStyle]= "editPoint[i] || (editMultiplicity[i] || editIncremental[i] ) ? {'visibility': 'visible'} : {'visibility': 'hidden'} " [ngClass]="{'edit-point-edit': editMultiplicity[i] || editIncremental[i] }"
                                                    *ngIf = "!isMMUser && userCapabilities > -1 && validForDeactivation && ( program?.isNew || (!program?.isNew && program?.draft)) && ((benefit?.multiplicity && !editMultiplicity[i]) || (benefit?.incremental && !editIncremental[i]))" (click) = "editMultiplicity[i] = true;editIncremental[i] = true; editRangeMax(benefit); openPointMenu()">
                                                    <mat-icon>edit</mat-icon> 
                                                </button>
                                                
                                                <button fxFlex="20%" tabindex="0"  mat-icon-button class="edit-point" (focusout) = "!editIncrementalCurrency[i] ? editPoint[i] = false: null;"
                                                    [ngStyle]= "(editPoint[i] || editIncrementalCurrency[i] ) ? {'visibility': 'visible'} : {'visibility': 'hidden'} "
                                                    [ngClass]="{'edit-point-edit': editIncrementalCurrency[i]}"
                                                    *ngIf = "!isMMUser && userCapabilities > -1 && validForDeactivation && ( program?.isNew || (!program?.isNew && program?.draft)) && ((benefit?.incrementalCurrency))" 
                                                    (click) = "editIncrementalCurrency[i]=!editIncrementalCurrency[i]; editMaxSelection(benefit, editIncrementalCurrency[i]); ">
                                                    <mat-icon tabindex="0">edit</mat-icon>
                                                </button>
                                                <mat-menu #pointMenu="matMenu" xPosition="before" overlapTrigger="true" 
                                                    (closed) = "$event == undefined && (pointVal.dirty || pointVal.touched) ? benefit.points = points : null; closePointMenu(benefit); points = null;editMultiplicity[i] = false;editIncremental[i] = false">
                                                    <div tabindex="0" (keydown)="$event.stopPropagation()">
                                                    <button   (keydown.arrowDown)="focusNextItem($event)" tabindex="0" mat-menu-item *ngIf = "!isMMUser && !(benefit?.multiplicity || benefit?.incremental || benefit?.incrementalCurrency) && validForDeactivation" (click) = "benefit.points = 'Guaranteed'">Core</button>
                                                    <input (keydown.arrowUp)="focusPreviousItem($event)" mat-menu-item matInput ngDefaultControl number-only tabindex="0" number required placeholder="Enter Points" name="pointVal" id="pointVal_{{i}}" [(ngModel)]="points" #pointVal="ngModel" class="form-control input-point"
                                                    (click)="$event.stopPropagation()">
                                                    </div>
                                                </mat-menu>
                                                </h3>
                                            </div>
                                            <div fxFlex="auto" *ngIf = "benefit?.multiplicity || benefit?.incremental || benefit?.advCashOutV2 || benefit?.advCashOut || benefit?.incrementalCurrency">
                                                <h5>Max Selections</h5>
                                                <h3 fxLayout = "row" tabindex="0" [ngStyle]= "benefit?.incremental ? benefit.maxSelection == 0 ? {'color': '#f00'} : {'color': '#424242'} : benefit?.incrementalCurrency ? (benefit?.incrementalCurrency && (benefit?.rangeMax / benefit?.rangeIncrementValue === 0)) ? {'color': '#f00'} : {'color': '#424242'} : (!benefit.maxSelection || benefit.maxSelection == 0) ? {'color': '#f00'} : {'color': '#424242'}">   
                                                    <span fxFlex="80%" *ngIf = "!(editMultiplicity[i] || editIncremental[i] || editIncrementalCurrency[i]) && validForDeactivation"> {{  (benefit.maxSelection || benefit.maxSelection ===0) ? benefit.maxSelection : benefit?.incremental || benefit.incrementalCurrency ? (benefit?.rangeMax / benefit?.rangeIncrementValue === 0 || benefit?.rangeIncrementValue === '' || benefit?.rangeIncrementValue === "0" ? '' : benefit?.rangeMax / benefit?.rangeIncrementValue) : (benefit?.advCashOutV2 || benefit?.advCashOut) ? 'N/A':''}} </span>
                                                    <input type="number" ngDefaultControl matInput number-only number tabindex="0" fxflex="80%" name="maxSelection" id="maxSelection_{{i}}" #maxSelection = "ngModel"
                                                    [(ngModel)]="benefit.maxSelection" *ngIf="editMultiplicity[i] || editIncremental[i] || editIncrementalCurrency[i]" (focusout) = "editMultiplicity[i] = false;editIncremental[i] = false; editPoint[i] = false; editIncrementalCurrency[i] = false; closePointMenu(benefit); benefit?.incrementalCurrency? editMaxSelection(benefit, editIncrementalCurrency[i]): null"  class="form-control ">
                                                    
                                                </h3>
                                            </div>
                                        </div>
    
                                    </div>
                                    <div fxLayout="row" role="cell"
                                      [attr.aria-label]="'Description: ' + benefit.description">
                                      <div fxFlex="100" class="description-container"> <!--(mouseover)="editDesc[i] = true" (mouseout)="editDesc[i] = false"-->
                                        <h5>Description</h5>
                                        <p fxLayout="row" tabindex="0">
                                          <span fxflex="80%" *ngIf="!benefit.editDescription">{{benefit.description}}</span>
                                          <textarea matInput ngDefaultControl tabindex="0" fxflex="80%" name="descVal" id="descVal_{{i}}"
                                            [(ngModel)]="benefit.description" #descVal="ngModel" *ngIf="benefit.editDescription && validForDeactivation"
                                            class="form-control" [class.tn-focus]='edit-point-edit'></textarea>
                                          <button fxFlex="20%" tabindex="0"  mat-icon-button aria-label="edit description"
                                            title="edit description" class="edit-point"
                                            [ngClass]= "{'edit-point-edit': benefit.editDescription}"                                        
                                            *ngIf="((userCapabilities > -1) &&( (program?.isNew || program?.active) || (!program?.isNew && program?.draft)) && validForDeactivation) || benefit.isCloned"
                                            (click)="editProgramDescription(benefit)" (keydown.enter)="editProgramDescription(benefit)">
                                            <mat-icon>edit</mat-icon>
                                          </button>
                                        </p>
                                      </div>
                                    </div>
                                    <!--Lump Sum Attributes starts-->
                                    <div fxLayout="row" role="cell" *ngIf="isLumpSumEnabled && benefit.reference.includes(dynamicBenefitService.lumpSumBenefitPrefix)"
                                      [attr.aria-label]="'Description: ' + benefit.description" >
                                      <div fxFlex="100" class="description-container"> <!--(mouseover)="editDesc[i] = true" (mouseout)="editDesc[i] = false"-->
                                        <app-dynamic-benefit-upsert-form [isDraftProgram]="isDraftProgram" [diasbleDynamicForm]="diasbleDynamicForm"(formRequestPayload)="updateProductAttibutes($event)"  [productSpecification]="benefit.productSpecificationReference" (validForm)="checkDynamicFormIsValid($event)"></app-dynamic-benefit-upsert-form>
                                      </div>
                                    </div>
                                    <!--Lump Sum Attributes ends-->
                                    <div fxLayout="row" role="cell">
                                        <mat-checkbox fxFlex="50%" ngDefaultControl
                                            [disabled]="!validForDeactivation ||!(userCapabilities > -1)"
                                            [(ngModel)]="benefit.isDEIEnabled" 
                                            (change)="editSustanabilityDEI(benefit, 'isDEIEnabled')"
                                            *ngIf="benefit.hasOwnProperty('isDEIEnabled')">
                                            <span >Diversity, Equity & Inclusion</span></mat-checkbox>
                                        <mat-checkbox fxFlex="50%" ngDefaultControl
                                            [disabled]="!validForDeactivation ||!(userCapabilities > -1)"
                                            [(ngModel)]="benefit.isSustainabilityEnabled" 
                                            (change)="editSustanabilityDEI(benefit, 'isSustainabilityEnabled')"
                                            *ngIf="benefit.hasOwnProperty('isSustainabilityEnabled')">
                                            <span>Sustainability</span></mat-checkbox>
                                    </div>
                                </div>
                                <!-- Benefit Display logic start -->
                                <div fxFlex="45"  *ngIf="!benefit.isNewlyAddedRef" class="benefit-logic" (focus)="editDisplayLogic[i] = true" (focusout)="editDisplayLogic[i] = false" (mouseover) = "editDisplayLogic[i] = true" (mouseout) = "editDisplayLogic[i] = false" fxLayoutGap="4px" [ngStyle]= "benefit.suppress ? {'pointer-events': 'none'} : ''">
                                    <!-- benefit.exclusions?.length > 0 || benefit.prerequisites?.length > 0 ||  -->
                                    <div  fxLayoutAlign="start start" class="benefit-display-logic-header">
                                        <img src="../../../../../../assets/images/account_tree.svg" alt="Icon here" role="figure">
                                        <h3 fxFlex="60%"> Benefit Display Logic
                                            <button (click)="openDialog(benefit)" class = "edit-display-logic" 
                                            *ngIf="((userCapabilities > -1) && validForDeactivation && ( program?.isNew || (!program?.isNew && program?.draft)))"><mat-icon>edit</mat-icon> <span>Edit</span></button>
                                        </h3>                                     
                                        <mat-checkbox fxFlex="40%"  fxLayoutAlign="end end" ngDefaultControl
                                        [disabled]="!validForDeactivation ||!( (userCapabilities > -1) && (program?.draft || program?.isNew || program?.active))" 
                                        (click)="!validForDeactivation ||!( (userCapabilities > -1) && (program?.draft || program?.isNew || program?.active)) ? null : openConsultantModal(benefit)"  
                                        [(ngModel)] = "benefit.consultantBenefitInfo && benefit.consultantBenefitInfo.consultant " 
                                        *ngIf = "!benefit?.advCashOutV2 && !benefit?.advCashOut && !benefit?.incremental && !benefit?.hybridTempLiving && !benefit?.multiplicity && !(benefit?.points === 'Guaranteed') 
                                        ">
                                        Consultant-Only Benefit</mat-checkbox>
                                    </div>
                                    <div fxLayoutGap="4px" class="scopes benefit-display-logic-header">
                                        <mat-tab-group disableRipple="true">
                                            <mat-tab label="Restrictions">
                                                <app-benefit-restrictions-rules-tasks [benefit]="benefit" [type]="'restrictions'"></app-benefit-restrictions-rules-tasks>
                                            </mat-tab>
                                            <mat-tab label="Rules">
                                                <app-benefit-restrictions-rules-tasks [benefit]="benefit" [type]="'rules'"></app-benefit-restrictions-rules-tasks>
                                            </mat-tab>
                                            <mat-tab label="Tasks">
                                                <app-benefit-restrictions-rules-tasks [benefit]="benefit" [type]="'tasks'"></app-benefit-restrictions-rules-tasks>
                                            </mat-tab>
                                        </mat-tab-group>
                                    </div>
                                            
                                </div>
                                <!-- Benefit Display logic end -->
                                <div fxFlex="99" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" *ngIf="benefit.isNewlyAddedRef"
                                    class="isNewelyAdded2">
                                    <div >
                                        <h5 class="titleNewCard">Title</h5>
                                        <h3 class="displayNameNewCard">
                                            <span>{{ benefit.displayName || 'N/A' }}</span>
                                        </h3>
                                    </div>
                                    <div class="notIncludedMessage">
                                        Benefit Not Included
                                    </div>
                                    <div fxLayout="row">
                                        <mat-icon style="color: green;">error</mat-icon>
                                        <span style="padding: 0.2em;">Benefit recently added</span>
                                
                                    </div>
                                </div>
                            </div>
                        </mat-card-content>
                        </mat-card>
                        <div fxFlex="5" role="cell">
                            <button class="isNewelyAdded1" *ngIf="!benefit.clonedCopy && benefit.isNewlyAddedRef" mat-button matSuffix
                                aria-label="Delete Benefit" role="button" (click)="deleteisNewlyAdded(benefit)">
                                <mat-icon>delete_outline</mat-icon>
                            </button>
                            <mat-card *ngIf="!benefit.isNewlyAddedRef"
                                style="height: 65% !important;width:30% !important ;margin-top: 0% !important;">
                                <button *ngIf="!benefit.clonedCopy && !benefit.isNewlyAddedRef" mat-button matSuffix mat-icon-button
                                    aria-label="Delete Benefit"
                                    [ngStyle]="!benefit.clonedCopy ? {'padding-right': '14px','padding-top': '10px'} : null" role="button"
                                    [disabled]="(userCapabilities == -1 && !(program?.isNew || program?.draft || program?.active)) || userCapabilities == -1 || !validForDeactivation"
                                    (click)="deleteBenefit(benefit)">
                                    <mat-icon>{{ benefit.suppress ? 'delete_outline': 'delete' }}</mat-icon>
                                </button>
                                <button mat-button matSuffix mat-icon-button aria-label="Delete Benefit" role="button"
                                    *ngIf="benefit.clonedCopy"
                                    [ngStyle]="benefit.clonedCopy ? {'padding-right': '14px','padding-top': '10px'} : null"
                                    (click)="discardBenefit(benefit, j, i)">
                                    <mat-icon>clear</mat-icon>
                                </button>
                                    <br>
                                    <button *ngIf="(userCapabilities > -1 &&(program?.isNew || program?.draft ))&&!((!( 
                                        !benefit.clonedCopy && !benefit.suppress)) || !validForDeactivation) 
                                        && !benefit.isNewlyAddedRef" 
                                     [disabled] = "benefit.parentClonedCopy" mat-button matSuffix mat-icon-button  aria-label="Delete Benefit" [ngStyle]="!benefit.clonedCopy ? {'padding-right': '14px','padding-top': '10px'} : null" role="button" (click)="cloneBenefit(benefit, focusable)">
                                        <!-- <mat-icon>{{ benefit.suppress ? 'delete_outline': 'delete' }}</mat-icon> -->
                                        <mat-icon *ngIf="!benefit.clonedCopy">content_copy</mat-icon>
                                    </button>
                                    <br>
                                    <button *ngIf="userCapabilities > -1 && !benefit.isNewlyAddedRef && !benefit.suppress"  [disabled] = "benefit.parentClonedCopy" mat-button matSuffix mat-icon-button  aria-label="Delete Benefit" [ngStyle]="!benefit.clonedCopy ? {'padding-right': '14px','padding-top': '10px'} : null" role="button" (click)="benefitSetting(benefit, index)">
                                        <mat-icon *ngIf="!benefit.clonedCopy ">settings</mat-icon>
                                    </button>
                                    <button *ngIf="benefit.clonedCopy" mat-button matSuffix mat-icon-button  aria-label="Save Benefit" role="button" style="padding-right: 14px;padding-top: 10px;" (click)="cloneBenefit(benefit)" #focusable>
                                        <!-- <mat-icon>{{ benefit.suppress ? 'delete_outline': 'delete' }}</mat-icon> -->
                                        <mat-icon>done</mat-icon>
                                    </button>
                                    <button tabindex="0" mat-icon-button (click) = "sortCategories()"
                                        *ngIf = "(userRole === 'program-admin' || userRole === 'product-mgmt-admin') && ((program?.draft || program?.isNew)) && !benefit.suppress"
                                        [matMenuTriggerFor]="categoryMenu"
                                        ><mat-icon class="category-change">more_vert</mat-icon></button>
                                    <mat-menu #categoryMenu="matMenu" xPosition="before" overlapTrigger="true" class="categoryMenu"
                                        >
                                        <div tabindex="0">
                                            <button tabindex="0" mat-icon-button disabled class = 'default-category'
                                                *ngIf = "(userRole === 'program-admin' || userRole === 'product-mgmt-admin') && ((program?.draft || program?.isNew))"
                                            ><div>Send to</div> 
                                            <div fxLayout="row" fxLayoutAlign=" center" >
                                                <p>{{benefit.category}}</p>
                                                <mat-chip tabindex="0" class="matchip-css style-chip current-chip " color="#452D7C" selected>Current </mat-chip>
                                            </div>
                                            </button>
                                        </div>    
                                        <div tabindex="0" *ngFor = "let category of categoriesList">
                                            <button tabindex="0" mat-icon-button (click) = "changeCategory(benefit, category)"
                                        *ngIf = "(userRole === 'program-admin' || userRole === 'product-mgmt-admin') && ((program?.draft || program?.isNew)) && benefit.category != category"
                                            >{{category}}</button>
                                        </div>
                                    </mat-menu>
                                </mat-card> 
    
                    </div>  
                </ng-container>
            </li>
        </ul>
    </ng-container>
    <!--Unused benefits section-->
    <ng-container *ngIf = "(userRole === 'program-admin') && unusedBenefitsList.length > 0">
        <h3 [attr.aria-label]="'Unused: '">Unused Benefits: {{unusedBenefitsList.length}}</h3>
        <ul class="unused-list">
            <li *ngFor="let benefit of unusedBenefitsList; let i= index">  
                <div class="drag-placeholder" *cdkDragPlaceholder></div>
                <div *ngIf="(userCapabilities > -1) && (program?.isNew || program?.draft)" fxFlex="2" role="cell" class="drag-handle" >
                    <!-- <span class="dot-handler" cdkDragHandle>::</span> -->
                    <mat-icon class="dot-handler" cdkDragHandle>drag_indicator</mat-icon>
                </div> 
                <mat-card  tabindex="0" class="mat-elevation-z3 drag-card " fxFlex="calc(33%-25px)" fxFlex.sm="calc(50%-25px)" [ngClass]="{ 'consultant-only': benefit.consultantBenefitInfo?.consultant && benefit?.points !== 'Guaranteed' ,'isNewelyAdded':benefit.isNewlyAddedRef }" *ngIf = "benefit">
                    <mat-card-content>
                        <div tabindex="0" fxLayout="row" fxLayoutAlign.xs="center center" role="row" fxLayoutGap="15px">
                            <div tabindex="0" fxFlex="10" fxLayout="column" fxLayoutGap="10px" role="cell" class="img-icon">
                                <mat-chip tabindex="0" class="temp-chip templiving-logo" *ngIf = "benefit?.tempLiving">Supplier Orders <img src="../../../../../../assets/icons/icon_supplierOrders.svg" alt="Icon here" role="figure" class="icon-img"></mat-chip>
                                <mat-chip tabindex="0" class="temp-chip insured-logo" *ngIf = "benefit?.clientIsCartusInsured">Cartus Insured<img src="../../../../../../assets/icons/icon_cartusInsured.svg" alt="Icon here" role="figure" class="icon-img"></mat-chip>
                                <img tabindex="0" [src]="benefit.iconLink" alt="benefit" role="figure" class="mat-elevation-z3">
                                    <div tabindex="0" *ngIf="benefit?.hybridTempLiving" style="border: 0.2px solid gray;">
                                        <mat-chip tabindex="0" *ngIf="benefit?.incremental &&!isChipEditable[i] &&!benefit.isNewlyAddedRef " style="margin-bottom: 5px;"
                                            class="chip-style">
                                            <div tabindex="0" class="rangeIncrementUnit" [class.tn-focus]='edit-point-edit'>Core {{benefit?.rangeIncrementUnit}}
                                                Per
                                                Selection </div>
                                            <div tabindex="0" >{{benefit?.coreUnitsPerSelection}}</div>
                                            <button tabindex="0" mat-icon-button
                                                *ngIf="(userCapabilities > -1) && ((program.draft || program.isNew) && benefit?.incremental)"
                                                (click)="enabledChipToEdit(i,j); rangeIncrementValue = benefit.rangeIncrementValue">
                                                <!-- (click)="enabledChipToEdit(i,j)" -->
                                                <mat-icon tabindex="0">edit</mat-icon>
                                            </button>
                                        </mat-chip>
                                        <mat-chip tabindex="0" class="chip-style" (mouseover)="editRaneIncrementVal[i] = true"
                                            *ngIf="benefit?.incremental &&!isChipEditable[i] &&!benefit.isNewlyAddedRef " color="#452D7C" selected>
                                            <div tabindex="0" class="rangeIncrementUnit" [class.tn-focus]='edit-point-edit'>Flex {{ benefit?.rangeIncrementUnit
                                                }} Per
                                                Selection </div>
                                            <div tabindex="0"> {{ benefit?.rangeIncrementValue }}</div>
                                            <button tabindex="0" mat-icon-button
                                                *ngIf="(userCapabilities > -1) && ((program.draft || program.isNew) && benefit?.incremental)"
                                                (click)="enabledChipToEdit(i,j); rangeIncrementValue = benefit.rangeIncrementValue">
                                                <!-- (click)="enabledChipToEdit(i,j)" -->
                                    
                                                <mat-icon tabindex="0">edit</mat-icon>
                                            </button>
                                        </mat-chip>
                                        <div tabindex="0" class="chip-outer-box" *ngIf="benefit?.incremental && isChipEditable[i]">
                                            <div tabindex="0" class="timeframeOuter">
                                                <span class="timeframeSpan"> Timeframe </span>
                                                <button tabindex="0" mat-icon-button class="matIconStyle" (click)="closeHybrid(i, j)"
                                                    style="margin-right:10px">
                                                    <mat-icon>cancel</mat-icon>
                                                </button>
                                                <button tabindex="0" mat-icon-button class="matIconStyle"
                                                    (click)="closeRangeIncrementValueMenu(benefit, index); isChipEditable[i]=false;">
                                                    <mat-icon style="color: #3865f8;">check_circle</mat-icon>
                                                </button>
                                            </div>
                                            <div tabindex="0">
                                                <mat-form-field tabindex="0" appearance="fill" style="width: 100%;">
                                                    <mat-select tabindex="0" matNativeControl [formControl]="timeSpanValue" [(ngModel)]="benefit.rangeIncrementUnit"
                                                        name="timeFrame">
                                                        <mat-option tabindex="0" value="Days" selected="selected">Days</mat-option>
                                                        <mat-option tabindex="0" value="Months">Months</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                                <mat-chip tabindex="0" style="margin-top: -10px;" class="StyleChip">Core {{benefit.rangeIncrementUnit}} per selection
                                                    <input tabindex="0" type="number" number-only class="inputSelection" [(ngModel)]="benefit.coreUnitsPerSelection"
                                                        (keyup.backspace)="onMatChipKeyPressCore($event,benefit.coreUnitsPerSelection,j,i)">
                                                </mat-chip>
                                                <mat-chip tabindex="0" class="StyleChip">Flex {{benefit.rangeIncrementUnit}} per selection
                                                    <input type="number" class="inputSelection" number-only tabindex="0" required name="rangeIncrementVal"
                                                        id="rangeIncrementVal_{{i}}" [(ngModel)]="rangeIncrementValue" #rangeIncrementVal="ngModel"
                                                        (keyup.backspace)="onMatChipKeyPress($event,rangeIncrementValue,j,i)">
                                                </mat-chip>
                                            </div>
                                        </div>
                                    </div>
                                    <div tabindex="0" *ngIf="!benefit?.hybridTempLiving">
                                        <mat-chip tabindex="0" (mouseover)="editRaneIncrementVal[i] = true"
                                            *ngIf="benefit?.incremental &&!isChipEditable[i] &&!benefit.isNewlyAddedRef" color="#452D7C" selected>
                                            <div tabindex="0" class="rangeIncrementUnit" [class.tn-focus]='edit-point-edit'>{{ benefit?.rangeIncrementUnit }} Per
                                                Selection </div>
                                            <div tabindex="0"> {{ benefit?.rangeIncrementValue }}</div>
                                            <button tabindex="0" mat-icon-button class="edit-point" (focusout)="editRaneIncrementVal[i] = false"
                                                [ngStyle]="editRaneIncrementVal[i] ? {'visibility': 'visible'} : {'visibility': 'hidden'}"
                                                [ngClass]="{'edit-point-edit': editRaneIncrementVal[i]}"
                                                *ngIf="(userCapabilities > -1) && ((program?.draft || program?.isNew) && benefit?.incremental)"
                                                (click)="enabledChipToEdit(i,j); rangeIncrementValue = benefit.rangeIncrementValue">
                                                <mat-icon tabindex="0">edit</mat-icon>
                                            </button>
                                        </mat-chip>
                                        <mat-chip tabindex="0" class="chip-form style-chip " *ngIf="benefit?.incremental && isChipEditable[i]">
                                            <form tabindex="0" style="display: flex;flex-flow: row wrap;align-items: center;">
                                                <mat-form-field tabindex="0" style="width: 33%; font-size: 13px;" *ngIf="benefit?.incremental">
                                                    <mat-select tabindex="0" [formControl]="timeSpanValue" [(ngModel)]="benefit.rangeIncrementUnit" ngDefaultControl>
                                                        <mat-option tabindex="0" *ngFor="let data of timespanValues" style="font-size: 15px;" [value]="data"
                                                            name="timeSpanValue" class="form-control">
                                                            {{ data }}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                                <mat-form-field tabindex="0" [ngStyle]="benefit?.incremental?{'width': '10%','margin-left':'3%'} : {'width': '20%'}">
                                                    <input matInput number-only ngDefaultControl tabindex="0" number required name="rangeIncrementVal"
                                                        id="rangeIncrementVal_{{i}}" [(ngModel)]="rangeIncrementValue" #rangeIncrementVal="ngModel"
                                                        (keyup.backspace)="onMatChipKeyPress($event,rangeIncrementValue,j,i)">
                                                </mat-form-field>
                                                <button tabindex="0" mat-icon-button class="edit-point"
                                                    (focusout)="editRaneIncrementVal[i] = false; isChipEditable[i]=false;"
                                                    [ngStyle]="editRaneIncrementVal[i] ? {'visibility': 'visible'} : {'visibility': 'hidden'}"
                                                    [ngClass]="{'edit-point-edit': editRaneIncrementVal[i]}"
                                                    *ngIf="(userCapabilities > -1) && ((program?.draft || program?.isNew) && benefit?.incremental)"
                                                    (click)="closeRangeIncrementValueMenu(benefit, index); isChipEditable[i]=false;">
                                                    <mat-icon>edit</mat-icon>
                                                </button>
                                            </form>
                                        </mat-chip>
                                    </div>
                            <mat-chip tabindex="0" class="matchip-css style-chip " (mouseover) = "editPerPointCurrency[i] = true"  *ngIf="benefit?.incrementalCurrency &&!benefit.isNewlyAddedRef" color="#452D7C" selected><div class="dollarPerPointUnit" [class.tn-focus]='edit-point-edit'>Value Per Point </div><div class="dollar-text"> {{ benefit?.perPointCurrency }}</div> <div class="left-margin-style dollar-text">{{benefit?.rangeIncrementUnit}}</div>
                                <button tabindex="0" mat-icon-button class="edit-point" (focusout) = "editPerPointCurrency[i] = false; "
                                    [ngStyle]= "editPerPointCurrency[i] ? {'visibility': 'visible'} : {'visibility': 'hidden'}" [ngClass]="{'edit-point-edit': editPerPointCurrency[i]}"
                                    *ngIf = "(userCapabilities > -1) && ((program?.draft || program?.isNew) && benefit?.incrementalCurrency)"
                                    [matMenuTriggerFor]="perPointCurrencyMenu"
                                    (menuOpened)="openRangeIncrementValueMenu()"
                                    >
                                    <mat-icon tabindex="0">edit</mat-icon> 
                                </button>
                                <mat-menu tabindex="0" #rangeIncrementValueMenu="matMenu" xPosition="before" overlapTrigger="true"
                                (closed) = "$event == undefined && (rangeIncrementVal.dirty || rangeIncrementVal.touched) ? benefit.rangeIncrementValue = rangeIncrementValue : null; closeRangeIncrementValueMenu(benefit, index); rangeIncrementValue = null;editRaneIncrementVal[i] = false; onMenuClosed($event)">
                                    <input matInput number-only tabindex="0" ngDefaultControl number required placeholder="Enter Range Increment Value" name="rangeIncrementVal" id="rangeIncrementVal_{{i}}" [(ngModel)]="rangeIncrementValue" #rangeIncrementVal="ngModel" class="form-control input-point"
                                        (click)="$event.stopPropagation()">
                                </mat-menu>

                                <mat-menu #perPointCurrencyMenu="matMenu" xPosition="before" overlapTrigger="true"
                                    (closed) = "$event == undefined && (perPointCurrencyVal.dirty || perPointCurrencyVal.touched) ? benefit.perPointCurrency = perPointCurrency : null; closePerPointCurrencyMenu(benefit); perPointCurrency = null; editPerPointCurrency[i] = false;">
                                    <input matInput number-only tabindex="0" ngDefaultControl number required placeholder="Enter Per point Value" name="perPointCurrencyVal" id="perPointCurrencyVal_{{i}}" [(ngModel)]="perPointCurrency"  #perPointCurrencyVal="ngModel"  class="form-control input-point" 
                                        (click)="$event.stopPropagation();">
                                </mat-menu>
                            </mat-chip>

                            </div>
                            
                            <div *ngIf="!benefit.isNewlyAddedRef" fxFlex="45" role="cell" class="benefit-detail" [ngStyle]= "benefit.suppress ? {'pointer-events': 'none'} : ''">
                                <div fxLayout="row" fxLayoutGap="15px">                                   
                                    <div fxFlex="70" role="cell" [attr.aria-label]="'Benefit Title: ' + benefit.displayName" class="title-container">
                                        <h5>Title</h5>
                                        <h3 tabindex="0">                                       
                                        <span *ngIf="!benefit.editTitle">{{ benefit.displayName || 'N/A' }}</span>
                                        <input matInput tabindex="0" fxflex="80%" name="titleVal" id="titleVal_{{i}}" ngDefaultControl
                                            [(ngModel)]="benefit.displayName" #titleVal="ngModel" #title *ngIf="benefit.editTitle" class="form-control" 
                                            [class.tn-focus]='edit-point-edit'>                                
                                        <button fxFlex="20%" tabindex="0" mat-icon-button aria-label="edit title"
                                            title="edit title" class="edit-point"
                                            [ngClass]="{'edit-point-edit': benefit.editTitle}"
                                            *ngIf="((userCapabilities > -1) &&( (program?.isNew || program?.active) || (!program?.isNew && program?.draft)) && validForDeactivation)"
                                            (click)="editProgramTitle(benefit)" (keydown.enter)="editProgramTitle(benefit)">                                          
                                            <mat-icon>edit</mat-icon>
                                        </button>
                                        </h3>
                                    </div>

                                    <div fxFlex="30" role="cell" [attr.aria-label]="'Total Points: ' + benefit.points" *ngIf="!isMMUser"
                                        (mouseover) = "editPoint[i] = true;" fxLayout = "row" fxLayoutGap="1em" (mouseout) = "editPoint[i] = false">
                                        <div fxFlex="auto">
                                            <h5 *ngIf="!isMMUser">Points</h5>
                                            <h5 *ngIf="isMMUser"></h5>
                                            <h3 fxLayout = "row" tabindex="0" [ngStyle]= "!(pointVal.touched || pointVal.dirty) || benefit.points ? {'color': '#424242'} : {'color': '#f00'} "
                                        (focus) = "editPoint[i] = true; " >   
                                        <span *ngIf="benefit.singleSelectTier; else cashout">
                                            <span fxFlex="80%" (click) = "openPointsLogicModal(benefit)">Variables</span>
                                            <button fxFlex="20%" tabindex="0" (focusout) = "editPoint[i] = false" mat-icon-button class="edit-point" 
                                                    [ngStyle]= "editPoint[i] ? {'visibility': 'visible'} : {'visibility': 'hidden'}" (click) = "openPointsLogicModal(benefit)">
                                                    <mat-icon>edit</mat-icon> 
                                                </button>
                                            </span>
                                            <ng-template #cashout>
                                            <span fxFlex="80%"> {{  (benefit.points || (benefit.points ===0 && (!benefit?.advCashOutV2 && !benefit?.advCashOut) )) ? (benefit.points === 'Guaranteed'? 'Core': benefit.points): (benefit?.advCashOutV2 || benefit?.advCashOut) ? 'Logic':'' }} </span>
                                            <button fxFlex="20%" tabindex="0" (focusout) = "editPoint[i] = false" mat-icon-button class="edit-point" 
                                                    [ngStyle]= "editPoint[i] ? {'visibility': 'visible'} : {'visibility': 'hidden'}" [ngClass]="{'edit-point-edit': editMultiplicity[i] || editIncremental[i]}"
                                                    *ngIf = "userCapabilities > -1 && validForDeactivation && ( program.isNew || (!program.isNew && program.draft)) && !benefit?.advCashOutV2 && !benefit?.advCashOut && !benefit?.incremental && !benefit?.incrementalCurrency && !benefit?.multiplicity || (userCapabilities > -1 && ( program.isNew || (!program.isNew && program.draft)) && (benefit?.multiplicity && editMultiplicity[i]) || (benefit?.incremental && editIncremental[i]))" [matMenuTriggerFor]="pointMenu" (menuOpened)="openPointMenu()">
                                                    <mat-icon tabindex="0">edit</mat-icon> 
                                                </button>
    
                                                <!-- Advance point logic for editing -->
                                                <button fxFlex="20%" tabindex="0" (focusout) = "editPoint[i] = false" mat-icon-button class="edit-point" 
                                                    [ngStyle]= "editPoint[i] ? {'visibility': 'visible'} : {'visibility': 'hidden'}" [ngClass]="{'edit-point-edit': editMultiplicity[i] || editIncremental[i] }"
                                                    *ngIf = "userCapabilities > -1 && validForDeactivation && ( program.isNew || (!program.isNew && program.draft)) && (benefit?.advCashOut || benefit?.advCashOutV2)" (click) = "openPointsLogicModal(benefit)">
                                                    <mat-icon>edit</mat-icon> 
                                                </button>
    
                                                <!-- Advance point logic for view -->
                                                <button fxFlex="20%" tabindex="0" (focusout) = "editPoint[i] = false" mat-icon-button class="edit-point" 
                                                    [ngStyle]= "editPoint[i] ? {'visibility': 'visible'} : {'visibility': 'hidden'}" [ngClass]="{'edit-point-edit': editMultiplicity[i] || editIncremental[i] }"
                                                    *ngIf = "(userCapabilities > -1 && ( !program.isNew && !program.draft && userCapabilities > -1) )  && (benefit.advCashOut || benefit?.advCashOutV2)" (click) = "openPointsLogicModal(benefit)">
                                                    <mat-icon>remove_red_eye</mat-icon> 
                                                </button>
                                            </ng-template>

                                            <button fxFlex="20%" tabindex="0" (focusout) = "editPoint[i] = false; editMultiplicity[i] = false; editIncremental[i] = false;" mat-icon-button class="edit-point" 
                                                [ngStyle]= "editPoint[i] || (editMultiplicity[i] || editIncremental[i] ) ? {'visibility': 'visible'} : {'visibility': 'hidden'} " [ngClass]="{'edit-point-edit': editMultiplicity[i] || editIncremental[i] }"
                                                *ngIf = "(userCapabilities > -1) && validForDeactivation && ( program?.isNew || (!program?.isNew && program?.draft)) && ((benefit?.multiplicity && !editMultiplicity[i]) || (benefit?.incremental && !editIncremental[i]))" (click) = "editMultiplicity[i] = true;editIncremental[i] = true; editRangeMax(benefit); openPointMenu()">
                                                <mat-icon>edit</mat-icon> 
                                            </button>
                                            
                                            <button fxFlex="20%" tabindex="0"  mat-icon-button class="edit-point" (focusout) = "!editIncrementalCurrency[i] ? editPoint[i] = false: null;"
                                                [ngStyle]= "(editPoint[i] || editIncrementalCurrency[i] ) ? {'visibility': 'visible'} : {'visibility': 'hidden'} "
                                                [ngClass]="{'edit-point-edit': editIncrementalCurrency[i]}"
                                                *ngIf = "(userCapabilities > -1) && validForDeactivation && ( program?.isNew || (!program?.isNew && program?.draft)) && ((benefit?.incrementalCurrency))" 
                                                (click) = "editIncrementalCurrency[i]=!editIncrementalCurrency[i]; editMaxSelection(benefit, editIncrementalCurrency[i]); ">
                                                <mat-icon tabindex="0">edit</mat-icon>
                                            </button>
                                            <mat-menu  #pointMenu="matMenu" xPosition="before" overlapTrigger="true" 
                                                (closed) = "$event == undefined && (pointVal.dirty || pointVal.touched) ? benefit.points = points : null; closePointMenu(benefit); points = null;editMultiplicity[i] = false;editIncremental[i] = false">
                                                <div tabindex="0" (keydown)="$event.stopPropagation()">
                                                <button  (keydown.arrowDown)="focusNextItem($event)" tabindex="0" mat-menu-item *ngIf = "!(benefit?.multiplicity || benefit?.incremental || benefit?.incrementalCurrency) && validForDeactivation" (click) = "benefit.points = 'Guaranteed'">Core</button>
                                                <input  (keydown.arrowUp)="focusPreviousItem($event)" mat-menu-item matInput ngDefaultControl number-only tabindex="0" number required placeholder="Enter Points" name="pointVal" id="pointVal_{{i}}" [(ngModel)]="points" #pointVal="ngModel" class="form-control input-point"
                                                (click)="$event.stopPropagation()">
                                                </div>
                                            </mat-menu>
                                            </h3>
                                        </div>
                                        <span *ngIf="benefit.singleSelectTier; else maxSelection">
                                            <div fxFlex="auto">
                                                <h5># Choices</h5>
                                                <h3 class="select-edit-tier">   
                                                    <input type="number" readonly matInput number-only number tabindex="0" fxflex="80%" name="tierChoices" [value]="benefit?.tierValues?.length ? benefit?.tierValues?.length : 0">
                                                    <button fxFlex="100%" tabindex="0" (focusout) = "editPoint[i] = false" mat-icon-button class="edit-point" 
                                                    [ngStyle]= "editPoint[i] ? {'visibility': 'visible'} : {'visibility': 'hidden'}"  (click)="onChoiceIconClick(i)">
                                                    <mat-icon>remove_red_eye</mat-icon> 
                                                </button>
                                                <div class="selected-tier" *ngIf="visibleIndex === i">
                                                    <mat-card>
                                                        <div *ngFor="let tierValue of benefit.tierValues; let i=index">
                                                            <div>{{tierValue.value}}<span class="tier-value">{{tierValue.displayValue}}</span></div>
                                                            </div>
                                                        <div class="select-edit-option" (click) = "openPointsLogicModal(benefit)">Edit Option <span class="arrow-right-icon"><mat-icon>keyboard_arrow_right</mat-icon></span></div>
                                                        </mat-card>
                                                    </div>
                                                </h3>
                                                
                                            </div>
                                            </span>
                                            <ng-template #maxSelection>
                                        <div fxFlex="auto" *ngIf = "benefit?.multiplicity || benefit?.incremental || benefit?.advCashOutV2 || benefit?.advCashOut || benefit?.incrementalCurrency">
                                            <h5>Max Selections</h5>
                                            <h3 fxLayout = "row" tabindex="0" [ngStyle]= "benefit?.incremental ? benefit.maxSelection == 0 ? {'color': '#f00'} : {'color': '#424242'} : benefit?.incrementalCurrency ? (benefit?.incrementalCurrency && (benefit?.rangeMax / benefit?.rangeIncrementValue === 0)) ? {'color': '#f00'} : {'color': '#424242'} : (!benefit.maxSelection || benefit.maxSelection == 0) ? {'color': '#f00'} : {'color': '#424242'}">   
                                                <span fxFlex="80%" *ngIf = "!(editMultiplicity[i] || editIncremental[i] || editIncrementalCurrency[i]) && validForDeactivation"> {{  (benefit.maxSelection || benefit.maxSelection ===0) ? benefit.maxSelection : benefit?.incremental || benefit.incrementalCurrency ? (benefit?.rangeMax / benefit?.rangeIncrementValue === 0 || benefit?.rangeIncrementValue === '' || benefit?.rangeIncrementValue === "0" ? '' : benefit?.rangeMax / benefit?.rangeIncrementValue) : (benefit?.advCashOutV2 || benefit?.advCashOut) ? 'N/A':''}} </span>
                                                <input type="number" ngDefaultControl matInput number-only number tabindex="0" fxflex="80%" name="maxSelection" id="maxSelection_{{i}}" #maxSelection = "ngModel"
                                                [(ngModel)]="benefit.maxSelection" *ngIf="editMultiplicity[i] || editIncremental[i] || editIncrementalCurrency[i]" (focusout) = "editMultiplicity[i] = false;editIncremental[i] = false; editPoint[i] = false; editIncrementalCurrency[i] = false; closePointMenu(benefit); benefit?.incrementalCurrency? editMaxSelection(benefit, editIncrementalCurrency[i]): null"  class="form-control ">
                                                
                                            </h3>
                                        </div>
                                        </ng-template>
                                    </div>
                                    <div tabindex="0" fxFlex="30" role="cell" [attr.aria-label]="'Total Points: ' + benefit.points" *ngIf="isMMUser"
                                        (mouseover) = "editPoint[i] = true;" fxLayout = "row" fxLayoutGap="1em" (mouseout) = "editPoint[i] = false">
                                        <div tabindex="0" fxFlex="auto">
                                            <h5 *ngIf="!isMMUser">Points</h5>
                                            <h5 *ngIf="isMMUser" class="pointsHeader"></h5>
                                            <h3 fxLayout = "row" tabindex="0" [ngStyle]= "!(pointVal.touched || pointVal.dirty) || benefit.points ? {'color': '#424242'} : {'color': '#f00'} "
                                        (focus) = "editPoint[i] = true; " >   
                                        <span fxFlex="80%"> {{  (benefit?.amount === 'Guaranteed'? 'Core': benefit.amount)  }} </span>
                                        <button fxFlex="20%" tabindex="0" (focusout) = "editPoint[i] = false" mat-icon-button class="edit-point"
                                                [ngStyle]= "editPoint[i] ? {'visibility': 'visible'} : {'visibility': 'hidden'}" [ngClass]="{'edit-point-edit': editMultiplicity[i] || editIncremental[i]}"
                                                *ngIf = "!isMMUser && userCapabilities > -1 && validForDeactivation && ( program?.isNew || (!program?.isNew && program?.draft)) && !benefit?.advCashOut && !benefit?.incremental && !benefit?.incrementalCurrency && !benefit?.multiplicity || (userCapabilities > -1 && ( program?.isNew || (!program?.isNew && program?.draft)) && (benefit?.multiplicity && editMultiplicity[i]) || (benefit?.incremental && editIncremental[i]))" [matMenuTriggerFor]="pointMenu" (menuOpened)="openPointMenu()">
                                                <mat-icon tabindex="0">edit</mat-icon> 
                                            </button>

                                            <!-- Advance point logic for editing -->
                                            <button fxFlex="20%" tabindex="0" (focusout) = "editPoint[i] = false" mat-icon-button class="edit-point" 
                                                [ngStyle]= "editPoint[i] ? {'visibility': 'visible'} : {'visibility': 'hidden'}" [ngClass]="{'edit-point-edit': editMultiplicity[i] || editIncremental[i] }"
                                                *ngIf = "!isMMUser && userCapabilities > -1 && validForDeactivation && ( program?.isNew || (!program?.isNew && program?.draft)) && (benefit?.advCashOutV2 || benefit?.advCashOut)" (click) = "openPointsLogicModal(benefit)">
                                                <mat-icon>edit</mat-icon> 
                                            </button>

                                            <!-- Advance point logic for view -->
                                            <button fxFlex="20%" tabindex="0" (focusout) = "editPoint[i] = false" mat-icon-button class="edit-point" 
                                                [ngStyle]= "editPoint[i] ? {'visibility': 'visible'} : {'visibility': 'hidden'}" [ngClass]="{'edit-point-edit': editMultiplicity[i] || editIncremental[i] }"
                                                *ngIf = "!isMMUser && (userCapabilities > -1 || ( !program?.isNew && !program?.draft && userCapabilities > -1) )  && (benefit?.advCashOutV2 || benefit?.advCashOut)" (click) = "openPointsLogicModal(benefit)">
                                                <mat-icon>remove_red_eye</mat-icon> 
                                            </button>

                                            <button fxFlex="20%" tabindex="0" (focusout) = "editPoint[i] = false; editMultiplicity[i] = false; editIncremental[i] = false;" mat-icon-button class="edit-point" 
                                                [ngStyle]= "editPoint[i] || (editMultiplicity[i] || editIncremental[i] ) ? {'visibility': 'visible'} : {'visibility': 'hidden'} " [ngClass]="{'edit-point-edit': editMultiplicity[i] || editIncremental[i] }"
                                                *ngIf = "!isMMUser && userCapabilities > -1 && validForDeactivation && ( program?.isNew || (!program?.isNew && program?.draft)) && ((benefit?.multiplicity && !editMultiplicity[i]) || (benefit?.incremental && !editIncremental[i]))" (click) = "editMultiplicity[i] = true;editIncremental[i] = true; editRangeMax(benefit); openPointMenu()">
                                                <mat-icon tabindex="0">edit</mat-icon> 
                                            </button>
                                            
                                            <button fxFlex="20%" tabindex="0"  mat-icon-button class="edit-point" (focusout) = "!editIncrementalCurrency[i] ? editPoint[i] = false: null;"
                                                [ngStyle]= "(editPoint[i] || editIncrementalCurrency[i] ) ? {'visibility': 'visible'} : {'visibility': 'hidden'} "
                                                [ngClass]="{'edit-point-edit': editIncrementalCurrency[i]}"
                                                *ngIf = "!isMMUser && userCapabilities > -1 && validForDeactivation && ( program?.isNew || (!program?.isNew && program?.draft)) && ((benefit?.incrementalCurrency))" 
                                                (click) = "editIncrementalCurrency[i]=!editIncrementalCurrency[i]; editMaxSelection(benefit, editIncrementalCurrency[i]); ">
                                                <mat-icon tabindex="0">edit</mat-icon>
                                            </button>
                                            <mat-menu  #pointMenu="matMenu" xPosition="before" overlapTrigger="false" 
                                                (closed) = "$event == undefined && (pointVal.dirty || pointVal.touched) ? benefit.points = points : null; closePointMenu(benefit); points = null;editMultiplicity[i] = false;editIncremental[i] = false">
                                                <div tabindex="0" (keydown)="$event.stopPropagation()">
                                                <button (keydown.arrowDown)="focusNextItem($event)" mat-menu-item tabindex="0" mat-menu-item *ngIf = "!isMMUser && !(benefit?.multiplicity || benefit?.incremental || benefit?.incrementalCurrency) && validForDeactivation" (click) = "benefit.points = 'Guaranteed'">Core</button>
                                                <input (keydown.arrowUp)="focusPreviousItem($event)" mat-menu-item matInput ngDefaultControl number-only tabindex="0" number required placeholder="Enter Points" name="pointVal" id="pointVal_{{i}}" [(ngModel)]="points" #pointVal="ngModel" class="form-control input-point"
                                                (click)="$event.stopPropagation()">
                                            </div>
                                            </mat-menu>
                                            </h3>
                                        </div>
                                        <div fxFlex="auto" *ngIf = "benefit?.multiplicity || benefit?.incremental || benefit?.advCashOutV2 || benefit?.advCashOut || benefit?.incrementalCurrency">
                                            <h5>Max Selections</h5>
                                            <h3 fxLayout = "row" tabindex="0" [ngStyle]= "benefit?.incremental ? benefit.maxSelection == 0 ? {'color': '#f00'} : {'color': '#424242'} : benefit?.incrementalCurrency ? (benefit?.incrementalCurrency && (benefit?.rangeMax / benefit?.rangeIncrementValue === 0)) ? {'color': '#f00'} : {'color': '#424242'} : (!benefit.maxSelection || benefit.maxSelection == 0) ? {'color': '#f00'} : {'color': '#424242'}">   
                                                <span fxFlex="80%" *ngIf = "!(editMultiplicity[i] || editIncremental[i] || editIncrementalCurrency[i]) && validForDeactivation"> {{  (benefit.maxSelection || benefit.maxSelection ===0) ? benefit.maxSelection : benefit?.incremental || benefit.incrementalCurrency ? (benefit?.rangeMax / benefit?.rangeIncrementValue === 0 || benefit?.rangeIncrementValue === '' || benefit?.rangeIncrementValue === "0" ? '' : benefit?.rangeMax / benefit?.rangeIncrementValue) : (benefit?.advCashOutV2 || benefit?.advCashOut) ? 'N/A':''}} </span>
                                                <input type="number" ngDefaultControl matInput number-only number tabindex="0" fxflex="80%" name="maxSelection" id="maxSelection_{{i}}" #maxSelection = "ngModel"
                                                [(ngModel)]="benefit.maxSelection" *ngIf="editMultiplicity[i] || editIncremental[i] || editIncrementalCurrency[i]" (focusout) = "editMultiplicity[i] = false;editIncremental[i] = false; editPoint[i] = false; editIncrementalCurrency[i] = false; closePointMenu(benefit); benefit?.incrementalCurrency? editMaxSelection(benefit, editIncrementalCurrency[i]): null"  class="form-control ">
                                                
                                            </h3>
                                        </div>
                                    </div>

                                </div>
                                <div fxLayout="row" role="cell"
                                    [attr.aria-label]="'Description: ' + benefit.description">
                                    <div fxFlex="100" class="description-container"> <!--(mouseover)="editDesc[i] = true" (mouseout)="editDesc[i] = false"-->
                                    <h5>Description</h5>
                                    <p fxLayout="row" tabindex="0">
                                        <span fxflex="80%" *ngIf="!benefit.editDescription">{{benefit.description}}</span>
                                        <textarea matInput ngDefaultControl tabindex="0" fxflex="80%" name="descVal" id="descVal_{{i}}"
                                        [(ngModel)]="benefit.description" #descVal="ngModel" *ngIf="benefit.editDescription && validForDeactivation"
                                        class="form-control" [class.tn-focus]='edit-point-edit'></textarea>
                                        <button fxFlex="20%" tabindex="0"  mat-icon-button aria-label="edit description"
                                        title="edit description" class="edit-point"
                                        [ngClass]= "{'edit-point-edit': benefit.editDescription}"                                        
                                        *ngIf="((userCapabilities > -1) &&( (program?.isNew || program?.active) || (!program?.isNew && program?.draft)) && validForDeactivation) || benefit.isCloned"
                                        (click)="editProgramDescription(benefit)" (keydown.enter)="editProgramDescription(benefit)">
                                        <mat-icon>edit</mat-icon>
                                        </button>
                                    </p>
                                    </div>
                                </div>
                                <div fxLayout="row" role="cell">
                                    <mat-checkbox fxFlex="50%" ngDefaultControl
                                        [disabled]="!validForDeactivation ||!(userCapabilities > -1)"
                                        [(ngModel)]="benefit.isDEIEnabled" 
                                        (change)="editSustanabilityDEI(benefit, 'isDEIEnabled')"
                                        *ngIf="benefit.hasOwnProperty('isDEIEnabled')">
                                        <span >Diversity, Equity & Inclusion</span></mat-checkbox>
                                    <mat-checkbox fxFlex="50%" ngDefaultControl
                                        [disabled]="!validForDeactivation ||!(userCapabilities > -1)"
                                        [(ngModel)]="benefit.isSustainabilityEnabled" 
                                        (change)="editSustanabilityDEI(benefit, 'isSustainabilityEnabled')"
                                        *ngIf="benefit.hasOwnProperty('isSustainabilityEnabled')">
                                        <span>Sustainability</span></mat-checkbox>
                                </div>
                                
                            </div>
                            <!-- Benefit Display logic start -->
                            <div fxFlex="45"  *ngIf="!benefit.isNewlyAddedRef" class="benefit-logic" (focus)="editDisplayLogic[i] = true" (focusout)="editDisplayLogic[i] = false" (mouseover) = "editDisplayLogic[i] = true" (mouseout) = "editDisplayLogic[i] = false" fxLayoutGap="4px" [ngStyle]= "benefit.suppress ? {'pointer-events': 'none'} : ''">
                                <!-- benefit.exclusions?.length > 0 || benefit.prerequisites?.length > 0 ||  -->
                                <div  fxLayoutAlign="start start" class="benefit-display-logic-header">
                                    <img src="../../../../../../assets/images/account_tree.svg" alt="Icon here" role="figure">
                                    <h3 fxFlex="60%"> Benefit Display Logic </h3>               
                                    <mat-checkbox fxFlex="40%"  fxLayoutAlign="end end" ngDefaultControl
                                    [disabled]="true" 
                                    (click)="!validForDeactivation ||!( (userCapabilities > -1) && (program?.draft || program?.isNew || program?.active)) ? null : openConsultantModal(benefit)"  
                                    [(ngModel)] = "benefit.consultantBenefitInfo && benefit.consultantBenefitInfo.consultant " 
                                    *ngIf = "!benefit?.advCashOutV2 && !benefit?.advCashOut && !benefit?.incremental && !benefit?.hybridTempLiving && !benefit?.multiplicity && !(benefit?.points === 'Guaranteed') 
                                    ">
                                    Consultant-Only Benefit</mat-checkbox>
                                </div>
                                <div fxLayoutGap="4px" class="scopes benefit-display-logic-header">
                                    <mat-tab-group disableRipple="true">
                                        <mat-tab label="Restrictions">
                                            <app-benefit-restrictions-rules-tasks [benefit]="benefit" [type]="'restrictions'"></app-benefit-restrictions-rules-tasks>
                                        </mat-tab>
                                        <mat-tab label="Rules" [disabled]="!benefit.exclusions?.length && !benefit.prerequisiteRule?.length && benefit.includeRule?.length">
                                            <app-benefit-restrictions-rules-tasks [benefit]="benefit" [type]="'rules'"></app-benefit-restrictions-rules-tasks>
                                        </mat-tab>
                                        <mat-tab label="Tasks">
                                            <app-benefit-restrictions-rules-tasks [benefit]="benefit" [type]="'tasks'"></app-benefit-restrictions-rules-tasks>
                                        </mat-tab>
                                    </mat-tab-group>
                                </div>
                                        
                            </div>
                            <!-- Benefit Display logic end -->
                        </div>
                    </mat-card-content>
                    </mat-card>
                    <div fxFlex="5" role="cell">
                        <mat-card *ngIf="!benefit.isNewlyAddedRef"
                            style="height: 65% !important;width:30% !important ;margin-top: 0% !important;">
                            <button *ngIf="!benefit.clonedCopy && !benefit.isNewlyAddedRef" mat-button matSuffix mat-icon-button
                                aria-label="Delete Benefit"
                                [ngStyle]="!benefit.clonedCopy ? {'padding-right': '14px','padding-top': '10px'} : null" role="button"
                                [disabled]="(userCapabilities == -1 && !(program?.isNew || program?.draft || program?.active)) || userCapabilities == -1 || !validForDeactivation"
                                (click)="deleteFromUnusedBenefit(benefit)">
                                <!-- <mat-icon>{{ benefit.suppress ? 'delete_outline': 'delete' }}</mat-icon> -->
                                <img class="restore_icon" src="../../../../../../assets/images/restore.svg" alt="Restore Icon" role="figure">
                            </button>
                            <button mat-button matSuffix mat-icon-button aria-label="Delete Benefit" role="button"
                                *ngIf="benefit.clonedCopy"
                                [ngStyle]="benefit.clonedCopy ? {'padding-right': '14px','padding-top': '10px'} : null"
                                (click)="discardBenefit(benefit, j, i)">
                                <mat-icon>clear</mat-icon>
                            </button>
                                <br>
                                <button *ngIf="(userCapabilities > -1 &&(program?.isNew || program?.draft ))&&!((!( 
                                    !benefit.clonedCopy && !benefit.suppress)) || !validForDeactivation) 
                                    && !benefit.isNewlyAddedRef" 
                                    [disabled] = "benefit.parentClonedCopy" mat-button matSuffix mat-icon-button  aria-label="Delete Benefit" [ngStyle]="!benefit.clonedCopy ? {'padding-right': '14px','padding-top': '10px'} : null" role="button" (click)="cloneBenefit(benefit, focusable)">
                                    <!-- <mat-icon>{{ benefit.suppress ? 'delete_outline': 'delete' }}</mat-icon> -->
                                    <mat-icon *ngIf="!benefit.clonedCopy">content_copy</mat-icon>
                                </button>
                                <br>
                                <button *ngIf="userCapabilities > -1 && !benefit.isNewlyAddedRef && !benefit.suppress"  [disabled] = "benefit.parentClonedCopy" mat-button matSuffix mat-icon-button  aria-label="Delete Benefit" [ngStyle]="!benefit.clonedCopy ? {'padding-right': '14px','padding-top': '10px'} : null" role="button" (click)="benefitSetting(benefit, index)">
                                    <mat-icon *ngIf="!benefit.clonedCopy ">settings</mat-icon>
                                </button>
                                <button *ngIf="benefit.clonedCopy" mat-button matSuffix mat-icon-button  aria-label="Save Benefit" role="button" style="padding-right: 14px;padding-top: 10px;" (click)="cloneBenefit(benefit)" #focusable>
                                    <!-- <mat-icon>{{ benefit.suppress ? 'delete_outline': 'delete' }}</mat-icon> -->
                                    <mat-icon>done</mat-icon>
                                </button>
                                <button tabindex="0" mat-icon-button (click) = "sortCategories()"
                                    *ngIf = "(userRole === 'program-admin' || userRole === 'product-mgmt-admin') && ((program?.draft || program?.isNew)) && !benefit.suppress"
                                    [matMenuTriggerFor]="categoryMenu"
                                    ><mat-icon class="category-change">more_vert</mat-icon></button>
                                <mat-menu tabindex="0" #categoryMenu="matMenu" xPosition="before" overlapTrigger="true" class="categoryMenu"
                                    >
                                    <div>
                                        <button tabindex="0" mat-icon-button disabled class = 'default-category'
                                            *ngIf = "(userRole === 'program-admin' || userRole === 'product-mgmt-admin') && ((program?.draft || program?.isNew))"
                                        ><div>Send to</div> 
                                        <div tabindex="0" fxLayout="row" fxLayoutAlign=" center" >
                                            <p tabindex="0">{{benefit.category}}</p>
                                            <mat-chip tabindex="0" class="matchip-css style-chip current-chip " color="#452D7C" selected>Current </mat-chip>
                                        </div>
                                        </button>
                                    </div>    
                                    <div tabindex="0" *ngFor = "let category of categoriesList">
                                        <button tabindex="0" mat-icon-button (click) = "changeCategory(benefit, category)"
                                    *ngIf = "(userRole === 'program-admin' || userRole === 'product-mgmt-admin') && ((program?.draft || program?.isNew)) && benefit.category != category"
                                        >{{category}}</button>
                                    </div>
                                </mat-menu>
                            </mat-card> 

                </div>  
            </li>
        </ul>
    </ng-container>
</div>

<div class="actions">
    <button [disabled]="!validForDeactivation"  *ngIf="!(program?.draft && !program?.isNew) && !program?.isNew && (userCapabilities > -1)" class="mat-button publish-btn contained-button" (click)="saveCopy()" role="button" aria-label="Copy Program" >Copy Program</button>
    <button 
    style="margin-right:0px;"
    *ngIf="((program?.draft && !program?.isNew) || program?.isNew) && (userCapabilities > -1)"
    [disabled]="(program?.draft && draftDisable) || (program?.isNew && publishDisable) || !validForDeactivation || publishDisable || isInitialcontactCheck || CloneCopyDisable" 
    mat-button 
    ole="button" 
    aria-label="Save Draft"
     (click)="draftProgram()"
    class="text-button main-btn"
    >Save Draft
   </button>
   <button *ngIf="((program?.draft && !program?.isNew) || program?.isNew) && (userCapabilities > -1)" [disabled]="(program?.isNew && publishDisable) || (userCapabilities == -1) || !validForDeactivation"  class="copy-program-menu"   mat-icon-button  [matMenuTriggerFor] = "copyProgramMenu"> <mat-icon class="add-arrow-icon" aria-label="more options">arrow_drop_down</mat-icon></button>
    <mat-menu #copyProgramMenu="matMenu" xPosition="before">
        <button 
        mat-menu-item 
        ole="button" 
        aria-label="Copy Program"
         (click)="saveCopyAndDraft()"
        class="text-button copy-btn"
        >Save & Copy Program</button>
    </mat-menu>
  <button *ngIf="(userCapabilities > -1 && program?.active)" [disabled]="(program?.draft && draftDisable) || (program?.isNew && publishDisable) || !validForDeactivation || publishDisable || isInitialcontactCheck" class="mat-button publish-btn contained-button" role="button" aria-label="Save Program" (click)="draftProgram()">Save Program Updates</button>
  <button *ngIf="(userCapabilities > -1 && !program?.active)" [disabled]="(!program?.isNew && !program?.draft) || (publishDisable) || CloneCopyDisable || !validForDeactivation || isInitialcontactCheck" class="mat-button publish-btn contained-button" role="button" aria-label="Publish for Use" (click)="publishProgram()">Publish for Use</button>
  <button *ngIf="(userCapabilities > -1)"[disabled]="!validForDeactivation || (validForDeactivation && program?.isNew)" class="mat-button publish-btn contained-button" role="button" aria-label="Deactivate Program" (click)="deactivateProgram()">Deactivate Program</button>
  <button *ngIf="((userCapabilities > -1) && program?.draft)"[disabled]="!validForDeactivation || (validForDeactivation && program?.isNew)" class="mat-button publish-btn contained-button" role="button" aria-label="Deactivate Program" (click)="deleteProgram()">Delete Program</button>
</div>  