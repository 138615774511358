import { ClientScreenApplicationAccessService, clientApplicationAccessData } from '../../administration/services/client-screen-application-access.service';
import { Component, OnInit, Output, EventEmitter, Input, ChangeDetectorRef, Inject } from '@angular/core';
import { Client } from '../../../core/models/client.model';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ClientScreenSaveChangesComponent } from '../client-screen-save-changes/client-screen-save-changes.component';
import { UserTypeService } from '../../administration/services/user-type.service';
import { FeatureFlag, FeatureFlagService } from '../../../core/services/feature-flag.service';
@Component({
  selector: 'app-client-screen',
  templateUrl: './client-screen.component.html',
  styleUrls: ['./client-screen.component.scss']
})
export class ClientScreenComponent implements OnInit {

  /**configurable fields for the client screen */
  clientApplicationData: clientApplicationAccessData = {};
  applicationAccessData: any = {};
  moveProData: any = {};
  moveProCollabBindingData: any = {};
  isLoaded: boolean = false;
  selectedBBOption: string;
  expanded: boolean = false;
  collabBindingData = [];
  fieldPropertyNameArray = {};
  checkBoxSelectedvalue = [];
  checkBoxSelectedvalueByPropertName = {};
  accessAplicationPayload: any = {};
  clientSsoData: clientApplicationAccessData = {};
  ssoAceessData: any = {};
  ssosetUpData: any = {};
  isSSOLoad: boolean = false;
  ssoCollabBindingData: any = {};
  /** client value passed from the parent component*/
  @Input() client: Client;
  /** backToClients to send a flag, to getClients from the parent component*/
  @Output() backToClients = new EventEmitter();
  metaSchemaData: any = [];
  roleDetailsData: any = [];
  ssoSetUpForm: UntypedFormGroup;
  applicationAccessForm: UntypedFormGroup;
  rolesData: any;
  clientSSOConfigurationCap: any;
  clientApplicationAccessCap: any;
  noCapBloclMessage = `You do not have access to this page. Please contact your Administrator`;
  Id: any;
  ssoRoleDetailsId: string;
  isLumpSumEnabled: boolean = false;

  constructor(private clientScreenService: ClientScreenApplicationAccessService,
    private changeDetector: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private userTypeService: UserTypeService,
    public featureFlagService: FeatureFlagService
  ) {
  }

  ngOnInit() {
    this.getFeatureFlag();
    this.getSchemaAndData();
    this.getCapabilities();
  }

  getSchemaAndData() {
    let observablesArray = []
    observablesArray.push(this.clientScreenService.getClientScreenApplicationAcces());
    observablesArray.push(this.clientScreenService.getClientScreenAppRoleDetails(this.client.clientPartyId, this.client.clientName))
    forkJoin(observablesArray).subscribe(([schema, data]: any) => {
      this.rolesData = data;
      this.transformData(schema);
    });
  }

  // CUST-348 Hides the Managed Move benefit type on UI only, new Clients will no longer be able to select Managed Move.
  // Existing Managed Move Clients will still have have Managed Move selected but they won't see the option anymore.
  // If Managed Move needs to be removed permanently it should be done as a dba script.
  removeManagedMoveOption(scheme: clientApplicationAccessData): clientApplicationAccessData {
    const applicationAccess = (scheme.metaSchema || []).find(schema => schema.propertyName === 'applicationAccess');
    if (applicationAccess) {
      const moveProSchema = (applicationAccess['metaSchema'] || []).find(schema => schema.propertyName === 'movePro360');
      if (moveProSchema) {
        const benefitsBuilder = (moveProSchema['metaSchema'] || []).find(metaSchema => metaSchema.propertyName === 'benefitsBuilder');
        if (benefitsBuilder) {
          const benefitBuilderType = (benefitsBuilder['metaSchema'] || []).find(metaSchema => metaSchema.propertyName === "benefitsBuilderType");  
          if (benefitBuilderType) {
            benefitBuilderType.values = (benefitBuilderType.values || []).filter(value => value !== 'Managed Move');
          }
        }
      }
    }

    return scheme
  }

  /**
   * Function to transform the response to structure for binding
   * @param resp 
   */
  transformData(resp) {
    if (resp && resp.length > 0) {
      resp.forEach((element) => {
        if (element.name === 'client-application-access') {
          this.clientApplicationData = this.removeManagedMoveOption(element);
          if (this.clientApplicationData && this.clientApplicationData !== undefined) {
            this.applicationAccessData = this.structureMetaschema(this.clientApplicationData, 'applicationAccess', 'property', 'Object');
            if (this.applicationAccessData && this.applicationAccessData !== undefined) {
              this.moveProData = this.structureMetaschema(this.applicationAccessData, 'movePro360', 'property', 'Object');
              if (this.moveProData && this.moveProData !== undefined) {
                this.moveProCollabBindingData = this.structureMetaschema(this.moveProData, 'movePro360Enabled', 'property', 'Boolean');
                this.getCollabBindingData(this.moveProData);
                this.changeDetector.detectChanges();
              }
            }
          }
        } else if (element.name === 'client-sso-setup') {
          this.clientSsoData = element;
          if (this.clientSsoData && this.clientSsoData !== undefined) {
            this.ssoAceessData = this.structureMetaschema(this.clientSsoData, 'ssoSetup', 'property', 'Object');
            if (this.ssoAceessData && this.ssoAceessData !== undefined) {
              // this.ssosetUpData = this.structureMetaschema(this.ssoAceessData, 'ssoSetup','property','Object');
              this.ssosetUpData = this.structureSSOMetaSchema(this.ssoAceessData)
              if (this.ssosetUpData && this.ssosetUpData !== undefined) {
                this.ssoCollabBindingData = this.structureMetaschema(this.ssoAceessData, 'ssoEnabled', 'property', 'Boolean');
                // this.isSSOLoad = true;
                this.changeDetector.detectChanges();
                this.generateFormGrp();
              }
            }
          }
        }
      });
      this.spinner.hide();
    }
  }
  structureMetaschema(data, propertyName, fieldType = undefined, dataType) {
    let metaData = {};
    data.metaSchema.forEach((element) => {
      if (element.propertyName === propertyName && element.fieldType === fieldType && element.dataType === dataType) {
        metaData = element;
      }
    });
    return metaData;
  }
  structureSSOMetaSchema(data) {
    let metaData = [];
    data.metaSchema.forEach(ele => {
      if (ele.dataType !== "Boolean") {
        metaData.push(ele);
      }
    });
    return metaData;
  }
  /**
   * Function to get data for binding inner collabs and fields in it
   * @param data 
   */
  getCollabBindingData(data) {
    this.fieldPropertyNameArray = {};
    if (!this.isLumpSumEnabled) {
      const lumpSumIndex = data.metaSchema.findIndex(item => item.propertyName === 'lumpSum');
      if (lumpSumIndex != -1) {
        delete data.metaSchema[lumpSumIndex];
      }
    }
    data.metaSchema.forEach(Ele => {
      if (Ele._structureType === 'nested') {
        let radioIterator = 0;
        Ele.metaSchema.forEach(ele => {
          if (ele.dataType === 'Boolean') {
            Ele.field0 = 'Boolean';
            Ele.field0_propertyName = ele.propertyName;
            this.fieldPropertyNameArray[ele.propertyName] = false;
          } else if (ele.dataType === 'String' && ele.selectionType === 'single' && ele.values && radioIterator === 0) {
            radioIterator++;
            Ele.field1 = 'Radio';
            Ele.field1_Values = ele.values;
            Ele.field1_propertyName = ele.propertyName;
            this.fieldPropertyNameArray[ele.propertyName] = ele.values[0];
            ele.tooltip ? Ele.field1_tooltip = ele.tooltip : null;
          } else if (ele.dataType === 'String' && ele.selectionType === 'multi' && ele.values) {
            Ele.field2 = 'CheckBox';
            Ele.field2_Values = ele.values;
            Ele.field2_propertyName = ele.propertyName;
            this.fieldPropertyNameArray[ele.propertyName] = [ele.values[0]];
            ele.tooltip ? Ele.field2_tooltip = ele.tooltip : null;
          } else if (ele.dataType === 'String' && ele.selectionType === 'single' && ele.values && radioIterator === 1) {
            radioIterator++;
            Ele.field3 = 'Radio';
            Ele.field3_Values = ele.values;
            Ele.field3_propertyName = ele.propertyName;
            this.fieldPropertyNameArray[ele.propertyName] = ele.values[0];
            ele.tooltip ? Ele.field3_tooltip = ele.tooltip : null;
          } else if (ele.dataType === 'String' && ele.selectionType === 'single' && ele.values && radioIterator === 2) {
            radioIterator++;
            Ele.field4 = 'Radio';
            Ele.field4_Values = ele.values;
            Ele.field4_propertyName = ele.propertyName;
            this.fieldPropertyNameArray[ele.propertyName] = ele.values[0];
            ele.tooltip ? Ele.field4_tooltip = ele.tooltip : null;
          }
        })
        this.collabBindingData.push(Ele);
      } else if (Ele._structureType === 'simple') {
        this.fieldPropertyNameArray[Ele.propertyName] = false;
      }
    });
    this.generateApplAccessFormGrp();
  }

  isEmptyObject(obj) {
    return (obj && (Object.keys(obj).length === 0));
  }

  /** getClients method to go back to select clients page */
  getClients() {
    this.backToClients.emit();
  }

  generateFormGrp() {
    let group = {};
    if (this.ssosetUpData && this.ssosetUpData.length) {
      this.ssosetUpData.forEach((element) => {
        group[element.propertyName] = new UntypedFormControl('');
      });
    }
    group[this.ssoCollabBindingData.propertyName] = new UntypedFormControl('');
    this.ssoSetUpForm = new UntypedFormGroup(group);
    this.clientSSOConfigurationCap !== 'write' ? this.ssoSetUpForm.disable() : null;

    /**appending values to form control from value-list api data */
    this.rolesData ? this.rolesData.forEach((element) => {
      if (element.modelName === 'client-sso-setup') {
        this.ssoRoleDetailsId = element._id ? element._id : null;
        let group = {};

        const entries = Object.entries(element.ssoSetup);
        entries.forEach(([key, val]) => {
          if (typeof val === 'boolean') {
            group[key] = new UntypedFormControl(!val);
          } else {
            group[key] = new UntypedFormControl(val ? val : '');
          }
          this.ssoSetUpForm.get(key).setValue(typeof val === 'boolean' ? !val : val ? val : '');
        });
        this.isSSOLoad = true
      }
    }) : null;
    this.isSSOLoad = true;
  }

  /**
   * Function to generate Application access FormGroup
   */
  generateApplAccessFormGrp() {
    let group = {};
    this.accessAplicationPayload = {
      'modelName': 'client-application-access',
      'partyId': this.client.clientPartyId,
      'roleId': (this.rolesData && this.rolesData.length > 0) ? this.rolesData[0].roleId : null
    }
    if (this.rolesData && this.rolesData.length > 0 && this.rolesData.find(ele => ele.modelName === 'client-application-access')) {
      this.rolesData.forEach((element) => {
        if (element.modelName === 'client-application-access') {
          this.Id = element._id ? element._id : null;
          const entries = (element.applicationAccess && element.applicationAccess.movePro360) ? Object.entries(element.applicationAccess.movePro360) : Object.entries(this.fieldPropertyNameArray);
          entries.forEach(([key, val]) => {
            if (key === 'movePro360Enabled') {
              group[key] = new UntypedFormControl(!val);
              delete this.fieldPropertyNameArray[key];
            }
          });
          /** fieldPropertyNameArray has all the fields expected and its values are replaced with Value list api response*/
          for (const key in element.applicationAccess.movePro360) {
            const val = element.applicationAccess.movePro360[key];
            for (const key in val) {
              if (key in this.fieldPropertyNameArray) {
                this.fieldPropertyNameArray[key] = val[key]
              }
            }
          }
          this.creatingFieldsInFormGroup(group);
        }
      });
    } else {
      this.creatingFieldsInFormGroup(group);
    }
  }

  creatingFieldsInFormGroup(group) {
    let valEntries = Object.entries(this.fieldPropertyNameArray);
    valEntries.map(([key, val]) => {
      if (typeof val === 'boolean') {
        group[key] = new UntypedFormControl(!val);
      } else {
        if (val && Array.isArray(val)) {
          const isStringArray =
            val.length > 0 &&
            val.every((value) => {
              return typeof value === 'string';
            });
          this.checkBoxSelectedvalue = val;
          isStringArray ? group[key] = new UntypedFormArray(val.map(x => new UntypedFormControl(true))) : group[key] = new UntypedFormArray([]);
        } else {
          group[key] = new UntypedFormControl(val ? val : '');
        }
      }
    });
    this.applicationAccessForm = new UntypedFormGroup(group);
    if (this.clientApplicationAccessCap === 'read') {
      this.applicationAccessForm.disable();
    }
    this.isLoaded = true
  }
  /**
   * Function to get values of checkbox according to filed propertyname
   * @param propertyName 
   * @param currentvalue 
   * @param isChecked 
   */
  checkedValue(currentvalue, isChecked) {
    isChecked ? this.checkBoxSelectedvalue.push(currentvalue) : null;
    if (!isChecked) {
      this.checkBoxSelectedvalue.forEach((item, index) => {
        if (item === currentvalue) this.checkBoxSelectedvalue.splice(index, 1);
      });
    }
  }
  ischecked(value, propertyName) {
    return this.fieldPropertyNameArray[propertyName].includes(value);
  }
  /**
   * Generic function for the toggle - application access
   * @param event 
   * @param propertyName 
   */
  onToggle(event, propertyName) {
    this.applicationAccessForm.controls[propertyName].setValue(!(event.checked));
  }
  /**
   * function for the toggle - SSO
   * @param event 
   * @param propertyName 
   */
  onSSOToggle(event, propertyName) {
    this.ssoSetUpForm.controls[propertyName].setValue(!(event.checked));
  }
  /**
   * Function to disable panels and all fields in it
   * @param propertyNameMainToggle 
   * @param propertyNameCorrespondingPanel 
   * @returns 
   */
  disablePanel(propertyNameMainToggle, propertyNameCorrespondingPanel) {
    return (this.applicationAccessForm.controls[propertyNameMainToggle].value || this.applicationAccessForm.controls[propertyNameCorrespondingPanel].value);
  }

  /** Invoked on click of Save Changes btn
  */
  openSaveChangesModal() {
    if (this.applicationAccessForm.valid || this.ssoSetUpForm.valid) {
      const dialog = this.dialog.open(ClientScreenSaveChangesComponent, {
        width: '700px'
      });
      dialog.afterClosed().subscribe(confirm => {
        if (confirm === 'true') {
          this.saveChangesAPI(this.settingPayloadBasedOnCapability());
        }
      });
    }
  }
  /**
   * Save changes post api call and payload creation.
   */
  generateApplicationAccessPayload() {
    this.accessAplicationPayload._id = this.Id;
    this.accessAplicationPayload.applicationAccess = {};
    this.accessAplicationPayload.applicationAccess[this.moveProData.propertyName] = {
      [this.moveProCollabBindingData.propertyName]: !this.applicationAccessForm.controls[this.moveProCollabBindingData.propertyName].value
    };
    if (!this.applicationAccessForm.controls[this.moveProCollabBindingData.propertyName].value) {
      this.collabBindingData.forEach(ele => {
        if (ele.field0_propertyName) {
          this.accessAplicationPayload.applicationAccess[this.moveProData.propertyName][ele.propertyName] = {
            [ele.field0_propertyName]: !this.applicationAccessForm.controls[ele.field0_propertyName].value
          }
          if (!this.applicationAccessForm.controls[ele.field0_propertyName].value &&
            this.applicationAccessForm.controls[ele.field1_propertyName] &&
            this.applicationAccessForm.controls[ele.field1_propertyName] !== undefined) {
              if(this.applicationAccessForm.controls[ele.field1_propertyName].value === 'Standard' &&
                this.applicationAccessForm.controls[ele.field3_propertyName].value &&
                this.applicationAccessForm.controls[ele.field4_propertyName].value) {
                  this.accessAplicationPayload.applicationAccess[this.moveProData.propertyName][ele.propertyName] = {
                    [ele.field0_propertyName]: !this.applicationAccessForm.controls[ele.field0_propertyName].value,
                    [ele.field1_propertyName]: this.applicationAccessForm.controls[ele.field1_propertyName].value,
                    [ele.field3_propertyName]: this.applicationAccessForm.controls[ele.field3_propertyName].value,
                    [ele.field4_propertyName]: this.applicationAccessForm.controls[ele.field4_propertyName].value
                  }
              } else {
                this.accessAplicationPayload.applicationAccess[this.moveProData.propertyName][ele.propertyName] = {
                  [ele.field0_propertyName]: !this.applicationAccessForm.controls[ele.field0_propertyName].value,
                  [ele.field1_propertyName]: this.applicationAccessForm.controls[ele.field1_propertyName].value
                }
              }
          }
          if (!this.applicationAccessForm.controls[ele.field0_propertyName].value &&
            this.applicationAccessForm.controls[ele.field2_propertyName] &&
            this.applicationAccessForm.controls[ele.field2_propertyName] !== undefined) {
            this.accessAplicationPayload.applicationAccess[this.moveProData.propertyName][ele.propertyName] = {
              [ele.field0_propertyName]: !this.applicationAccessForm.controls[ele.field0_propertyName].value,
              [ele.field2_propertyName]: [...new Set(this.checkBoxSelectedvalue)]
            }
          }
        }
      })
    }

    return this.accessAplicationPayload;
  }

  generateSsoSetupPayload() {
    const ssoEnabled = this.ssoSetUpForm.controls.ssoEnabled.value;
    // this.ssoSetUpForm.controls.ssoEnabled.setValue(!ssoEnabled)
    const ssoSetup = {
      "ssoClientLink": this.ssoSetUpForm.controls.ssoClientLink.value,
      "ssoEnabled": !ssoEnabled,
      "ssoLoginInstructions": this.ssoSetUpForm.controls.ssoLoginInstructions.value,
      "ssoRegex": this.ssoSetUpForm.controls.ssoRegex.value,
      "ssoRegexErrorMessage": this.ssoSetUpForm.controls.ssoRegexErrorMessage.value
    }

    const ssoSteupPayload = {
      '_id': this.ssoRoleDetailsId,
      'modelName': 'client-sso-setup',
      'partyId': this.client.clientPartyId,
      'roleId': (this.rolesData && this.rolesData.length > 0) ? this.rolesData[0].roleId : null,
      'ssoSetup': !ssoEnabled ? ssoSetup : { 'ssoEnabled': !ssoEnabled }
    }

    return ssoSteupPayload;
  }

  settingPayloadBasedOnCapability() {
    let clientDetailsArray = []
    if (this.clientApplicationAccessCap == 'write') {
      clientDetailsArray.push(this.generateApplicationAccessPayload());
    }
    if (this.clientSSOConfigurationCap == 'write') {
      clientDetailsArray.push(this.generateSsoSetupPayload());
    }
    return clientDetailsArray;
  }

  /**
   * Function to call POST api call for save changes btn
   * @param accessAplicationPayload 
   */
  saveChangesAPI(accessAplicationPayload) {
    this.spinner.show();
    if (this.Id || this.ssoRoleDetailsId) {
      this.updateChanges(accessAplicationPayload);
    } else {
      this.clientScreenService.saveApplicationAccessChanges(accessAplicationPayload).subscribe(data => {
        if (data) {
          this.toastr.info('Client Configurations applied successfully', null, {
            timeOut: 4000
          });
        } else {
          this.spinner.hide();
          this.toastr.error('Failed to apply client configurations. Try again.', null, {
            timeOut: 4000
          });
        }
      });
    }
  }

  /** Method to get capabilities */
  getCapabilities() {
    this.userTypeService.capabilities$.subscribe(ele => {
      if (ele) {
        ele.forEach(element => {
          if (element.name == "MP - Client SSO Configuration" && element.permission == "allow") {
            this.clientSSOConfigurationCap = element.operation;
          } else if (element.name == "MP - Client Application Access" && element.permission == "allow") {
            this.clientApplicationAccessCap = element.operation;
          }
        });
      }
    });
  }

  updateChanges(accessAplicationPayload) {
    this.clientScreenService.updateApplicationAccessChanges(accessAplicationPayload, this.Id).subscribe(data => {
      if (data) {
        this.toastr.info('Client Configurations applied sucessfully', null, {
          timeOut: 4000
        });
      } else {
        this.spinner.hide();
        this.toastr.error('failed to apply client configurations. Try again', null, {
          timeOut: 4000
        });
      }
    });
  }

  getFeatureFlag() {
    this.featureFlagService.getFeatureFlagsBBUrl().subscribe(
      (res) => {
        const featureFlag: FeatureFlag[] = res.items;
        this.isLumpSumEnabled = featureFlag && featureFlag.find(ele => ele.key === 'lump-sum-client').on;
      }
    )
  }
}

